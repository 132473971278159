/*
* @ngdoc Config
* @name app.models.asset-info-filters
* @description
* The service is used to define asset Info Filters data
* */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AssetInfoFilterData {
    assetIdName = ['VIN', 'Rego', 'NVIC', 'RedbookShort', 'RedbookLong'];
    states = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'];
    statusCodes = [200, 404, 422, 500];
    versions = ['v1.0', 'v2.0'];
    v1Products = ['AssetAttributes', 'AssetAge', 'RegistrationStatus', 'HPV', 'Stolen', 'Wov', 'VehicleCode1', 'VehicleCode2', 'VehicleCode3', 'VehicleExtraData', 'StdEquip', 'OptEquip'];
    v2Products = ['AssetAttributes', 'AssetAge', 'RegistrationStatus', 'Stolen', 'Wov', 'VehicleCode1', 'VehicleCode2', 'VehicleCode3', 'VehicleExtraData', 'StdEquip'];
    nvicRedbookProducts = ['VehicleCode1', 'VehicleCode2', 'VehicleCode3', 'VehicleExtraData', 'StdEquip', 'OptEquip'];
    nonNavdisProducts = ['NVIC', 'RedbookShort', 'RedbookLong'];
}
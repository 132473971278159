import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { QuotesService } from 'src/app/services/quotes.service';

interface IvForm {
  Quantity: number;
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
  TotalPrice: number,
  ItemPrice: number
}

@Component({
  selector: 'app-company-admin-quote-details',
  templateUrl: './company-admin-quote-details.component.html',
  styleUrls: ['./company-admin-quote-details.component.scss']
})
export class CompanyAdminQuoteDetailsComponent implements OnInit {

  quoteLoader: boolean = false;
  quoteDetails: any = null;
  quoteId = null;
  autpOrderDetails = undefined;
  ivOrderDetails = undefined;
  priceMyTruckDetailOrderDetails = undefined;
  autpForm: {
    ConfigName: string,
    ConfigPermissions: string
    ConfigPrice: number,
    TotalMakePrice: number,
    TotalUserPrice: number,
    MakePrice: number,
    UserPrice: number,
    TotalMakes: number,
    TotalUsers: number
  } = undefined;
  priceMyTruckForm: {
    ItemPrice: number,
    TotalPrice: number,
    Name: string,
    TotalUserPrice: number,
    UserPrice: number,
    TotalUsers: number,
    TotalMakePrice: number,
    MakePrice: number,
    TotalMakes: number
  } = undefined;
  priceFormat = '';
  downloadLoader: boolean = false;
  constructor(
    public appSettings: AppSettings,
    private commonService: CommonService,
    private quoteService: QuotesService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.priceFormat = appSettings.priceFormat;
    this.quoteId = this.activatedRoute.snapshot.paramMap.get("id");
    console.log()
    if (!this.quoteId) {
      this.sendToProduct();
    }
  }

  ngOnInit() {
    this.commonService.setTitle("Quotes Detail");
    this.getQuoteListing();
  }

  sendToProduct() {
    this.commonService.showSnakeBar("Unable to find the requested quote.");
    this.router.navigate(["/my-quotes"]);
  }

  getQuoteListing() {
    this.quoteLoader = true;
    this.quoteService.quoteQuoteDetails(this.quoteId)
      .subscribe(
        (res) => {
          this.quoteLoader = false;
          const response = res;
          this.quoteDetails = response.data;
          this.setupProductItems()
        },
        (err) => {
          this.quoteLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.sendToProduct();
        }
      );
  }

  setupProductItems() {
    if (this.quoteDetails) {
      let data = this.quoteDetails;
      if ('QuoteItems' in data) {
        let QuoteItems = data['QuoteItems']
        if ("autpDetail" in QuoteItems) {
          this.autpOrderDetails = QuoteItems["autpDetail"];
          this.autpForm = {
            ConfigName: this.autpOrderDetails['ConfigName'],
            ConfigPermissions: this.autpOrderDetails['ConfigPermissions'],
            ConfigPrice: this.autpOrderDetails['ConfigPrice'],
            TotalMakePrice: this.autpOrderDetails['OrderItemConfigs']['TotalMakePrice'],
            TotalUserPrice: this.autpOrderDetails['OrderItemConfigs']['TotalUserPrice'],
            MakePrice: this.autpOrderDetails['OrderItemConfigs']['MakePrice'],
            UserPrice: this.autpOrderDetails['OrderItemConfigs']['UserPrice'],
            TotalMakes: this.autpOrderDetails['TotalMakes'],
            TotalUsers: this.autpOrderDetails['TotalUsers']
          };
        } else {
          this.autpOrderDetails = undefined;
        }

        if ("priceMyTruckDetail" in QuoteItems) {
          this.priceMyTruckDetailOrderDetails = QuoteItems["priceMyTruckDetail"];
          this.priceMyTruckForm = {
            ItemPrice: this.priceMyTruckDetailOrderDetails['Configs']['ItemPrice'],
            TotalPrice: parseInt(this.priceMyTruckDetailOrderDetails['TotalPrice']),
            Name: this.priceMyTruckDetailOrderDetails['Configs']['Name'],
            TotalUserPrice: this.priceMyTruckDetailOrderDetails['OrderItemConfigs']['TotalUserPrice'],
            UserPrice: this.priceMyTruckDetailOrderDetails['OrderItemConfigs']['UserPrice'],
            TotalUsers: this.priceMyTruckDetailOrderDetails['TotalUsers'],
            TotalMakePrice: this.priceMyTruckDetailOrderDetails['OrderItemConfigs']['TotalMakePrice'],
            MakePrice: this.priceMyTruckDetailOrderDetails['OrderItemConfigs']['MakePrice'],
            TotalMakes: this.priceMyTruckDetailOrderDetails['TotalMakes'],
          };
        } else {
          this.priceMyTruckDetailOrderDetails = undefined;
        }
        ///console.log(this.priceMyTruckDetailOrderDetails);

        if ("ivDetail" in QuoteItems && "Configs" in QuoteItems["ivDetail"]) {
          this.ivOrderDetails = QuoteItems["ivDetail"];
        } else {
          this.ivOrderDetails = undefined;
        }
        //console.log(this.ivOrderDetails, 'ivOrderDetails');
      }
    }
  }


  sendInvoice() {
    if (this.downloadLoader === true) {
      return false;
    }
    this.downloadLoader = true
    this.quoteService.sendInvoiceDetail({ id: this.quoteId })
      .subscribe(
        (res) => {
          this.downloadLoader = false
          const response = res;
          this.commonService.showSnakeBar('Quote has been sent to your email. Please check your email.');
        },
        (err) => {
          this.downloadLoader = false
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

}

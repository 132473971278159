import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/company.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-renew-subscription',
  templateUrl: './renew-subscription.component.html',
  styleUrls: ['./renew-subscription.component.scss']
})
export class RenewSubscriptionComponent implements OnInit {

  companyId = null;
  step = 1;
  offer = {};
  isSubProcessing = false;
  public onCompanyOfferAdded: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RenewSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public companyService: CompanyService,
    public commonService: CommonService
  ) {
    this.offer = this.data['offer']
    this.companyId = this.data['offer']['companyId']
  }


  ngOnInit() {
  }

  onCompanySubAdd(event) {
    //console.log(event)
    this.onCompanyOfferAdded.emit(event)
  }

  subInProgress(event) {
    this.isSubProcessing = event
  }
}

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { CommonService } from "../../../services/common.service";
import { DataStoreService } from "../../../services/data-store.service";
import { SubscriptionService } from "../../../services/subscription.service";
import { PermissionsService } from '../../../services/permissions.service'
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { AddOfferComponent } from "../add-offer/add-offer.component";
import { AppSettings } from "src/app/configs/app-settings";
import { ConfirmationPopupComponent } from "src/app/shared/confirmation-popup/confirmation-popup.component";
import { PopupLoaderComponent } from "src/app/shared/popup-loader/popup-loader.component";
import { RenewSubscriptionComponent } from "../renew-subscription/renew-subscription.component";
import { UpdateCompanyV2Component } from "../../portal/update-company-v2/update-company-v2.component";
import { CompanyService } from "src/app/services/company.service";
import { AddMakePopupComponent } from "../../add-make-popup/add-make-popup.component";
import { MakesModalHistoryComponent } from "../../makes-modal-history/makes-modal-history.component";
import { QuotesService } from "src/app/services/quotes.service";

@Component({
  selector: "app-subscription-list",
  templateUrl: "./subscription-list.component.html",
  styleUrls: ["./subscription-list.component.scss"]
})
export class SubscriptionListComponent implements OnInit {
  displayedColumns: string[] = [
    //"PlanName",
    "ProductName",
    "StartDate",
    "EndDate",
    "Status",
    "Makes",
    "Action"
  ];
  isLoading: boolean = false;
  isSubLoading: boolean = false;
  listData = [];

  vSubscription: Subscription;
  vFactListing: Subscription;
  makArray: Array<string> = [];
  permArray: Array<any> = [];
  CompanyDetails = {};
  public downloadInvoiceLoader: boolean = false;


  title = "Manage Subscriptions";
  companyId = null;
  timeLineDateFormat = null
  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public dataStore: DataStoreService,
    public subscriptionService: SubscriptionService,
    public permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appSettings: AppSettings,
    private companyService: CompanyService,
    private quoteService: QuotesService,
  ) {

    this.companyId = this.activatedRoute.parent.snapshot.paramMap.get("id");
    if (!this.companyId) {
      this.sendToCompany()
    }
    this.getCompanyDetails()
    this.timeLineDateFormat = this.appSettings.COMMON_DATE_FORMAT
  }

  ngOnInit() {
    this.commonService.setTitle(this.title);
  }

  sendToCompany() {
    this.commonService.showSnakeBar(
      "Unable to find the requested company."
    );
    this.router.navigate(["/companies"]);
  }


  updateCompany() {
    const dialogRef = this.dialog.open(UpdateCompanyV2Component, {
      width: 'auto',
      height: 'auto',
      data: {
        company: this.CompanyDetails
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyUpdate.subscribe(res => {
      if (res) {
        this.CompanyDetails = {
          ...res
        };
        // console.log(this.CompanyDetails, 'CompanyDetails');
      }
    })
  }

  updateMake(product, index: number) {
    const dialogRef = this.dialog.open(AddMakePopupComponent, {
      width: 'auto',
      height: 'auto',
      panelClass: 'addMakesContainer',
      disableClose: true,
      data: {
        allMakes: product.Product_Id === 1 ? this.makArray : product.allMakes,
        product: product,
        companyId: this.companyId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'updated') {
        this.listData = [];
        this.getSubscriptionListing();
      }
    });
  }

  isProcessing: boolean = false;
  changeCompanyStatus(id, status) {
    const reqModel = {
      status: status,
      companyId: id
    };
    this.isProcessing = true;
    this.companyService.companyStatus(reqModel).subscribe(res => {
      this.CompanyDetails['CompanyStatus'] = status;
      try {

      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, () => {
      this.isProcessing = false;
    })
  }

  confirmStatusDeactivation(companyId) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to deactivate Company?'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.changeCompanyStatus(companyId, 2);
      }
    });
  }

  getCompanyDetails() {
    this.isLoading = true;
    this.vFactListing = this.subscriptionService
      .getCompanyDetails({ companyId: this.companyId })
      .subscribe(
        result => {
          this.isLoading = false;
          try {
            let response = result;
            if (!response.data) {
              this.sendToCompany()
            }
            this.CompanyDetails = response.data;
            this.getSubscriptionListing()

          } catch (e) { }
        },
        error => {
          //console.log(error)
          this.isLoading = false;
          let response = error;

          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isLoading = false;
        },
        () => { }
      );
  }

  permissionMapping(Permissions = []) {
    if (Permissions != null && Array.isArray(Permissions)) {
      let pp = []
      this.permArray.map(p => {
        Permissions.indexOf(String(p.key)) != -1 ? pp.push(p.label) : null;
      });
      if (pp.length > 0) {
        return pp.join(', ');
      }
    }
    return 'N/A';
  }

  /*
  * @name getSubscriptionListing
  * @param none
  * @description get the subscription of products
  * @return none
  */
  getSubscriptionListing() {
    this.isSubLoading = true;
    this.vFactListing = this.subscriptionService
      .getSubscriptionList({ companyId: this.companyId })
      .subscribe(
        result => {
          this.isSubLoading = false;
          try {
            let response = result;
            this.listData = response.data.subscription;
            this.makArray = response.data.Makes;
            this.permArray = response.data.Permissions;
          } catch (e) {
            this.commonService.commonSnakeBar();
          }
        },
        error => {
          this.isSubLoading = false;
          let response = error;
          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isSubLoading = false;
        }
      );
  }

  downloadInvoice(InvoiceId: string) {
    this.downloadInvoiceLoader = true;
    this.quoteService.sendInvoiceDetail({ id: InvoiceId, action: 'download', companyId: this.companyId })
      .subscribe(
        (res) => {
          const response = res;
          let filename = `invoice-${InvoiceId}.pdf`;
          var blob = new Blob([response], { type: 'application/pdf' });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
          }
          else {
            var link = document.createElement("a");
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          this.downloadInvoiceLoader = false;
        },
        (err) => {
          this.downloadInvoiceLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

  viewProductQuote(quoteId: string) {
    this.router.navigate(['/quote-details/' + quoteId])
  }

  subscriptionAction(action, data) {
    console.log(action, data);
  }

  addSubscription() {
    const dialogRef = this.dialog.open(AddOfferComponent, {
      width: "auto",
      height: "auto",
      data: {
        companyId: this.companyId
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyOfferAdded.subscribe(res => {
      //console.log('here...')
      this.getSubscriptionListing();
    })
  }


  getOfferSubscriptionData(offer) {
    let ProductSubscription = {
      TotalUsers: offer['TotalUsers']
    }
    if (offer['TotalRequests']) {
      ProductSubscription['TotalRequests'] = offer['TotalRequests']
    }
    if (offer['Makes']) {
      ProductSubscription['Makes'] = offer['Makes']
    }
    if (offer['Permissions']) {
      ProductSubscription['Permission_Id'] = offer['Permissions'].map(Number)
    }
    let temp = {
      Product_Id: offer['Product_Id'],
      ProductSubscription: ProductSubscription
    }
    //console.log(temp, 'temp')
    return [temp]
  }


  renewSubscription(offer) {
    if (offer['Product_Id'] == this.appSettings.InstantVal || offer['Product_Id'] == this.appSettings.AutoPredict) {
      let routes = ['product-details', offer['Product_Id']];
      console.log(routes, 'routes')
      this.router.navigate(routes, {
        queryParams: {
          'companyId': this.companyId
        }
      });
    }
    return false;
    const popupLoader = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Getting details...'
      }
    });
    let params = { Plan_Id: offer['Plan_Id'], Product_Id: offer['Product_Id'] }
    this.permissionsService
      .getOfferDetails(params)
      .subscribe(
        result => {
          this.isSubLoading = false;
          try {
            popupLoader.close()
            let response = result;
            if (response.data) {
              let offerData = response.data
              if (offerData['products']) {
                let makes = offerData.makes
                delete offerData.makes
                let permissions = offerData.permissions
                delete offerData.permissions
                let pData = {
                  offerDetails: offerData['products'],
                  makes: makes,
                  permissions: permissions,
                  offerSubscription: this.getOfferSubscriptionData(offer),
                  companyId: this.companyId,
                  fromRenew: true
                }
                this.openRenewPopup(pData)
              }


            } else {

            }

          } catch (e) {
            popupLoader.close()
          }
        },
        error => {
          //console.log(error)
          popupLoader.close()
          this.isSubLoading = false;
          let response = error;

          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isSubLoading = false;
        },
        () => { }
      );
  }


  openRenewPopup(data) {
    // console.log(data)
    const dialogRef = this.dialog.open(RenewSubscriptionComponent, {
      width: "auto",
      height: "auto",
      data: {
        offer: data
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyOfferAdded.subscribe(res => {
      //console.log('here...')
      this.getSubscriptionListing();
    })
  }

  confirmDeactivation(subData) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to deactivate this subscription?'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.deactivateSubscription(subData);
      }
    });
  }


  deactivateSubscription(subData) {
    let ttData = {
      'Company_Id': this.companyId,
      'Product_Id': subData['Product_Id'],
      'Plan_Id': subData['Plan_Id'],
      'CompanySubscription_Id': subData['CompanySubscription_Id']
    }
    const popupLoader = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Deactivating...'
      }
    });
    //let subId = subData['CompanySubscription_Id']
    this.subscriptionService
      .deactivateSubscription(ttData)
      .subscribe(
        result => {
          popupLoader.close()
          try {
            this.getSubscriptionListing();
            /*var index = this.listData.findIndex(p => p.CompanySubscription_Id == subId)
            if (index != -1) {
              this.listData[index]['IsActive'] = false
            }*/
          } catch (e) { }
        },
        error => {
          popupLoader.close()
          //console.log(error)
          let response = error;
          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          popupLoader.close()
        }
      );
  }

  /*
   * @name updateProducts
   * @param none
   * @description Update new (Deflation, BusinessRisk and Remarketing Efficiency)
   * @return none
   */
  /*updateProducts(object) {
    const dialogRef = this.dialog.open(EditProductComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: true,
        editProduct: object,
        makes: this.makArray,
        permissions: this.permArray,
        ProductOffers: this.ProductOffers
      },
      disableClose: true
    });


    dialogRef.componentInstance.onUpdateProduct.subscribe(res => {
      this.listData = res
      this.listData = this.listData.slice()
    })
  }*/

  /*
   * @name handleServerFormError
   * @param Form
   * @description handle server side errors
   * @return none
   */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  showMakeHistory(makesHistory) {
    const dialogRef = this.dialog.open(MakesModalHistoryComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: false,
      data: {
        makesHistoryData: makesHistory
      }
    });
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe();
    }
    if (this.vFactListing) {
      this.vFactListing.unsubscribe();
    }
  }
}

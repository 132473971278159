import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-select-makes-modal',
  templateUrl: './select-makes-modal.component.html',
  styleUrls: ['./select-makes-modal.component.scss']
})
export class SelectMakesModalComponent implements OnInit {

  autpFormData: any = undefined;
  Makes: Array<string> = [];
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectMakesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private commonService: CommonService
  ) {
    if (!data) {
      this.commonService.showSnakeBar("Invalid details. Please try again later");
      this.closeModal();
    }
    if (!('autpForm' in data)) {
      this.commonService.showSnakeBar("Invalid details. Please try again later");
      this.closeModal()
    } else {
      this.autpFormData = Object.assign(data['autpForm'], {});
    }
    if ('Makes' in data) {
      this.Makes = data['Makes'];
    }
  }

  ngOnInit() {
  }

  updateMake(makeList: Array<string> = []) {
    if (makeList.length == 0) {
      this.commonService.showSnakeBar('Please choose at least one make.');
      return false;
    }
    const MaxMake = parseInt(this.autpFormData.MaxMake);
    if (makeList.length > MaxMake) {
      this.commonService.showSnakeBar(`You can not choose more than ${MaxMake} make.`);
      return false;
    }
    this.dialogRef.close({
      makeList: makeList,
      action: 'update'
    })
  }

  closeModal() {
    this.dialogRef.close({
      action: 'close'
    });
    return false;
  }

}

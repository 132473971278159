import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'phone-verification',
  templateUrl: './phone-verification-modal.component.html'
})
export class PhoneVerificationModalComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PhoneVerificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public userData
  ) {
  }

  ngOnInit() {
  }

  closeModal(event: string) {
    if (event === 'close') {
      localStorage.removeItem('AUTOP_PHONE_VERIFICATION_MODEL');
      this.dialogRef.close();
    }
  }
}

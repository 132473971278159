import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { QuotesService } from 'src/app/services/quotes.service';

@Component({
  selector: 'app-company-admin-subscriptions',
  templateUrl: './company-admin-subscriptions.component.html',
  styleUrls: ['./company-admin-subscriptions.component.scss']
})
export class CompanyAdminSubscriptionsComponent implements OnInit {

  panelOpenState = false;
  Loader: boolean = false;
  priceFormat;
  dataSource = [];
  constructor(
    private commonService: CommonService,
    private quoteService: QuotesService,
    public appSettings: AppSettings
  ) {
    this.priceFormat = this.appSettings.priceFormat;
  }

  ngOnInit() {
    this.commonService.setTitle('My Subscriptions');
    this.getInvoiceListing();
  }

  getInvoiceListing() {
    this.Loader = true;
    this.quoteService.paymentListing({
      subscription: true
    })
      .subscribe(
        (res) => {
          this.Loader = false;
          const response = res;
          this.dataSource = response.data;
        },
        (err) => {
          this.Loader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }
}

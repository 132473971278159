import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleCheckerService } from '../services/role-checker.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {

  @Input('appHasPermission') appHasPermission: string | string[] = "";
  constructor(private roleCheckerSer: RoleCheckerService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {
    this.applyPermission();
  }

  applyPermission() {
    if (Array.isArray(this.appHasPermission)) {
      let isAllowed: boolean = false;
      this.appHasPermission.forEach(perKey => {
        if (this.roleCheckerSer.checkUserPermission(perKey)) {
          isAllowed = true
        }
      });
      if (isAllowed) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else {
      if (this.roleCheckerSer.checkUserPermission(this.appHasPermission)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  }
}

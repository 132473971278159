import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoleCheckerService } from '../../services/role-checker.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public innerWidth: any;
  public showLeftMenu: boolean;

  constructor(
    public dialog: MatDialog,
    public roleCheckService: RoleCheckerService
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.showLeftMenu = false;
  }


  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

}

/**
 * Interceptor
 * This is HttpTokenInterceptor that adds a token to each api request.
 */


import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { TOKEN_HEADER_KEY, AUTH_PREFIX, CommonService } from '../services/common.service';

/**
 * Creates a HttpTokenInterceptor.
 * @class HttpTokenInterceptor
 * @classdesc This class is used to add user token/auth entity to the headers.
 * You can define the custom header here.
 */

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private commonService: CommonService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig: any = {};
    if (this.jwtService.IsAuthUser() && !req.url.includes(this.commonService.azureURL)) {

      const token = this.jwtService.getAccessToken();
      headersConfig[TOKEN_HEADER_KEY] = `${AUTH_PREFIX} ${token}`;
      const request = req.clone({ setHeaders: headersConfig });

      return next.handle(request);
    } else {
      return next.handle(req);
    }
  }
}


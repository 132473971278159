import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'app-makes-modal-history',
  templateUrl: './makes-modal-history.component.html',
  styleUrls: ['./makes-modal-history.component.scss']
})
export class MakesModalHistoryComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MakesModalHistoryComponent>,
    public appSettings: AppSettings
  ) {
    for (let i = 0; i < this.data.makesHistoryData.length; i++) {
      this.data.makesHistoryData[i].Make = this.data.makesHistoryData[i].Make.replace(',', ', ');
    }
  }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}

import { Injectable } from '@angular/core';

@Injectable()
export class DateHelper {

    public getMonthStartDate() {
        const date = new Date();
        const startDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-01";
        return startDate;
    }

    public getMonthCurrentDate() {
        const date = new Date();
        const EndDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        return EndDate;
    }
}
import { Injectable } from '@angular/core';

import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  getPermissionsList() {
    return this.commonHttpService.get(this.apiUrls.permissions.list);
  }

  updateProductOffer(params) {
    return this.commonHttpService.post(`${this.apiUrls.p_offer.list}/${this.apiUrls.p_offer.update}`, params);
  }

  getProductAttributesList() {
    return this.commonHttpService.get(`${this.apiUrls.products.list}/${this.apiUrls.products.attributes}`);
  }

  getCompanyProductOfferList(params) {
    return this.commonHttpService.post(this.apiUrls.p_offer.company, params);
  }

  getProductList() {
    return this.commonHttpService.get(this.apiUrls.products.list);
  }

  getProductOfferList() {
    return this.commonHttpService.get(this.apiUrls.p_offer.list);
  }

  getOfferDetails(params) {
    return this.commonHttpService.get(`${this.apiUrls.p_offer.list}/${this.apiUrls.p_offer.details}`, params);
  }
}

import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../../configs/app-settings';

@Component({
  selector: 'app-footer-text',
  templateUrl: './footer-text.component.html',
  styleUrls: ['./footer-text.component.scss']
})
export class FooterTextComponent implements OnInit {

  constructor(
    public appSettings: AppSettings
  ) { }

  ngOnInit() {
  }

}

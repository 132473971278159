import { Component, Input } from '@angular/core';

@Component({
  selector: 'product-icons',
  templateUrl: './product-icons.component.html',
  styleUrls: ['./product-icons.component.scss']
})
export class ProductIconsComponent {
  @Input() icon: string;
  @Input() theme: string;
}

import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import * as Highcharts from 'highcharts';
import { ProductService } from 'src/app/services/product.service';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { JwtService } from 'src/app/services/jwt.service';
import { AppSettings } from 'src/app/configs/app-settings';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usage-wrapper',
  templateUrl: './usage-wrapper.component.html',
  styleUrls: ['./usage-wrapper.component.scss']
})
export class UsageWrapperComponent implements OnInit {

  public displayedColumns = [];
  public usageDataForGraph = [];
  public dataSource;
  public chart;
  public dates = [];
  public regoData: any = [];
  public vinData = [];
  public Car2Cash = [];
  public truckValData = [];
  public bikeValData = [];
  public assetValuationData = [];
  public assetValuationAcces = false;
  public instantData = [];
  public usageInterval = [];
  public intervalValue = "";
  public maxDate: Date = new Date();
  public customValue = 0;
  public selected = "";
  // public endDateCheck = false
  public xAxisLabel = [];
  public companiesListing: any = [];
  public companyId = 'all';
  public selectedCompany: any = [];
  public recordsFound = true;
  public instantvalDepreciationData = [];
  public filteredOptions: any = [];
  public searchTextboxControl = new UntypedFormControl();
  @ViewChild('search', { static: false }) searchTextBox: ElementRef;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public Highcharts = Highcharts;
  public columnNames = [{
    id: "user",
    value: "User"

  }, {
    id: "api_key",
    value: "API Key"
  },
  {
    id: "request_date",
    value: "Request date"
  },
  {
    id: "number_request",
    value: "Number of Request"
  }];
  isProcessing: boolean = false;
  oldProducts: any;
  apiSub: Subscription;
  interValDays: number = 10
  //intervalArray = Array(90 - 10 + 1).join().split(',').map(function (e, i) { return 10 + i * 5; })
  constructor(
    public productService: ProductService,
    public commonService: CommonService,
    public roleCheckService: RoleCheckerService,
    public userService: UserService,
    public dp: DatePipe,
    private renderer: Renderer2,
    public jwtService: JwtService,
    public appSettings: AppSettings,
    private router: Router
  ) {
    this.commonService.setTitle('Usage');

    // this.renderer.addClass(document.body, 'menu_collapsed');

  }

  ngOnInit() {
    this.displayedColumns = this.columnNames.map(x => x.id);
    // this.createTable();
    //this.getDataForChart();
    this.getUsageInterval();
    if (this.roleCheckService.isSuperAdmin()) {
      this.getCompanyListing()
    } else {
      let productAccess = this.jwtService.getUserProductsAccess();
      if (this.roleCheckService.isCompanyUser() && !productAccess.includes(this.appSettings.InstantVal)) {
        this.router.navigate(["dashboard"]);;
      }
      this.getDataForChart();
      this.getUsageCount()
    }
    this.searchTextboxControl.valueChanges.subscribe(res => {
      this.filteredOptions = this._filter(res);
    })
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    let filteredList = this.companiesListing.filter((option) => {
      return option.CompanyName.toLowerCase().indexOf(filterValue) === 0
    });
    return filteredList;
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  // ngOnDestroy() {
  //   this.renderer.removeClass(document.body, 'menu_collapsed');
  // }

  public chartModel: any = {
    StartDate: new Date(new Date().setFullYear((new Date).getFullYear() - 1)),
    EndDate: this.maxDate
  };

  makeHighChart() {
    let series: any = [{
      name: 'Instant Val',
      data: this.instantData
    }
    ];
    if (this.roleCheckService.isSuperAdmin()) {
      series.push({
        name: 'InstantVal Depreciation',
        data: this.instantvalDepreciationData
      });
      series.push({
        name: 'Rego',
        data: this.regoData,
      });
      series.push({
        name: 'Vin',
        data: this.vinData
      });
      series.push({
        name: 'TruckVal',
        data: this.truckValData
      });
      series.push({
        name: 'BikeVal',
        data: this.bikeValData
      });
    }
    if (this.roleCheckService.isSuperAdmin() || ((this.roleCheckService.isCompanyOwner() || this.roleCheckService.isCompanyAdmin()) && this.Car2CashPending > 0)) {
      series.push({
        name: 'Car2Cash',
        data: this.Car2Cash
      });
    }
    if (this.roleCheckService.isSuperAdmin() || ((this.roleCheckService.isCompanyOwner() || this.roleCheckService.isCompanyAdmin()) && this.assetValuationAcces == true)) {
      series.push({
        name: 'AssetValuation',
        data: this.assetValuationData
      });
    }
    this.chart = Highcharts.chart('container', {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Usage Graph'
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        title: {
          text: 'Date'
        },
        categories: this.dates,
        //crosshair: true
      },
      yAxis: {
        title: {
          text: 'Values'
        },
        min: 0,
        allowDecimals: false
      },

      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0
        }
      },
      series: series,
      credits: {
        enabled: false
      }
    });
  }

  /*onPointSelect(event) {
    if (this.endDateCheck) {
      let value = this.usageDataForGraph[event.point.x]
      this.chartModel.StartDate = new Date(value.StartDate)
      this.chartModel.EndDate = (value.EndDate)
      this.customValue = 1
      this.getDataForChart()
    }
  }*/

  TotalRequest = 0
  PendingRequest = 0;
  Car2CashTotal = 0;
  Car2CashPending = 0;
  isLoader = false
  getUsageCount() {
    const reqModel = {
      'companyId': this.companyId ? this.companyId : null,
    }
    this.isLoader = true
    this.productService.usageCountData(reqModel).subscribe(res => {
      this.isLoader = false
      try {
        const response = res;
        this.TotalRequest = response.data['TotalRequest'];
        this.PendingRequest = response.data['PendingRequest'];

        this.Car2CashTotal = response.data['Car2CashTotal'];
        this.Car2CashPending = response.data['Car2CashPending'];
        this.assetValuationAcces = response.data['AssetValuation'];
        this.makeHighChart();
      } catch (e) {
      }
    }, (err) => {
      this.isLoader = false
      let response = err;
      if (response.message) {
        this.recordsFound = false
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    });
  }

  getDataForChart() {
    this.isProcessing = true;
    const reqModel = {
      // 'intervalValue': this.intervalValue ? this.intervalValue : 'this_month',
      // StartDate: (this.chartModel.StartDate) ? this.dp.transform(this.chartModel.StartDate, 'yyyy/MM/dd') : '',
      // EndDate: (this.chartModel.EndDate) ? this.dp.transform(this.chartModel.EndDate, 'yyyy/MM/dd') : '',
      // 'customValue': this.customValue,
      'companyId': this.companyId ? this.companyId : null,
      //'interval': this.interValDays
    }
    //console.log(reqModel)
    if (this.apiSub) {
      this.apiSub.unsubscribe()
    }
    this.apiSub = this.productService.usageData(reqModel).subscribe(res => {
      try {
        const response = res;
        this.isProcessing = false
        if (response.status == 200) {
          this.recordsFound = true
          this.regoData = [];
          this.vinData = [];
          this.instantData = [];
          this.instantvalDepreciationData = [];
          this.dates = [];
          this.Car2Cash = [];
          this.truckValData = [];
          this.bikeValData = [];
          this.assetValuationData = [];
          this.usageDataForGraph = response.data;
          this.usageDataForGraph.forEach(element => {
            let startDate = this.commonService.currentDateFormat(element.Date)
            //this.endDateCheck = false
            this.dates.push(startDate)

            this.regoData.push(element.Rego);
            this.vinData.push(element.Vin);
            this.instantData.push(element.InstantVal);
            this.instantvalDepreciationData.push(element.InstantValDepreciation);
            this.Car2Cash.push(element.Car2Cash);
            this.truckValData.push(element.TruckVal);
            this.bikeValData.push(element.BikeVal);
            this.assetValuationData.push(element.AssetValuation);
          });
          this.isProcessing = false;
          this.makeHighChart();
        } else {
          this.recordsFound = false
          this.isProcessing = false;
          this.commonService.showSnakeBar("You don't have permission for the graph")
        }
      }
      catch (e) {
      }
    }, (err) => {
      let response = err;
      if (response.message) {
        this.recordsFound = false
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    })
  }

  getUsageInterval() {
    this.productService.usageInterval().subscribe(res => {
      try {
        const response = res;
        this.usageInterval = response.data.Intervals;
      }
      catch (e) {
      }
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  onDateChange() {
    if (this.chartModel.StartDate > this.chartModel.EndDate) {
      this.commonService.showSnakeBar("Invalid date selection");
    }
    else if (this.chartModel.StartDate && this.chartModel.EndDate) {
      this.customValue = 1
      this.recordsFound = true
      this.getDataForChart()
    }
  }


  onDateInterValChange() {
    if (this.interValDays >= 10 && this.interValDays <= 90) {
      this.getDataForChart()
    } else {
      this.commonService.showSnakeBar("Interval days must be between 10 to 90.");
    }

  }

  selectedValue(event) {
    this.intervalValue = event.value;
    this.customValue = 0
    this.recordsFound = true
    this.getDataForChart()
  }

  getCompanyListing() {
    this.isProcessing = true;
    this.userService.getCompaniesDropDown().subscribe(res => {
      try {
        this.isProcessing = false;
        const response = res;
        this.companiesListing = response.data.records;
        this.filteredOptions = this.companiesListing;
        this.getDataForChart();
      }
      catch (e) {
        this.isProcessing = false;
      }
    }, (err) => {
      this.isProcessing = false;
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  onCompanyChange(event) {
    console.log(event)
    this.companyId = event.value
    this.recordsFound = true
    this.getDataForChart();
    if (this.companyId != 'all') {
      this.getUsageCount();
    }
  }
}

export interface Element {
  user: string,
  api_key: string,
  request_date: string,
  number_request: string
}







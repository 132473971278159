import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProductService } from '../../services/product.service';
import { CommonService } from '../../services/common.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { userRoles } from 'src/app/models/user-roles.model';

@Component({
  selector: 'app-update-sub-user',
  templateUrl: './update-sub-user.component.html',
  styleUrls: ['./update-sub-user.component.scss']
})
export class UpdateSubUserComponent implements OnInit {

  subUsers: UntypedFormGroup;
  //userArray: Array<any> = []
  isFetchingFrom: boolean = false
  isProcessing: boolean = false
  products: Array<any> = []
  constructor(
    private productService: ProductService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<UpdateSubUserComponent>,
    private router: Router) { }

  ngOnInit() {
    this.subUsers = new UntypedFormGroup({

    })
    this.getUserProducts()
  }


  getUserProducts() {
    this.isFetchingFrom = true
    this.productService.getSubCustomer().subscribe(res => {
      this.isFetchingFrom = false
      try {
        const response = res;
        let data = response.data
        //this.userArray = data.users
        this.products = data.products
        if (data.products.length > 0) {
          data.products.forEach(product => {
            this.addProductControl(product)
          });
        }
      } catch (error) {

      }
    }, (error) => {
      let response = error;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isFetchingFrom = false;
    })
  }

  addProductControl(p_data) {
    let formControls = []
    if (p_data['Product_Id'] == 1) {
      let AutpControl = new UntypedFormGroup({
        product_id: new UntypedFormControl(p_data['Product_Id']),
        Users: new UntypedFormControl(p_data['User_Ids'], [Validators.maxLength(p_data['TotalUsers'])])
      })
      formControls.push()
      this.subUsers.addControl('autp', AutpControl);
    } else if (p_data['Product_Id'] == 2) {
      let IVControl = new UntypedFormGroup({
        product_id: new UntypedFormControl(p_data['Product_Id']),
        Users: new UntypedFormControl(p_data['User_Ids'], [Validators.maxLength(p_data['TotalUsers'])])
      })
      this.subUsers.addControl('iv', IVControl);
    } else if (p_data['Product_Id'] == 3) {
      let IVControl = new UntypedFormGroup({
        product_id: new UntypedFormControl(p_data['Product_Id']),
        Users: new UntypedFormControl(p_data['User_Ids'], [Validators.maxLength(p_data['TotalUsers'])])
      })
      this.subUsers.addControl('rego', IVControl);
    } else if (p_data['Product_Id'] == 4) {
      let IVControl = new UntypedFormGroup({
        product_id: new UntypedFormControl(p_data['Product_Id']),
        Users: new UntypedFormControl(p_data['User_Ids'], [Validators.maxLength(p_data['TotalUsers'])])
      })
      this.subUsers.addControl('vin', IVControl);
    } else if (p_data['Product_Id'] == 6) {
      let IVControl = new UntypedFormGroup({
        product_id: new UntypedFormControl(p_data['Product_Id']),
        Users: new UntypedFormControl(p_data['User_Ids'], [Validators.maxLength(p_data['TotalUsers'])])
      })
      this.subUsers.addControl('truck', IVControl);
    }
    let p = this.subUsers.get('autp')
    console.log(p)
    let a = this.subUsers.get('iv')
    console.log(a)
    console.log(this.subUsers)
  }


  getControlName(product) {
    if (product['Product_Id'] == 1) {
      return 'autp'
    } else if (product['Product_Id'] == 2) {
      return 'iv'
    } else if (product['Product_Id'] == 3) {
      return 'rego'
    } else if (product['Product_Id'] == 4) {
      return 'vin'
    } else if (product['Product_Id'] == 6) {
      return 'truck'
    }
  }

  checkControlExists(product) {
    if (product['Product_Id'] == 1) {
      return this.subUsers.get('autp')
    } else if (product['Product_Id'] == 2) {
      return this.subUsers.get('iv')
    } else if (product['Product_Id'] == 3) {
      return this.subUsers.get('rego')
    } else if (product['Product_Id'] == 4) {
      return this.subUsers.get('vin')
    } else if (product['Product_Id'] == 6) {
      return this.subUsers.get('truck')
    }
  }

  getControl(product, subName = null) {
    let controlName = ''
    if (product['Product_Id'] == 1) {
      controlName = 'autp'
    } else if (product['Product_Id'] == 2) {
      controlName = 'iv'
    } else if (product['Product_Id'] == 3) {
      controlName = 'rego'
    } else if (product['Product_Id'] == 4) {
      controlName = 'vin'
    } else if (product['Product_Id'] == 6) {
      controlName = 'truck'
    }
    if (subName != null) {
      return this.subUsers.get(`${controlName}.${subName}`)
    } else {
      return this.subUsers.get(`${controlName}`)
    }
  }

  saveProducts(form) {
    if (form.valid) {
      let formData = JSON.stringify(form.value);
      formData = JSON.parse(formData);
      for (let i = 0; i < this.products.length; i++) {
        for (let j = 0; j < this.products[i].Users.length; j++) {
          if (this.products[i].Users[j].Role_Id === userRoles.companyOwner.toString()) {
            console.log(this.products[i].Users[j].User_Id, "owner");
            if (formData["autp"]) {
              formData["autp"]['Users'] = formData["autp"]['Users'].filter(u => u != this.products[i].Users[j].User_Id.toString());
            }
            if (formData["truck"]) {
              formData["truck"]['Users'] = formData["truck"]['Users'].filter(u => u != this.products[i].Users[j].User_Id.toString());
            }
          }
        }
      }
      this.isProcessing = true
      this.productService.saveSubCustomer(formData).subscribe(res => {
        this.isProcessing = false
        try {
          const response = res;
          this.dialogRef.close();
          this.commonService.showSnakeBar(response.message);
          window.location.reload();
        } catch (error) {

        }
      }, (error) => {
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      });
    } else {
      this.commonService.showSnakeBar('Please correct the errors.');
    }
  }
}

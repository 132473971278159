import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../../services/company.service';
import { CommonService } from '../../../services/common.service';
import { AzureService } from '../../../services/azure.service';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/configs/app-settings';
//import { GaEventsService } from '../../../services/ga-events.service';

@Component({
  selector: 'app-add-company-v2',
  templateUrl: './add-company-v2.component.html',
  styleUrls: ['./add-company-v2.component.scss']
})
export class AddCompanyV2Component implements OnInit {
  public isProcessing: boolean = false;
  public loadingPer: boolean = false

  public companyForm: UntypedFormGroup;
  public onCompanyAdd: EventEmitter<any> = new EventEmitter();
  private allowedfiles = ["png", "jpg", "jpeg", "bmp"];
  public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  public phoneNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public isUploading: boolean = false;
  public companyLogoModel: string = '';
  public customerTypes = [];

  public Offer = null

  formError = {};
  step = 1
  CompanyId = null
  isSubProcessing = false
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCompanyV2Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    public companyService: CompanyService,
    public commonService: CommonService,
    public azureService: AzureService,
    private route: Router,
    private appSettings: AppSettings
    //private gaEvent: GaEventsService
  ) {

  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.customerTypes = this.appSettings.CustomerTypes;
    this.companyForm = new UntypedFormGroup({
      'photoProfile': new UntypedFormControl(null),
      'thumbnailLogo': new UntypedFormControl(null),
      'companyName': new UntypedFormControl(null, [
        Validators.required, Validators.minLength(3), Validators.maxLength(200)
      ]),
      'email': new UntypedFormControl(null, [
        Validators.required, Validators.email, Validators.maxLength(255)
      ]),
      'userName': new UntypedFormControl(null, [
        Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
      ]),
      'jobTitle': new UntypedFormControl(null, [
        Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
      ]),
      'abn_number': new UntypedFormControl(null, [Validators.required]),
      'customerType': new UntypedFormControl(null, [Validators.required])
    });
  }

  checkValidABN() {
    if (this.companyForm.get('abn_number')!.valid) {
      this.companyForm.patchValue({ abn_number: this.companyForm.get('abn_number').value.replace(/ /g, '') });
      this.getABNDetails();
    }
  }

  abnLoader: boolean = false;
  getABNDetails() {
    let abn_number = this.companyForm.get('abn_number').value.replace(/ /g, '');
    if (abn_number && !this.commonService.ABNValidation(abn_number)) {
      this.handleServerFormError({
        'abn_number': 'Please enter a valid ABN number'
      })
      //this.formError['abn_number'] = 'Please enter a valid ABN number'
      this.commonService.showSnakeBar('Please enter a valid ABN number');
      this.companyForm.get('companyName')!.setValue('');
      return false
    }
    this.abnLoader = true
    this.companyService.checkABN(this.companyForm.get('abn_number').value).subscribe(
      (res) => {
        this.abnLoader = false;
        try {
          const response = res;
          if (response.data && response.data['EntityName']) {
            console.log(response.data['EntityName'])
            this.companyForm.get('companyName').setValue(response.data['EntityName']);
          }
        } catch (e) {
          console.log(e, 'error');
        }
      },
      (err) => {
        let response = err;
        this.abnLoader = false;
        // clear invalid abn number
        this.companyForm.get('abn_number').setValue('');
        this.companyForm.get('companyName').setValue('');
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.showSnakeBar('Invalid ABN number. PLease try to enter valid one.');
        }
      },
      () => { }
    );
  }

  saveCompany(formData) {
    this.isProcessing = true;
    const fmCopy = Object.assign({}, formData);
    fmCopy['userPic'] = fmCopy['profilePhoto'];
    delete fmCopy['profilePhoto'];
    this.companyService.addCompany(formData).subscribe(res => {
      try {
        const response = res;
        let data = response.data
        if (data['CompanyId']) {
          this.CompanyId = data['CompanyId']
          //this.step = 2
          this.onCompanyAdd.emit(response.data);
          let gaData = {
            'event': 'userSignup',
            'value': formData.companyName
          }
          //this.gaEvent.sendGaTagConfigData(gaData)
          this.commonService.showSnakeBar(response.message);
          let cnfm = confirm('Do you want to add configuration for this company?')
          if (cnfm == true) {
            this.route.navigate(['/dashboard'], { queryParams: { companyId: this.CompanyId } });
          }
          this.dialogRef.close()
        } else {
          this.commonService.showSnakeBar("An error occurred while adding company. Please try again later");
        }
        //this.closeModal();
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.message) {
        if ('errors' in response) {
          this.handleServerFormError(response.errors)
        }
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    });
  }


  onCompanySubAdd(event) {
    // console.log(event)
    this.onCompanyAdd.emit(event)
  }

  addNewCompany(form) {
    //console.log(form.controls, form.valid)
    if (form.valid) {
      let abn_number = form.value.abn_number

      if (abn_number && !this.commonService.ABNValidation(abn_number)) {
        this.handleServerFormError({
          'abn_number': 'Please enter a valid ABN number'
        })
        //this.formError['abn_number'] = 'Please enter a valid ABN number'
        this.commonService.showSnakeBar('Please enter a valid ABN number');
        this.companyForm.get('companyName')!.setValue('');
        return false
      } else {
        this.formError['abn_number'] = ''
      }
      this.formError = {}
      this.saveCompany(form.value)
    }
    else {
      this.formValidate(form);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.companyForm.contains(key)) {
          let control = this.companyForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  setImageUrl(mainUrl, thumbUrl) {
    if (mainUrl && mainUrl != '') {
      this.companyForm.controls.photoProfile.setValue(mainUrl.toString());
    }
    if (thumbUrl && thumbUrl != '') {
      this.companyForm.controls.thumbnailLogo.setValue(thumbUrl.toString())
    }
    this.companyLogoModel = thumbUrl;
  }

  fileChange(event) {
    if (event.target.files[0] != undefined && this.validateFile(event.target.files[0].name)) {
      this.companyLogoModel = '';
      let file: File = event.target.files[0];
      let fileName: string = (new Date()).getTime() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
      if (!this.validateFileSize(file.size)) {
        this.commonService.showSnakeBar("File size should be less than or equal to 5 MB");
      }
      else {
        this.startImageLoading();
        this.commonService.getImageEssentials(file).subscribe(mainImage => {
          this.commonService.compressImage(mainImage.image, file.type, [400, 100], mainImage.width, mainImage.height).subscribe(compRes => {
            if (compRes.status) {
              const reqModel = {
                fileName: fileName,
                thumbName: 'thumb' + fileName
              }
              this.azureService.getSASToken(reqModel).subscribe(res => {
                try {
                  const result = res;
                  if (result.status) {
                    for (let i = 0; i < result.data.length; i++) {
                      let newFile = <File>this.createFile(compRes.data.blobs[i], file.name);
                      this.azureService.putObject(result.data[i].uri, newFile).subscribe((res: any) => {
                        if (res.ok && i == 1) {
                          this.setImageUrl(reqModel.fileName, reqModel.thumbName);
                          this.stopImageLoading();
                        }
                      })
                    }
                  }
                }
                catch (ex) {
                  this.commonService.showSnakeBar("Something went wrong");
                  this.stopImageLoading();
                }
              }, (err) => {
                let response = err;
                if (response.message) {
                  this.commonService.showSnakeBar(response.message);
                }
                this.stopImageLoading();
              })
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
              this.stopImageLoading();
            }
          })
        })
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowedfiles.indexOf(ext.toLowerCase()) != -1) {
      return true;
    }
    else {
      this.commonService.showSnakeBar("Please try with valid file extensions (.jpg, .jpeg, .bmp, .png)");
      return false;
    }
  }

  resetImageInput(event) {
    if (event.target.files[0] != undefined) {
      event.target.value = "";
    }
  }

  validateFileSize(fileSize: number) {
    if (((fileSize / 1024) / 1024) > 5) {
      return false;
    }
    else {
      return true;
    }
  }

  private createFile(fileBlob: Blob, fileName: string): File {
    const file: File = <File>Object.assign(fileBlob, { name: fileName });
    return file;
  }

  startImageLoading() {
    this.isUploading = true;
    this.isProcessing = true;
  }

  stopImageLoading() {
    this.isUploading = false;
    this.isProcessing = false;
  }

  setOffer(event) {
    this.Offer = event
    this.step = 3
  }

  subInProgress(event) {
    this.isSubProcessing = event
  }

}

import { Component, OnInit, Inject, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CompanyService } from "src/app/services/company.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-add-offer",
  templateUrl: "./add-offer.component.html",
  styleUrls: ["./add-offer.component.scss"]
})
export class AddOfferComponent implements OnInit {
  companyId = null;
  step = 1;
  offer = {};
  isSubProcessing = false;
  public onCompanyOfferAdded: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public companyService: CompanyService,
    public commonService: CommonService
  ) {
    this.companyId = this.data["companyId"];
  }

  ngOnInit() { }

  setOffer(event) {
    ///console.log(event, "event");
    this.offer = event;
    this.step = 2;
  }

  subInProgress(event) {
    this.isSubProcessing = event
  }

  setStep(step) {
    if (this.step != step) {
      this.step = step
    }
  }

  onCompanySubAdd(event) {
    console.log('here')
    this.onCompanyOfferAdded.emit(event)
    this.dialog.closeAll()
  }
}

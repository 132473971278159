import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from '../../services/company.service';
import { CommonService } from '../../services/common.service';
import { DataStoreService } from '../../services/data-store.service';
import { Subscription } from 'rxjs';
import { AddCompanyV2Component } from '../portal/add-company-v2/add-company-v2.component';
import { PopupLoaderComponent } from 'src/app/shared/popup-loader/popup-loader.component';
import { ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AppSettings } from 'src/app/configs/app-settings';

interface CompanyDropdown {
  Company_Id: number,
  Name: string
}

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})

export class CompaniesListComponent implements OnInit {
  public isProcessing: boolean = false;
  public companyList = [];
  public isPaging: boolean = false;
  public pagingParams: any;
  public totalRecords: number = 0;
  public getCompanyListSignal: Subscription;
  public listCompleted: boolean = false;
  noMoreRec = false;

  @ViewChild('search', { static: false }) searchTextBox: ElementRef;

  selectFormControl = new UntypedFormControl();
  searchTextboxControl = new UntypedFormControl();
  selectedCompanies = [];
  selectedValues = [];
  selectedProduct = '';
  selectedCustomerType = '';
  enteredEmail = '';
  companyStatus = '';
  isSearch: boolean = false;
  companyDropdown: Array<CompanyDropdown> = [];
  filteredOptions: Array<CompanyDropdown> = [];
  resetTriggered: boolean = false;
  public companyProducts = [];
  constructor(
    public dialog: MatDialog,
    public companyService: CompanyService,
    public commonService: CommonService,
    public dataStore: DataStoreService,
    private userService: UserService,
    public appSettings: AppSettings
  ) {
    this.companyProducts = this.appSettings.companyProducts;
    this.getCompanyListSignal = this.dataStore.getCompanyListSignal().subscribe(res => {
      if (res && res.companyStatusChange) {
        const index = this.companyList.findIndex(obj => obj.CompanyId == res.data.companyId);
        if (index != -1) {
          this.companyList[index].CompanyStatus = res.data.status;
        }
      }
      else {
        this.pagingParams = this.getPagingParams();
        this.companyList = [];
        this.listCompleted = false;
        this.getCompaniesList(false);
      }
    });
  }

  ngOnInit() {
    this.pagingParams = this.getPagingParams();
    this.getCompaniesList(false);
    this.getCompanyDropDown();

    this.searchTextboxControl.valueChanges.subscribe(res => {
      this.filteredOptions = this._filter(res);
    })
  }


  timeout: any = null;
  filterChanged(event: any, filterType: string) {
    this.pagingParams.page = 1;
    if (!this.isProcessing && !this.resetTriggered) {
      if (filterType === 'email') {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (event.keyCode != 13) {
            this.searchCompanies();
          }
        }, 1000);
      } else {
        this.searchCompanies();
      }
    }
  }

  searchCompanies() {
    this.isSearch = true;
    this.companyList = [];
    this.getCompaniesList(false);
  }

  resetFilters() {
    this.pagingParams.page = 1;
    this.resetTriggered = true;
    this.enteredEmail = "";
    this.companyStatus = "";
    this.selectedProduct = "";
    this.selectedCustomerType = "";
    this.selectedValues = [];
    this.selectedCompanies = [];
    this.selectFormControl.patchValue([]);
    this.listCompleted = false;
    setTimeout(() => {
      this.resetTriggered = false
    }, 1000);
    //load all companies
    this.searchCompanies();
  }

  getCompanyDropDown() {
    this.userService.getCompaniesDropDown(true).subscribe(res => {
      try {
        const response = res;
        this.companyDropdown = response.data;
        this.filteredOptions = response.data;
      }
      catch (e) {
        this.isProcessing = false;
      }
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }


  private _filter(name: string): Array<CompanyDropdown> {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    let filteredList = this.companyDropdown.filter((option) => {
      return option.Name.toLowerCase().indexOf(filterValue) > -1
    });
    return filteredList;
  }

  selectCompanyClosed() {
    const isArraysSame = this.selectedCompanies.length === this.selectedValues.length && this.selectedCompanies.every((element, index) => {
      return element === this.selectedValues[index];
    });
    if (!isArraysSame) {
      this.pagingParams.page = 1;
      this.searchCompanies();
    }
  }

  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedValues() {
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  getPagingParams() {
    return {
      page: 1,
      limit: 30,
      sort: 'asc',
    }
  }

  ngOnDestroy() {
    if (this.getCompanyListSignal) {
      this.getCompanyListSignal.unsubscribe();
    }
  }

  addNewCompany(): void {
    const dialogRef = this.dialog.open(AddCompanyV2Component, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: false
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyAdd.subscribe(res => {
      let companyData = res

      const obj = {
        data: companyData,
        companyAdded: true,
        companyUpdated: false,
        companyStatusChange: false
      }
      this.dataStore.sendCompanyListSignal(obj);

      // const dialogRef = this.dialog.open(AddOfferComponent, {
      //   width: "auto",
      //   height: "auto",
      //   data: {
      //     companyId: res['CompanyId']
      //   },
      //   disableClose: true
      // });
      // dialogRef.componentInstance.onCompanyOfferAdded.subscribe(res => {
      //   // console.log('here...')
      //   const obj = {
      //     data: companyData,
      //     companyAdded: true,
      //     companyUpdated: false,
      //     companyStatusChange: false
      //   }
      //   this.dataStore.sendCompanyListSignal(obj);
      // })
    })
  }

  getCompaniesList(isForPaging) {
    this.toggleLoader(isForPaging);
    if (this.isSearch) {
      let selectedCmp = [...this.selectedValues];
      this.pagingParams = {
        ...this.pagingParams,
        ...{
          companyNames: selectedCmp.length > 0 ? selectedCmp.join('|') : '',
          emailId: this.enteredEmail ? this.enteredEmail : '',
          product: this.selectedProduct ? this.selectedProduct : '',
          companyStatus: this.companyStatus ? this.companyStatus : '',
          customerType: this.selectedCustomerType ? this.selectedCustomerType : ''
        }
      }
    }
    this.listCompleted = false;
    this.companyService.getCompanyListing(this.pagingParams).subscribe(res => {
      try {
        const response = res;
        this.appendCompanyListing(response.data.records);
        this.totalRecords = response.data.total;
        if (this.totalRecords == 0 && isForPaging === true) {
          this.noMoreRec = true;
        } else {
          this.noMoreRec = false;
        }
        this.selectedValues.forEach(elem => {
          this.selectedCompanies.push(elem);
        });

      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    })
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.companyList.length > 0) {
        newState = this.companyList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.companyList = newState;
      if (this.companyList.length == this.totalRecords) {
        this.listCompleted = true;
      }
    }
  }

  toggleLoader(isForPaging) {
    if (isForPaging) {
      this.isPaging = !this.isPaging;
    }
    else {
      this.isProcessing = !this.isProcessing;
    }
  }

  loadMore() {
    if (this.isProcessing === true || this.isPaging === true) {
      return false;
    }
    this.pagingParams.page++;
    if (this.totalRecords > 10) {
      this.getCompaniesList(true)
    }
  }

  createConsumer(CompanyId: number) {
    //this.isProcessing = true;
    const confirmDialogRef = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Creating...'
      }
    });
    this.companyService.createConsumer(CompanyId).subscribe(res => {
      confirmDialogRef.close()
      try {
        const response = res;
        this.commonService.showSnakeBar(response.message)
        this.updateCompanyRow({ action: 'consumer', CompanyId: CompanyId })
      }
      catch (e) {
      }
      //this.isProcessing = false;
    }, (err) => {
      confirmDialogRef.close()
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      //this.isProcessing = false;
    })
  }

  updateCompanyRow(data) {
    if (data['action'] == 'consumer') {
      if (data['CompanyId']) {
        let index = this.companyList.findIndex(el => el.CompanyId == data['CompanyId'])
        if (index != -1) {
          this.companyList[index].isApi = 1
        }
      }
    }
  }

  handleProcessAction(data) {
    //console.log(data)
    if (data['action'] == 'consumer') {
      if (data['CompanyId']) {
        this.createConsumer(data['CompanyId'])
      }
    }
  }
}

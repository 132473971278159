import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { ProfileImgComponent } from './profile-img/profile-img.component';
import { HasPermissionDirective } from './has-permission.directive';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SvgIconComponent, ProfileImgComponent, HasPermissionDirective],
  exports: [SvgIconComponent, ProfileImgComponent, HasPermissionDirective]
})
export class ApCommonModule { }

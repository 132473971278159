import { Component, OnInit } from '@angular/core';

export interface Role {
  value: string;
  viewValue: string;
}

export interface Dropdown {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-uikit',
  templateUrl: './uikit.component.html',
  styleUrls: ['./uikit.component.scss']
})
export class UikitComponent implements OnInit {

  disabled = false;
  invert
  step = 5
  thumbLabel
  tickInterval
  vertical
  value
  constructor() { }

  ngOnInit() {
  }

  roles: Role[] = [
    { value: 'read-only', viewValue: 'Read Only' },
    { value: 'read-write', viewValue: 'Read & Write' }
  ];

  dropdowns: Dropdown[] = [
    { value: 'ford', viewValue: 'Ford' },
    { value: 'hyundai', viewValue: 'Hyundai' },
    { value: 'mercedes', viewValue: 'Mercedes' },
    { value: 'renault', viewValue: 'Ranault' },
    { value: 'cheverolet', viewValue: 'Cheverolet' }
  ];

}

import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ApCommonModule } from "../ap-common/ap-common.module";

import { MaterialComponentsModule } from "../material-components.module";

import { FormsModule } from "@angular/forms";

import { SharedModule } from "../shared/shared.module";

import { ConfirmationPopupComponent } from "../shared/confirmation-popup/confirmation-popup.component";

import { CompaniesListComponent } from "./companies-list/companies-list.component";
import { CompanyRowDetailsComponent } from "./company-row-details/company-row-details.component";
import { AddCompanyComponent } from "./add-company/add-company.component";
import { UpdateCompanyComponent } from "./update-company/update-company.component";
import { AddProductComponent } from "./portal/add-product/add-product.component";
import { AddCompanyV2Component } from "./portal/add-company-v2/add-company-v2.component";
import { UpdateCompanyV2Component } from "./portal/update-company-v2/update-company-v2.component";
import { DynamicFormComponent } from "./portal/dynamic-form/dynamic-form.component";
import { ProductComponent } from "./product/product.component";
import { EditProductComponent } from "./edit-product/edit-product.component";
import { UsageComponent } from "./usage/usage.component";
import { InstaApiKeyComponent } from "./insta-api-key/insta-api-key.component";

import { ClipboardModule } from "ngx-clipboard";
import { ProductOfferComponent } from "./product-offer/product-offer.component";
import { OfferListingComponent } from "./portal/offer-listing/offer-listing.component";
import { AddProductV2Component } from "./portal/add-product-v2/add-product-v2.component";
import { SubscriptionListComponent } from "./subscription/subscription-list/subscription-list.component";
import { RenewSubscriptionComponent } from "./subscription/renew-subscription/renew-subscription.component";
import { SubscriptionService } from "../services/subscription.service";
import { AddOfferComponent } from "./subscription/add-offer/add-offer.component";
import { CompanyMasterComponent } from "./company-master/company-master.component";
import { CouponsComponent } from "./coupons/coupons.component";
import { AddCouponComponent } from "./add-coupon/add-coupon.component";
import { ProductsListingComponent } from "./products-listing/products-listing.component";
import { EditProductDetailsComponent } from "./edit-product-details/edit-product-details.component";
import { QuotesListingComponent } from "./quotes-listing/quotes-listing.component";
import { SuperAdminQuoteDetailsComponent } from "./super-admin-quote-details/super-admin-quote-details.component";
import { InvoicesListingComponent } from "./invoices-listing/invoices-listing.component";
import { AutoPredictDetailsComponent } from "./autopredict-details/autopredict-details.component";
import { CartDetailsComponent } from "./cart-details/cart-details.component";
import { SelectMakesModalComponent } from "./select-makes-modal/select-makes-modal.component";
import { CompanyAdminQuotesComponent } from "./company-admin-quotes/company-admin-quotes.component";
import { CompanyAdminQuoteDetailsComponent } from "./company-admin-quote-details/company-admin-quote-details.component";
import { CompanyAdminPaymentsComponent } from "./company-admin-payments/company-admin-payments.component";
import { CompanyAdminSubscriptionsComponent } from "./company-admin-subscriptions/company-admin-subscriptions.component";
import { AddProductConfigComponent } from "./add-product-config/add-product-config.component";
import { AddMakePopupComponent } from './add-make-popup/add-make-popup.component';
import { MakesModalHistoryComponent } from './makes-modal-history/makes-modal-history.component';
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}
@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        FormsModule,
        SharedModule,
        ApCommonModule,
        ClipboardModule,
        RouterModule,
    ],
    declarations: [
        CompaniesListComponent,
        CompanyRowDetailsComponent,
        AddCompanyComponent,
        UpdateCompanyComponent,
        AddProductComponent,
        AddCompanyV2Component,
        UpdateCompanyV2Component,
        DynamicFormComponent,
        ProductComponent,
        EditProductComponent,
        UsageComponent,
        InstaApiKeyComponent,
        ProductOfferComponent,
        OfferListingComponent,
        AddProductV2Component,
        SubscriptionListComponent,
        RenewSubscriptionComponent,
        AddOfferComponent,
        CompanyMasterComponent,
        CouponsComponent,
        AddCouponComponent,
        ProductsListingComponent,
        EditProductDetailsComponent,
        QuotesListingComponent,
        SuperAdminQuoteDetailsComponent,
        InvoicesListingComponent,
        AutoPredictDetailsComponent,
        CartDetailsComponent,
        SelectMakesModalComponent,
        CompanyAdminQuotesComponent,
        CompanyAdminQuoteDetailsComponent,
        CompanyAdminPaymentsComponent,
        CompanyAdminSubscriptionsComponent,
        AddProductConfigComponent,
        AddMakePopupComponent,
        MakesModalHistoryComponent,
    ],
    exports: [],
    providers: [DatePipe, SubscriptionService]
})
export class CompaniesModule { }

import { Component, OnInit, Input } from '@angular/core';

import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-profile-img',
  templateUrl: './profile-img.component.html',
  styleUrls: ['./profile-img.component.scss']
})
export class ProfileImgComponent implements OnInit {

  @Input() type: string = ""
  constructor(
    public jwtService: JwtService,
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
  }

}

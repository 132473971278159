import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  showLoader: boolean = true;
  constructor(
    public jwtService: JwtService,
    private router: Router,
  ) {
    this.logout();
  }

  ngOnInit() {
  }

  logout() {
    //this.jwtService.deleteLocalStorage();
    window.localStorage.clear();
    this.router.navigate(["/login"]);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneVerificationStepsComponent } from '../phone-verification-steps/phone-verification-steps.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialComponentsModule } from 'src/app/material-components.module';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialComponentsModule,
    NgOtpInputModule
  ],
  declarations: [
    PhoneVerificationStepsComponent,
    OtpVerificationComponent
  ],
  exports: [
    OtpVerificationComponent,
    PhoneVerificationStepsComponent
  ]
})
export class VerificationModule { }

/*
  *
  * @ngdoc Services
  * @name app.services.AdminUsersService
  * @description
  * The AdminUsersService Service is used for handling the request related to admin User
  *
*/

import { Injectable } from '@angular/core';

import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }

  /*
  * @name getUserListing
  * @param
  * @description get Company User Listing
  * @return Observable
  */
  getAdminUserListing(filter) {
    return this.commonHttpService.get(this.apiUrls.admin.userListing, filter);
  }

  /*
  * @name addUpdateUser
  * @param  
  * @description add/update admin user
  * @return Observable
  */
  addUpdateUser(data) {
    return this.commonHttpService.post(this.apiUrls.admin.deleteUser, data)
  }

  /*
  * @name deleteAdminUser
  * @param  
  * @description delete admin user
  * @return Observable
  */
  deleteAdminUser(data) {
    return this.commonHttpService.post(this.apiUrls.admin.deleteUser, data);
  }

  /*
  * @name addUserToCompany
  * @param  
  * @description add user to company
  * @return Observable
  */
  addUserToCompany(data) {
    return this.commonHttpService.post(this.apiUrls.admin.addUser, data);
  }

  /*
  * @name updateCompanyUser
  * @param  
  * @description update company user
  * @return Observable
  */
  updateCompanyUser(data) {
    return this.commonHttpService.post(this.apiUrls.admin.UpdateUser, data);
  }
}

/*
  *
  * @ngdoc Services
  * @name app.services.UserService
  * @description
  * The UserService Service is used for handling the request related to User
  *
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }

  /*
  * @name getCompanyListing
  * @param
  * @description get Company User Listing
  * @return Observable
  */
  getCompanyUserListing(filter) {
    return this.commonHttpService.get(this.apiUrls.user.userListing, filter);
  }

  /*
  * @name getCompanyListing
  * @param
  * @description get User Permission List
  * @return Observable
  */
  getUserPermissionList() {
    return this.commonHttpService.get(this.apiUrls.user.permissionListing);
  }

  /*
  * @name getCompanyListing
  * @param
  * @description get User Permission List
  * @return Observable
  */
  addUserToCompany(data) {
    return this.commonHttpService.post(this.apiUrls.user.addUserToCompany, data);
  }

  /*
  * @name validateInviteToken
  * @param
  * @description set Password
  * @return Observable
  */
  validateInviteToken(data) {
    return this.commonHttpService.post(this.apiUrls.user.validateInviteToken, data);
  }

  /*
  * @name setPassword
  * @param
  * @description set Password
  * @return Observable
  */
  setPassword(data) {
    return this.commonHttpService.post(this.apiUrls.user.setPassword, data);
  }

  /*
  * @name resetPassword
  * @param
  * @description reset Password
  * @return Observable
  */
  resetPassword(data) {
    return this.commonHttpService.post(this.apiUrls.user.resetPassword, data);
  }

  /*
  * @name login
  * @param
  * @description login
  * @return Observable
  */
  login(data) {
    return this.commonHttpService.post(this.apiUrls.user.login, data);
  }

  /*
  * @name forgotPassword
  * @param
  * @description forgotPassword
  * @return Observable
  */
  forgotPassword(data) {
    return this.commonHttpService.post(this.apiUrls.user.forgotPassword, data);
  }

  /*
  * @name updatePassword
  * @param
  * @description updatePassword
  * @return Observable
  */
  changePassword(data) {
    return this.commonHttpService.post(this.apiUrls.user.updatePassword, data);
  }

  /*
  * @name updateProfile
  * @param
  * @description updateProfile
  * @return Observable
  */
  updateProfile(data) {
    return this.commonHttpService.post(this.apiUrls.user.updateProfile, data);
  }

  /*
  * @name editUserDetails
  * @param
  * @description editUserDetails
  * @return Observable
  */
  updateCompanyUser(data) {
    return this.commonHttpService.post(this.apiUrls.user.editUserDetails, data);
  }

  /*
  * @name deleteUser
  * @param
  * @description deleteUser
  * @return Observable
  */
  deleteUser(data) {
    return this.commonHttpService.post(this.apiUrls.user.deleteUser, data);
  }

  /*
  * @name resendInvite
  * @param
  * @description resend invitation to user
  * @return Observable
  */
  resendInvite(data) {
    return this.commonHttpService.post(this.apiUrls.user.resendInviteUser, data);
  }

  /*
  * @name logout
  * @param
  * @description logout
  * @return Observable
  */
  logout() {
    return this.commonHttpService.post(this.apiUrls.user.logout, {});
  }

  /* @name getCompaniesListing
    * @param
    * @description get companies listing
    * @return Observable
    */
  getCompaniesListing() {
    return this.commonHttpService.get(this.apiUrls.user.companyListing, {});
  }


  /* @name getCompaniesDropDown
  * @param
  * @description get companies listing drop down
  * @return Observable
  */
  getCompaniesDropDown(isCompany: boolean = false) {
    return this.commonHttpService.get(this.apiUrls.user.companyDropDown, { isCompany: isCompany });
  }

  /*
  * @name oktaLogin
  * @param
  * @description login
  * @return Observable
  */
  oktaLogin(data) {
    return this.commonHttpService.post(this.apiUrls.user.oktaLogin, data);
  }

  /*
  * @name oktaLoginUrl
  * @param
  * @description login url
  * @return Observable
  */
  oktaLoginUrl() {
    return this.commonHttpService.get(this.apiUrls.user.oktaLoginUrl);
  }


  getBackupTableData() {
    return this.commonHttpService.get(this.apiUrls.user.backupTable);
  }

  updateBackupTableData(params) {
    return this.commonHttpService.post(this.apiUrls.user.backupTable, params);
  }
}

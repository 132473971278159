import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { JwtService } from './services/jwt.service';
import { DataStoreService } from './services/data-store.service';
import { MatDialog } from '@angular/material/dialog'
import { UpdateSubUserComponent } from './my-account/update-sub-user/update-sub-user.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  showHead: boolean = false;
  public innerWidth: any;
  getPopupListSignal: Subscription
  constructor(
    private router: Router,
    private jwtService: JwtService,
    private dataStore: DataStoreService,
    private dialog: MatDialog
  ) {
    // _router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event['url'].includes('login') || event['url'].includes('forgot - password') || event['url'].includes('create-password')) {
    //       this.showHead = false;
    //     } else {
    //       this.showHead = true;
    //     }
    //   }
    // });
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects.includes('login') || val.urlAfterRedirects.includes('logout') || val.urlAfterRedirects.includes('forgot-password') || val.urlAfterRedirects.includes('create-password')) {
          this.showHead = false;
        }
        else if (this.jwtService.IsAuthUser()) {
          this.showHead = true;
        }
        else {
          this.showHead = false;
        }
      }
    });
    this.getPopupListSignal = this.dataStore.getSubPopupSignal().subscribe(result => {
      console.log(result, 'here')
      this.showUserSubscriptionPopup()
    })

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  showUserSubscriptionPopup() {
    console.log('here')
    const dialogRef = this.dialog.open(UpdateSubUserComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Creating trial subscription...'
      }
    });
    //console.log(dialogRef)
    /*dialogRef.afterClosed().subscribe((result) => {
      console.log('closed', result)
    })*/
  }

  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  ngOnDestroy() {
    if (this.getPopupListSignal) {
      this.getPopupListSignal.unsubscribe();
    }
  }
}

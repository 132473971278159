export enum httpStatusCodes {
    success = 200,
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    methodNotAllowed = 405,
    requestTimeout = 408,
    badGateway = 502,
    formError = 422
  }
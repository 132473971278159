import { Component, EventEmitter, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataStoreService } from 'src/app/services/data-store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileMenuComponent implements OnInit {

  closeMenu: boolean = true;
  @Input() showMobileMenu: boolean = false;
  @Input() userName: string = "";
  @Input() userEmail: string = "";
  @Output() menuStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dataStoreService: DataStoreService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  closeMobileMenu() {
    this.dataStoreService.setShowMobileMenuStatus(false);
    this.menuStatus.emit(this.closeMenu = false);
  }

  editProfile() {
    this.closeMobileMenu();
    this.router.navigate(['/my-account']);
  }

}

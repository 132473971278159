import { Injectable } from '@angular/core';
import { ApiUrls } from "../configs/api-urls";
import { CommonHttpService } from './common-http.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AzureService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls,
    private commonService: CommonService
  ) { }

  public getSASToken(filename) {
    return this.commonHttpService.get(this.apiUrls.user.azureAccessToken, filename)
  }

  public putObject(url, file) {
    return this.commonHttpService.azureObjectUpload(url, file);
  }
}

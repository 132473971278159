import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { CommonService } from 'src/app/services/common.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { JwtService } from 'src/app/services/jwt.service';
import { AppSettings } from 'src/app/configs/app-settings';
import { MatDialog } from '@angular/material/dialog';
import { PopupLoaderComponent } from 'src/app/shared/popup-loader/popup-loader.component';
import { TermSignComponent } from 'src/app/dashboard/term-sign/term-sign.component';
import { BillingAddressComponent } from 'src/app/dashboard/billing-address/billing-address.component';
import { ActivatedRoute, Router } from "@angular/router";
import { SubscriptionService } from 'src/app/services/subscription.service';
import { PhoneVerificationModalComponent } from 'src/app/dashboard/phone-verification-modal/phone-verification-modal.component';
import { verificationTypes } from 'src/app/models/verification-types.model';
import { ConfirmationPopupComponent } from "src/app/shared/confirmation-popup/confirmation-popup.component";
import { DataStoreService } from 'src/app/services/data-store.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public productListingByCompany: Array<any> = [];
  public activeServices: Array<any> = [];
  public inactiveServices: Array<any> = [];
  public isProcessing: boolean = false;
  public productUrls: any
  subFormat = null;
  companyId = null;
  queryParams = null;
  companyLoader = false;
  companyDetails = null;
  public showAuthModel: boolean = false;
  public isAnyProdPurchased: boolean = false;
  public isProdPurchasePending: boolean = false;
  public isAddConfigScreen: boolean = false;
  public usageCountLoader: boolean = false;
  public instantvalPendingRequest: number = 0;
  public hasTruckValBikeValAccess: boolean = false;
  public assetInfoUsageCount: number = 0;
  public salvageUsageCount: number = 0;
  constructor(
    private productService: ProductService,
    private commonService: CommonService,
    public roleCheckService: RoleCheckerService,
    public jwtService: JwtService,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public subscriptionService: SubscriptionService,
    private dataStoreService: DataStoreService
  ) {
    this.subFormat = this.appSettings.SUBSCRIPTION_FORMAT
    this.commonService.setTitle('Dashboard');
    if (this.roleCheckService.isSuperAdmin()) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.companyId = params['companyId'] ? params['companyId'] : null;
        this.queryParams = { 'companyId': this.companyId };
        this.isAddConfigScreen = params['companyId'] ? true : false;
        if (this.companyId) {
          this.getCompanyDetails();
        }
      });
    } else {
      this.companyId = this.jwtService.getCompanyId();
    }
    if (localStorage.getItem('AUTOP_PHONE_VERIFICATION_MODEL') !== null && localStorage.getItem('AUTOP_PHONE_VERIFICATION_MODEL') !== null
      && localStorage.getItem('AUTOP_PHONE_VERIFICATION_MODEL') === 'true') {
      this.dialog.open(PhoneVerificationModalComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          phone: this.jwtService.getUserPhone(),
          userId: this.jwtService.getUserId(),
          verificationFrom: verificationTypes.phoneVerifyOrUpdate,
          countryCode: this.jwtService.getUserDetails().countryCode
        }
      });
    }
  }

  ngOnInit() {
    this.loadProductsList();
  }

  loadProductsList() {
    this.getProductListByCompany();
    if (!this.roleCheckService.isSuperAdmin() && !this.roleCheckService.isCompanyUser()) {
      this.getUsageCount();
    }
  }

  sendToCompany() {
    this.commonService.showSnakeBar(
      "Unable to find the requested company."
    );
    this.router.navigate(["/companies"]);
  }

  getCompanyDetails() {
    this.companyLoader = true;
    this.subscriptionService
      .getCompanyDetails({ companyId: this.companyId })
      .subscribe(
        result => {
          this.companyLoader = false;
          try {
            let response = result;
            if (!response.data) {
              this.sendToCompany()
            }
            this.companyDetails = response.data

          } catch (e) { }
        },
        error => {
          this.companyLoader = false;
          let response = error;

          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => { }
      );
  }

  getProductListByCompany() {
    this.isProcessing = true;
    let params = {}
    if (this.companyId) {
      params = { companyId: this.companyId }
    }
    this.activeServices = [];
    this.inactiveServices = [];
    this.productService.getProductListingByCompany(params).subscribe(res => {
      try {
        const response = res;
        this.productListingByCompany = response.data;
        if (this.productListingByCompany[0].hasTruckValBikeValAccess) {
          this.hasTruckValBikeValAccess = this.productListingByCompany[0].hasTruckValBikeValAccess;
        }
        this.isAnyProdPurchased = false;
        this.isProdPurchasePending = false;
        this.productListingByCompany.sort(function (x, y) { return x.Product_ID == 2 ? -1 : y.Product_ID == 2 ? 1 : 0; });
        let productsAccess = [];
        //creating two arrays for active services and inactive services

        let hasInstantValAccess = false
        // For super admin, all services will be by default active
        if (this.roleCheckService.isSuperAdmin()) {
          this.activeServices = this.productListingByCompany;
          this.activeServices.forEach(product => {
            if (product.hasNavigationAccess === true) {
              productsAccess.push(product.Product_ID);
              if (product.Product_ID === this.appSettings.InstantVal) {
                hasInstantValAccess = true;
              }
            }
          });
          //for super admin, allow all products
          productsAccess.push(this.appSettings.TruckVal, this.appSettings.BikeVal);
        } else {
          this.productListingByCompany.forEach(product => {
            //creating two arrays for active services and inactive services
            if (((this.appSettings.AutoPredict == product.Product_ID || this.appSettings.PriceMyTruck == product.Product_ID) && product.CompanySubscription_Id == null && product.LastExpireOn === null) || (this.appSettings.InstantVal == product.Product_ID && !product.IsTakenTrial)) {
              this.isProdPurchasePending = true;
              this.inactiveServices.push(product);
            } else {
              this.isAnyProdPurchased = true;
              this.activeServices.push(product);

              if (product.hasNavigationAccess === true) {
                productsAccess.push(product.Product_ID);
              }
              //check if user also has truckval bikeval access
              if (this.appSettings.InstantVal == product.Product_ID && product.hasTruckValBikeValAccess) {
                productsAccess.push(this.appSettings.TruckVal, this.appSettings.BikeVal);
              }
              if (this.roleCheckService.isCompanyUser() && this.appSettings.InstantVal == product.Product_ID) {
                this.getUsageCount();
              }
              if (product.Product_ID === this.appSettings.InstantVal) {
                hasInstantValAccess = true;
              }
            }
          });
        }
        const hasSalvageAccess = this.activeServices.some(product => product.Product_ID === this.appSettings.SalvageVal);
        if (hasSalvageAccess) {
          const index = hasInstantValAccess ? 1 : 0;
          this.moveLastElementToPosition(this.activeServices, index);
        }
        this.jwtService.updateUserProductsAccess(productsAccess);
        //trigger services to update header nav dropdown
        this.dataStoreService.setUserProductAccess(true);
      }
      catch (e) {
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    })
  }

  moveLastElementToPosition(arr: any[], newPosition: number) {
    if (newPosition >= arr.length) {
      return; // Invalid position
    }

    const lastElement = arr.pop(); // Remove the last element from the array
    arr.splice(newPosition, 0, lastElement); // Insert the last element at the specified position
  }


  getUsageCount() {
    const reqModel = {
      'companyId': this.companyId ? this.companyId : null,
    }
    this.usageCountLoader = true
    this.productService.usageCountData(reqModel).subscribe(res => {
      this.usageCountLoader = false
      try {
        const response = res.data;
        this.instantvalPendingRequest = response.PendingRequest;
        this.assetInfoUsageCount = response.AssetInfoThisMonthCount;
        this.salvageUsageCount = response.SalvageValThisMonthCount;
      } catch (e) {
      }
    }, (err) => {
      this.usageCountLoader = false
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    });
  }

  contactUs(Product) {
    //this.isProcessing = true;
    let params = {
      Product_Id: Product['Product_ID'],
      Plan_Id: Product['Plan_Id']
    }
    // console.log(params, 'params')
    const confirmDialogRef = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Sending request...'
      }
    });
    this.productService.contactUs(params).subscribe(res => {
      confirmDialogRef.close()
      try {
        const response = res;
        this.commonService.showSnakeBar(response.message)
      }
      catch (e) {
      }
      //this.isProcessing = false;
    }, (err) => {
      confirmDialogRef.close()
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      //this.isProcessing = false;
    })
  }

  getTrial(Product) {
    //this.isProcessing = true;
    const confirmDialogRef = this.dialog.open(BillingAddressComponent, {
      width: '500px',
      height: 'auto',
      data: {
        Product_Id: Product['Product_ID']
      }
    });
    confirmDialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadProductsList();
      }
    });
  }

  getProductUrl(productUrl) {
    let signInUrl = productUrl + "?token=" + this.jwtService.getAccessToken();
    return signInUrl;
  }

  getProductExpireDateText(product) {
    const datePipe = new DatePipe('en-US');
    if (product.EndDate !== null) {
      const endDate = datePipe.transform(product.EndDate, 'dd MMM yyyy');
      if (product.IsTrialActive == 1) {
        const pendingDays = this.getDaysRemaining(product);
        let daysText = "Expires ";
        if (pendingDays > 1) {
          daysText = daysText + "in " + pendingDays.toString() + " days";
        } else if (pendingDays === 1) {
          daysText = daysText + "tommorrow";
        } else {
          daysText = daysText + "today";
        }
        return daysText + " (" + endDate + ")";
      } else {
        return "Expires on " + endDate;
      }
    } else {
      const endDate = datePipe.transform(product.LastExpireOn, 'dd MMM yyyy');
      return "Expired on " + endDate;
    }
  }

  getDaysRemaining(product) {
    let diff = this.dateDiff(new Date(), product.EndDate, 'days')
    if (diff === 1) {
      diff = 2;
    } else if (diff == 0) {
      diff = 1;
    }
    if (diff < 0) {
      diff = -1;
    }
    return diff;
  }

  dateDiff(date1, date2, interval) {
    //console.log(date1, date2, interval)
    var second = 1000, minute = second * 60, hour = minute * 60, day = hour * 24, week = day * 7
    date1 = new Date(date1);
    date2 = new Date(date2);
    var timeDiff = date2 - date1;
    //console.log(timeDiff, 'timeDiff', timeDiff / day)
    if (isNaN(timeDiff)) return NaN;
    switch (interval) {
      case "years": return date2.getFullYear() - date1.getFullYear();
      case "months": return (
        (date2.getFullYear() * 12 + date2.getMonth())
        -
        (date1.getFullYear() * 12 + date1.getMonth())
      );
      case "weeks": return Math.floor(timeDiff / week);
      case "days": return Math.floor(timeDiff / day);
      case "hours": return Math.floor(timeDiff / hour);
      case "minutes": return Math.floor(timeDiff / minute);
      case "seconds": return Math.floor(timeDiff / second);
      default: return undefined;
    }
  }


  checkTerm(product) {
    if (product.Term_Conditions_Signed == false) {
      const dialogRef = this.dialog.open(TermSignComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'term-modal',
        data: {
          product: product
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          //create a copy of summer fruits.
          const pCopy = [...this.productListingByCompany];

          //find index of item to be replaced
          let tIndex = this.productListingByCompany.findIndex(f => f.termId == product['termId']);
          if (tIndex != -1) {
            //replace the object with a new one.
            pCopy[tIndex]['Term_Conditions_Signed'] = true;
            this.productListingByCompany = pCopy;
          }
          window.location.href = this.getProductUrl(product.productUrl)
        }
      })
    } else {
      window.location.href = this.getProductUrl(product.productUrl)
    }
  }

  confirmDeactivation(subData) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to deactivate this subscription?'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.deactivateSubscription(subData);
      }
    });
  }


  deactivateSubscription(subData) {
    let ttData = {
      'Company_Id': this.companyId,
      'Product_Id': subData['Product_ID'],
      'CompanySubscription_Id': subData['CompanySubscription_Id']
    }
    const popupLoader = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Deactivating...'
      }
    });
    //let subId = subData['CompanySubscription_Id']
    this.subscriptionService
      .deactivateSubscription(ttData)
      .subscribe(
        result => {
          popupLoader.close()
          try {
            this.loadProductsList();
            //trigger services to update header nav dropdown
            this.dataStoreService.setUserProductAccess(true);
          } catch (e) { }
        },
        error => {
          popupLoader.close()
          let response = error;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          popupLoader.close()
        }
      );
  }

}


// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload_logo {
  min-width: 80px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/companies/portal/add-company-v2/add-company-v2.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;AACJ","sourcesContent":[".upload_logo{\n    min-width:80px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

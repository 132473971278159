/*
* @ngdoc Config
* @name app.configs.ApiUrls
* @description
* The service is used to define all the Api urls
* */

export class ApiUrls {
  user = {
    userListing: 'portal/getCompanyUserListing',
    permissionListing: 'getUserPermissionList',
    addUserToCompany: 'addUserToCompany',
    validateInviteToken: 'portal/validateInviteToken',
    setPassword: 'portal/setPassword',
    login: 'portal/login',
    oktaLogin: 'portal/okta-login',
    oktaLoginUrl: 'portal/okta-login-url',
    forgotPassword: 'portal/forgot-password',
    updatePassword: 'portal/updatePassword',
    updateProfile: 'portal/updateProfile',
    editUserDetails: 'editUserDetails',
    deleteUser: 'deleteUser',
    resendInviteUser: 'portal/resend-invite',
    resetPassword: 'portal/reset-password',
    azureAccessToken: 'azure/accessToken',
    logout: 'portal/logout',
    companyListing: 'companyListing',
    companyDropDown: 'portal/usage/company',
    subUser: 'company/getSubCustomer',
    updateSub: 'company/updateSubCustomers',
    backupTable: 'portal/admin/backup-table',
    sendOtpForceUpdate: 'user/send-otp-force-update',
    sendOtpLogin: 'user/send-otp-login',
    sendOtpSignup: 'user/send-otp-signup',
    sendOtpUserInvite: 'user/send-otp-user-invite',
    sendOtpUpdateMobile: 'user/send-otp-update-mobile',
    verifyOtp: 'user/verify-otp',
    verifyOtpLogin: 'user/verify-otp-login',
    verifyOtpSignup: 'user/verify-otp-signup',
    verifyOtpUserInvite: 'user/verify-otp-user-invite',
    verifyOtpUpdateMobile: 'user/verify-otp-update-mobile',
    verifyOtpUpdateForceUpdate: 'user/verify-otp-force-update',
    resendOtpLogin: 'user/resend-otp-login',
    resendOtpSignup: 'user/resend-otp-signup',
    resendOtpUpdateMobile: 'user/resend-otp-update-mobile',
  }
  permissions = {
    list: 'permissions',
  }
  products = {
    productListing: 'portal/getProductList',
    getProductListByCompany: 'portal/getProductListByCompany',
    contactUs: 'portal/contactUs',
    usage: 'portal/usage',
    usageCount: 'portal/usage-count',
    usageIntervals: 'portal/getUsageInterval',
    trial: 'portal/product/trial',
    list: 'products',
    attributes: 'attributes',
    updateProduct: 'update',
    usageMakeModel: 'portal/usageMakeModel',
    usageDetails: 'portal/usageDetails',
    assetInfoUsageDetails: 'portal/assetInfoUsageDetails',
    salvageValUsageMakeModel: 'portal/usageSalvageValMakeModel',
    salvageValUsageDetails: 'portal/salvageValUsageDetails',
  }
  quote = {
    listing: 'portal/quote/listing',
    add: 'portal/quote/add',
    payment: 'portal/quote/payment',
    quoteDetail: 'portal/quote/quote-details',
    quoteSend: 'portal/quote/send-quote',
    toggleQuote: 'portal/quote/accept-quote',
    filters: 'portal/filters',
  }
  payment = {
    listing: 'portal/payment/listing',
    download: 'portal/payment/download',
  }
  p_offer = {
    list: 'product-offers',
    company: 'company-offers',
    update: 'update',
    details: 'details',
  }
  company = {
    company: 'company',
    list: 'companyListing',
    addCompany: "addCompany",
    updateCompany: "updateCompany",
    addSubscription: "add-subscription",
    updateSubscription: "update-subscription",
    getCompany: "getCompanyDetails",
    companyStatus: "companyStatus",
    subscription: "subscription",
    removeSubscription: "remove-subscription",
    createConsumer: "create-consumer",
    subscriptionList: 'subscription-list',
    deactivateSub: 'de-subscription',
    details: 'details',
    signup: 'portal/company-signup/',
    acceptTerms: '/portal/sign-terms',
    resendInvite: 'portal/resend-company-invite',
    abn: 'portal/check-abn',
    bEmail: 'portal/check-email',
    updateSubscriptionMakes: 'company/update-subscription-makes'
  }
  admin = {
    userListing: 'users',
    addUser: "users/add",
    UpdateUser: "users/update",
    deleteUser: 'users/delete',
    couponList: 'portal/admin/coupon-list',
    addCoupon: 'portal/admin/add-coupon',
    toggleCoupon: 'portal/admin/toggle-coupon',
    productList: 'portal/admin/product-list',
    productDetail: 'portal/product-detail',
    adminProductDetail: 'portal/admin/product-detail',
    updateProductDetail: 'portal/admin/save-product-detail',
    productConfigDetail: 'portal/product-config-list',
    adminProductConfigDetail: 'portal/admin/product-config-list',
    productConfigStatus: 'portal/admin/product-config-status',
    addConfig: 'portal/admin/add-product-config',
    addCart: 'portal/add-product-cart',
    cartCount: 'portal/cart-count',
    cartDetails: 'portal/cart-details',
    removeCartItem: 'portal/remove-cart-item',
    addQuote: 'portal/add-quote',
    applyCoupon: 'portal/apply-coupon',
  }
}

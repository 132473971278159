import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaLoginComponent implements OnInit {
  isAuthenticated: boolean;
  loginUrl: string = ''
  constructor(
    private renderer: Renderer2,
    public appSettings: AppSettings,
    private userService: UserService,
    private commonService: CommonService
  ) {
    this.renderer.addClass(document.body, 'login_bg');
  }

  ngOnInit() {
    this.getServerUrl()
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login_bg');
  }

  getServerUrl() {
    this.isAuthenticated = true
    this.userService.oktaLoginUrl().subscribe(res => {
      this.isAuthenticated = false;
      try {
        const response = res;
        this.loginUrl = `${response.data['url']}&redirect_uri=${environment.frontEndUrl}authorization-code/callback`;
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.isAuthenticated = false;
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      window.location.href = '/error'
    }, () => {

    })
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  text-transform: capitalize;
}

.backup_tables .radio-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.backup_tables .radio-list label {
  margin-bottom: 5px;
}
.backup_tables .radio-list .mat-radio-button {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/backup-table/backup-table.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AACI;EACE,kBAAA;AACN;AACI;EACE,kBAAA;AACN","sourcesContent":["h2{\n  text-transform: capitalize;\n}\n\n.backup_tables{\n  .radio-list{\n    display: flex;\n    flex-direction: column;\n    margin-bottom:15px;\n    label{\n      margin-bottom:5px;\n    }\n    .mat-radio-button{\n      margin-right:15px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-add-product-config',
  templateUrl: './add-product-config.component.html',
  styleUrls: ['./add-product-config.component.scss']
})
export class AddProductConfigComponent implements OnInit {

  @Input('Product_Id') Product_Id: number;
  @Input('PermissionsIds') PermissionsIds: Array<any> = []
  @Output() configAdded = new EventEmitter();

  formError = {};
  loader: boolean = false;
  configForm: UntypedFormGroup;

  constructor(
    public appSettings: AppSettings,
    private commonService: CommonService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.configForm = new UntypedFormGroup({
      'IsActive': new UntypedFormControl(true, [Validators.required]),
      'Name': new UntypedFormControl(null, [Validators.required]),
      //'PermissionsIds': new FormArray(this.getPermissionsControls(), [this.minSelectedCheckbox()]),
      //'TotalRequest': new FormControl(null),
      'Price': new UntypedFormControl(null, [Validators.required])
    });
    if (this.Product_Id == this.appSettings.AutoPredict) {
      this.configForm.addControl('PermissionsIds', new UntypedFormArray(this.getPermissionsControls(), [this.minSelectedCheckbox()]))
    } else if (this.Product_Id == this.appSettings.InstantVal) {
      this.configForm.addControl('TotalRequest', new UntypedFormControl(null, [Validators.required]));
    }
    // console.log(this.configForm.get('PermissionsIds'), 'PermissionsIds', this.getPermissionsControls())
  }

  getPermissionsControls() {
    return this.PermissionsIds.map(control => {
      return new UntypedFormControl(false)
    })
  }

  getControl(path) {
    return this.configForm.get(path) as UntypedFormControl
  }

  checkControlError(path, pForm, errorType: string = 'required') {
    return this.getControlError(path, errorType) && (this.checkControlDirty(path))
  }

  getControlError(path, type: string = 'required') {
    return this.configForm.get(path).hasError(type)
  }

  checkControlDirty(path) {
    return this.configForm.get(path).invalid && (this.configForm.get(path).pristine || this.configForm.get(path).touched)
  }

  minSelectedCheckbox(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkbox
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }


  addProduct(form) {
    if (form.valid) {

      if (this.Product_Id == this.appSettings.AutoPredict) {
        let selectedPermissions: Array<Number> = this.getControl('PermissionsIds').value
        selectedPermissions = selectedPermissions.map((checked, index) => checked ? this.PermissionsIds[index].Permission_Id : null)
          .filter(value => value !== null);
        if (selectedPermissions.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one permission.")
          return false
        } else {
          console.log(selectedPermissions, 'selectedPermissions');
          form.value['PermissionsIds'] = selectedPermissions
        }
      }
      if (this.Product_Id == this.appSettings.InstantVal) {
        if (form.value['TotalRequest'] <= 0) {
          this.commonService.showSnakeBar('Please enter valid total request')
          return false;
        }
      }
      this.loader = true;
      this.formError = {};
      form.value['Product_Id'] = this.Product_Id
      this.productService.addConfig(form.value).subscribe(res => {
        try {
          const response = res;
          if (response.message) {
            this.commonService.showSnakeBar(response.message)
          }
          this.configAdded.emit(true)
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.loader = false;
      }, (err) => {
        let response = err;
        this.loader = false;
        if (response.message) {
          if ('errors' in response) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message)
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.loader = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  cancelAddConfig() {
    this.configAdded.emit(false)
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.configForm.contains(key)) {
          let control = this.configForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    console.log(formGroup, 'formGroup')
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { JwtService } from 'src/app/services/jwt.service';
import { AzureService } from 'src/app/services/azure.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  public changeProfile: UntypedFormGroup;
  public isProcessing: boolean = false;
  public isUploading: boolean = false;
  public isChange: boolean = false;
  public formError: any = {};
  private allowedfiles = ["png", "jpg", "jpeg", "bmp"];
  public profileModel: string = '';
  constructor(
    public commonService: CommonService,
    private userService: UserService,
    public jwtService: JwtService,
    private azureService: AzureService,
    public roleCheckService: RoleCheckerService,
    private appSettings: AppSettings
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.changeProfile = new UntypedFormGroup({
      'name': new UntypedFormControl(this.jwtService.getUserName(), [
        Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
      ]),
      'email': new UntypedFormControl(this.jwtService.getUserEmail(), [
        Validators.required, Validators.email, Validators.maxLength(255)
      ]),
      'jobTitle': new UntypedFormControl(this.jwtService.getUserDetails().jobTitle, [
        Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
      ]),
      'profileImage': new UntypedFormControl(null),
      'thumbnailLogo': new UntypedFormControl(null),
    });
    this.setImageUrl(this.jwtService.getProfileImage(), this.jwtService.getProfileThumbImage());
    this.changeProfile.valueChanges.subscribe(data => {
      if (data) {
        this.isChange = true;
      }
      //console.log(data, 'print the data here');
    })
  }

  submit(form) {
    if (form.valid) {
      this.isProcessing = true;
      const fmCopy = Object.assign({}, form.value);
      fmCopy['userPic'] = fmCopy['profileImage'];
      delete fmCopy['profileImage'];
      this.userService.updateProfile(fmCopy).subscribe(res => {
        try {
          const response = res;
          this.jwtService.updateUserDetails(response.data[0]);
          if (response.message) {
            this.commonService.showSnakeBar(response.message)
          }
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.initForm();
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.initForm();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.initForm();
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors
* @return none
*/
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.changeProfile.contains(key)) {
          let control = this.changeProfile.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowedfiles.indexOf(ext.toLowerCase()) != -1) {
      return true;
    }
    else {
      this.commonService.showSnakeBar("Please try with valid file extensions (.jpg, .jpeg, .bmp, .png)");
      return false;
    }
  }

  resetImageInput(event) {
    if (event.target.files[0] != undefined) {
      event.target.value = "";
    }
  }

  validateFileSize(fileSize: number) {
    if (((fileSize / 1024) / 1024) > 5) {
      return false;
    }
    else {
      return true;
    }
  }

  private createFile(fileBlob: Blob, fileName: string): File {
    const file: File = <File>Object.assign(fileBlob, { name: fileName });
    return file;
  }

  // profileImageLoad() {
  //   if (this.isUploading) {
  //     this.isUploading = false;
  //     this.isProcessing = false;
  //   }
  // }

  setImageUrl(mainUrl, thumbUrl = null, directPush = false) {
    if (mainUrl && mainUrl != '') {
      this.changeProfile.controls.profileImage.setValue(mainUrl.toString());
    }
    if (thumbUrl && thumbUrl != '') {
      this.changeProfile.controls.thumbnailLogo.setValue(thumbUrl.toString());
    }
    this.profileModel = thumbUrl;
    if (directPush) {
      this.submit(this.changeProfile);
    }
  }

  fileChange(event) {
    this.profileModel = '';
    if (event.target.files[0] != undefined && this.validateFile(event.target.files[0].name)) {
      let file: File = event.target.files[0];
      let fileName: string = (new Date()).getTime() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
      if (!this.validateFileSize(file.size)) {
        this.commonService.showSnakeBar("File size should be less than or equal to 5 MB");
      }
      else {
        this.startImageLoading();
        this.commonService.getImageEssentials(file).subscribe(mainImage => {
          this.commonService.compressImage(mainImage.image, file.type, [400, 100], mainImage.width, mainImage.height).subscribe(compRes => {
            if (compRes.status) {
              const reqModel = {
                fileName: fileName,
                thumbName: 'thumb' + fileName
              }
              this.azureService.getSASToken(reqModel).subscribe(res => {
                try {
                  const result = res;
                  if (result.status) {
                    for (let i = 0; i < result.data.length; i++) {
                      let newFile = <File>this.createFile(compRes.data.blobs[i], file.name);
                      this.azureService.putObject(result.data[i].uri, newFile).subscribe((res: any) => {
                        if (res.ok && i == 1) {
                          this.setImageUrl(reqModel.fileName, reqModel.thumbName, true);
                          this.isUploading = false;
                        }
                      })
                    }
                  }
                }
                catch (ex) {
                  this.commonService.showSnakeBar("Something went wrong");
                  this.stopImageLoading();
                }
              }, (err) => {
                let response = err;
                if (response.message) {
                  this.commonService.showSnakeBar(response.message);
                }
                this.stopImageLoading();
              })
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
              this.stopImageLoading();
            }
          })
        })
      }
    }
  }

  startImageLoading() {
    this.isUploading = true;
    this.isProcessing = true;
  }

  stopImageLoading() {
    this.isUploading = false;
    this.isProcessing = false;
  }
}

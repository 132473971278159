import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { ProductService } from "src/app/services/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "src/app/configs/app-settings";
import { DataStoreService } from "src/app/services/data-store.service";
import { RoleCheckerService } from "src/app/services/role-checker.service";
import { SubscriptionService } from "src/app/services/subscription.service";
interface IvForm {
  Quantity: number;
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
}

interface PriceMyTruckForm {
  toggle: boolean;
  User: number;
  Make: Array<string>;
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
}
interface AutpForm {
  toggle: boolean;
  User: number;
  Make: Array<string>;
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
}
@Component({
  selector: "app-autopredict-details",
  templateUrl: "./autopredict-details.component.html",
  styleUrls: ["./autopredict-details.component.scss"],
})
export class AutoPredictDetailsComponent implements OnInit {
  productId = null;
  configLoader: boolean = false;
  productLoader = false;
  processing: boolean = false;
  productDetail: any = {};
  productFields = [];
  permissions: Array<any> = [];
  productConfigs: Array<any> = [];
  Makes: Array<string> = [];

  instantValForm: Array<IvForm> = [];
  autpForm: Array<AutpForm> = [];
  priceMyTruckForm: Array<PriceMyTruckForm> = [];
  DraftOrderId: number = null;
  cartLoader: number = null;
  priceFormat = "1.2-2";
  cartDetails = undefined;
  deleteCart = null;
  companyId = null;
  cartUrl = '/my-cart';
  queryParams = null;
  constructor(
    private commonService: CommonService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public appSettings: AppSettings,
    public dataStore: DataStoreService,
    public roleService: RoleCheckerService,
    public subscriptionService: SubscriptionService
  ) {
    this.productId = this.activatedRoute.snapshot.paramMap.get("id");
    if (!this.productId) {
      this.sendToProduct();
    }
    this.getProductDetails();
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyId = params['companyId'];
      if (this.roleService.isSuperAdmin() && !this.companyId) {
        this.sendToProduct();
      }
      if (this.roleService.isSuperAdmin() && this.companyId) {
        this.cartUrl = '/cart'
        this.getCompanyDetails();
        //this.queryParams = { companyId: this.companyId };
      }
    });
  }

  ngOnInit() {
    this.commonService.setTitle("Products");
  }


  sendToCompany(showMsg: boolean = true) {
    showMsg ? this.commonService.showSnakeBar(
      "Unable to find the requested company."
    ) : false;
    this.router.navigate(["/companies"]);
  }
  companyLoader = false;
  companyDetails = null;
  getCompanyDetails() {
    this.companyLoader = true;
    this.subscriptionService
      .getCompanyDetails({ companyId: this.companyId })
      .subscribe(
        result => {
          this.companyLoader = false;
          try {
            let response = result;
            if (!response.data) {
              this.sendToCompany()
            }
            this.companyDetails = response.data

          } catch (e) { }
        },
        error => {
          //console.log(error)
          this.companyLoader = false;
          let response = error;

          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => { }
      );
  }



  sendToProduct() {
    this.commonService.showSnakeBar("Unable to find the requested product.");
    this.router.navigate(["/dashboard"]);
  }

  getProductDetails() {
    this.configLoader = true;
    this.productLoader = true;
    this.productService
      .getAdminProductDetail(
        {
          id: this.productId,
        },
        false
      )
      .subscribe(
        (res) => {
          this.productLoader = false;
          //try {
          const response = res;
          this.productDetail = response.data["product"];
          this.productFields = response.data["productFields"];
          this.permissions = response.data["permissions"];
          this.getProductConfigurations();
          // } catch (e) {
          //   this.commonService.commonSnakeBar();
          // }
        },
        (err) => {
          this.productLoader = false;
          this.configLoader = false;
          let response = err;
          if (response.status == 404) {
            this.sendToProduct();
          } else {
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          }
        }
      );
  }

  setupConfigForm(data: Array<any> = []) {
    if (data.length > 0) {
      if (this.productId == this.appSettings.InstantVal) {
        this.instantValForm = []
        data.forEach((el) => {
          this.instantValForm.push({
            Quantity: 0,
            Price: el["Price"],
            Product_Config_Id: el["Product_Config_Id"],
            DraftOrderItemId: null,
          });
        });
      }
      if (this.productId == this.appSettings.AutoPredict) {
        this.autpForm = [];
        data.forEach((el) => {
          let temp = {
            toggle: false,
            User: 1,
            Make: [],
            Price: el["Price"],
            Product_Config_Id: el["Product_Config_Id"],
            DraftOrderItemId: null,
          };
          this.autpForm.push(temp);
        });
      }
      if (this.productId == this.appSettings.PriceMyTruck) {
        this.priceMyTruckForm = [];
        data.forEach((el) => {
          let temp = {
            toggle: false,
            User: 1,
            Make: [],
            Price: el["Price"],
            Product_Config_Id: el["Product_Config_Id"],
            DraftOrderItemId: null,
          };
          this.priceMyTruckForm.push(temp);
        });
      }
    }
  }

  updateProductConfig() {
    if (this.productId == this.appSettings.AutoPredict) {
      let OrderItems = undefined;
      if (this.cartDetails && 'cartDetail' in this.cartDetails) {
        let cartDetail = this.cartDetails['cartDetail']
        if ("autpDetail" in cartDetail && "OrderItems" in cartDetail["autpDetail"]) {
          OrderItems = cartDetail["autpDetail"]["OrderItems"];
        }
      }
      if (OrderItems) {
        let uIndex = this.autpForm.findIndex(el => OrderItems["ProductConfig"]["Product_Config_Id"] == el["Product_Config_Id"]);
        if (uIndex != -1) {
          this.autpForm[uIndex].toggle = true;
          this.autpForm[uIndex].User = parseInt(OrderItems["TotalUsers"]);
          this.autpForm[uIndex].Make = OrderItems["Make"];
          this.autpForm[uIndex].DraftOrderItemId = OrderItems["DraftOrderItemId"];
        }
      }
    }
    if (this.productId == this.appSettings.PriceMyTruck) {
      let OrderItems = undefined;
      if (this.cartDetails && 'cartDetail' in this.cartDetails) {
        let cartDetail = this.cartDetails['cartDetail']
        if ("priceMyTruckDetail" in cartDetail && "OrderItems" in cartDetail["priceMyTruckDetail"]) {
          OrderItems = cartDetail["priceMyTruckDetail"]["OrderItems"];
        }
      }
      if (OrderItems) {
        let uIndex = this.priceMyTruckForm.findIndex(el => OrderItems["ProductConfig"]["Product_Config_Id"] == el["Product_Config_Id"]);
        if (uIndex != -1) {
          this.priceMyTruckForm[uIndex].toggle = true;
          this.priceMyTruckForm[uIndex].DraftOrderItemId = OrderItems["DraftOrderItemId"];
          this.priceMyTruckForm[uIndex]['User'] = OrderItems["TotalUsers"];
          this.priceMyTruckForm[uIndex].Make = OrderItems["Make"];
        }
      }
    }
    if (this.productId == this.appSettings.InstantVal) {
      let OrderItems = undefined;
      if (this.cartDetails && 'cartDetail' in this.cartDetails) {
        let cartDetail = this.cartDetails['cartDetail']
        if ("ivDetail" in cartDetail && "OrderItems" in cartDetail["ivDetail"]) {
          OrderItems = cartDetail["ivDetail"]["OrderItems"];
        }
      }
      if (OrderItems) {
        OrderItems.map(el => {
          let uIndex = this.instantValForm.findIndex(e => e["Product_Config_Id"] == el["ProductConfig"]["Product_Config_Id"]);
          if (uIndex != -1) {
            this.instantValForm[uIndex].Quantity = parseInt(el["Quantity"]);
            this.instantValForm[uIndex].DraftOrderItemId = el["DraftOrderItemId"];
          }
        });
        //console.log(this.instantValForm, 'form');
      }
    }
  }

  checkAutpTruckProduct() {
    if (this.cartDetails && 'cartDetail' in this.cartDetails) {
      let cartDetail = this.cartDetails['cartDetail'];
      if (this.productId == this.appSettings.AutoPredict) {
        if ("autpDetail" in cartDetail && "OrderItems" in cartDetail["autpDetail"]) {
          return true
        }
      } else if (this.productId == this.appSettings.PriceMyTruck) {
        if ("priceMyTruckDetail" in cartDetail && "OrderItems" in cartDetail["priceMyTruckDetail"]) {
          return true
        }
      }
    }
    return false;
  }

  getProductConfigurations() {
    this.configLoader = true;
    this.productService
      .getAdminProductConfigs(
        {
          id: this.productId,
        },
        false
      )
      .subscribe(
        (res) => {
          this.configLoader = false;
          //try {
          this.getCartDetails();
          const response = res;
          this.productConfigs = response.data["configs"];
          this.setupConfigForm(this.productConfigs);
          if ("makes" in response.data) {
            this.Makes = response.data["makes"];
          }
          // } catch (e) {
          //   this.commonService.commonSnakeBar();
          // }
        },
        (err) => {
          this.configLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

  getCartDetails() {
    this.configLoader = true;
    this.productService
      .getCartDetails({
        companyId: this.companyId
      })
      .subscribe(
        (res) => {
          this.configLoader = false;
          // try {
          const response = res;
          if (
            response.data &&
            Object.keys(response.data).length > 0
          ) {
            if (this.roleService.isSuperAdmin()) {
              if (this.companyId != response.data['Company_Id']) {
                this.commonService.showSnakeBar('You cannot add item in cart for multiple companies.');
                this.sendToCompany(false);
              }
            }
            this.DraftOrderId = response.data['DraftOrderId'];
            this.cartDetails = response.data;
            this.updateProductConfig();
          } else {
            this.cartDetails = undefined
            this.DraftOrderId = null;
          }
          // } catch (e) {
          //   this.commonService.commonSnakeBar();
          // }
        },
        (err) => {
          this.configLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }



  toggleAutpProduct(actionType: string, toggle: boolean, key: number) {
    if (this.productId == this.appSettings.AutoPredict) {
      this.autpForm = this.autpForm.map((element: AutpForm, index) => {
        if (actionType == "cancel") {
          if (key == index) {
            element.toggle = toggle;
            element.Make = [];
            element.User = 1;
          }
        } else if (actionType == "select") {
          if (key == index) {
            element.toggle = toggle;
          } else {
            element.toggle = !toggle;
            element.Make = [];
            element.User = 1;
          }
        }
        return element;
      });
    } else if (this.productId == this.appSettings.PriceMyTruck) {
      this.priceMyTruckForm = this.priceMyTruckForm.map((element: PriceMyTruckForm, index) => {
        if (actionType == "cancel") {
          if (key == index) {
            element.toggle = toggle;
            element.Make = [];
            element.User = 1;
          }
        } else if (actionType == "select") {
          if (key == index) {
            element.toggle = toggle;
          } else {
            element.toggle = !toggle;
            element.Make = [];
          }
          element.User = 1;
        }
        return element;
      });
    }
  }

  toggleIvQuantity(index, action: 'add' | 'rmv') {
    if (action == 'add') {
      this.instantValForm[index].Quantity = this.instantValForm[index].Quantity + 1;
    } else if (action == 'rmv') {
      const futureVal = this.instantValForm[index].Quantity - 1;
      if (futureVal == 0 && this.instantValForm[index].DraftOrderItemId) {
        this.deleteCartItem(index, this.instantValForm[index].DraftOrderItemId);
        return false;
      }
      this.instantValForm[index].Quantity = futureVal;
    }
  }

  addToCart(index: number, itemModel: AutpForm | IvForm, goToCart: boolean = false) {
    let formData = {};
    if (this.productId == this.appSettings.AutoPredict) {
      const MaxMake = this.productDetail["MaxMake"];
      const MaxUser = this.productDetail["MaxUser"];
      const pUser = parseInt(itemModel["User"]);
      if (isNaN(pUser) || pUser == 0) {
        this.commonService.showSnakeBar("Please choose at least one user.");
        return false;
      }
      if (itemModel["User"] > MaxUser) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxUser} users.`
        );
        return false;
      }
      if (itemModel["Make"].length == 0) {
        this.commonService.showSnakeBar(`Please choose at least one make.`);
        return false;
      }
      if (itemModel["Make"].length > MaxMake) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxMake} make.`
        );
        return false;
      }
      formData = {
        User: itemModel["User"],
        Make: itemModel["Make"],
        id: itemModel["Product_Config_Id"],
        DraftOrderId: this.DraftOrderId,
        Product_Id: this.productId,
        DraftOrderItemId: itemModel.DraftOrderItemId,
        companyId: this.companyId
      };
    } else if (this.productId == this.appSettings.InstantVal) {
      formData = {
        Quantity: itemModel["Quantity"],
        id: itemModel["Product_Config_Id"],
        DraftOrderId: this.DraftOrderId,
        Product_Id: this.productId,
        DraftOrderItemId: itemModel.DraftOrderItemId,
        companyId: this.companyId
      };
    } else if (this.productId == this.appSettings.PriceMyTruck) {
      const MaxMake = this.productDetail["MaxMake"];
      const MaxUser = this.productDetail["MaxUser"];
      const pUser = parseInt(itemModel["User"]);
      if (isNaN(pUser) || pUser == 0) {
        this.commonService.showSnakeBar("Please choose at least one user.");
        return false;
      }
      if (itemModel["User"] > MaxUser) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxUser} users.`
        );
        return false;
      }
      if (itemModel["Make"].length == 0) {
        this.commonService.showSnakeBar(`Please choose at least one make.`);
        return false;
      }
      if (itemModel["Make"].length > MaxMake) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxMake} make.`
        );
        return false;
      }
      formData = {
        id: itemModel["Product_Config_Id"],
        DraftOrderId: this.DraftOrderId,
        Product_Id: this.productId,
        DraftOrderItemId: itemModel.DraftOrderItemId,
        companyId: this.companyId,
        User: itemModel["User"],
        Make: itemModel["Make"]
      };
    }
    if (Object.keys(formData).length > 0) {
      this.cartLoader = index;
      this.productService.addUpdateCart(formData).subscribe(
        (res) => {
          try {
            const response = res;
            let data = response["data"];
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            }
            if (
              response.data &&
              Object.keys(response.data).length > 0
            ) {
              this.DraftOrderId = response.data['DraftOrderId'];
              this.cartDetails = response.data;
              this.updateProductConfig();
              this.dataStore.setCart(data["CartCount"]);
              if (goToCart === true) {
                this.router.navigate([this.cartUrl], { queryParams: this.queryParams });
              }
            } else {
              this.cartDetails = undefined
              this.DraftOrderId = null;
              this.dataStore.setCart(0);
            }

          } catch (e) {
            this.commonService.commonSnakeBar();
          }
          this.cartLoader = null;
        },
        (err) => {
          this.cartLoader = null;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          this.cartLoader = null;
        }
      );
    } else {
      this.commonService.showSnakeBar(
        "Invalid details. Please try again later."
      );
    }
  }

  deleteCartItem(index, DraftOrderItemId) {
    let confirmRes = confirm('Are you sure you want to remove?');
    if (confirmRes === true) {
      if (!this.DraftOrderId) {
        this.commonService.showSnakeBar("Invalid details. Please try to refresh the page.")
        return false;
      }
      if (!DraftOrderItemId) {
        this.commonService.showSnakeBar("Invalid item. Please try again later.")
        return false;
      }
      this.deleteCart = index;
      this.productService.removeCartItem({
        DraftOrderId: this.DraftOrderId,
        DraftOrderItemId: DraftOrderItemId
      }).subscribe(
        (res) => {
          this.deleteCart = null;
          //try {
          const response = res;
          let data = response["data"];
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          }
          if (
            response.data &&
            Object.keys(response.data).length > 0
          ) {
            this.DraftOrderId = response.data['DraftOrderId'];
            this.cartDetails = response.data;
            this.updateProductConfig();
            this.dataStore.setCart(data["CartCount"]);
            if (this.productId == this.appSettings.InstantVal) {
              this.instantValForm[index]['Quantity'] = 0;
              this.instantValForm[index]['DraftOrderItemId'] = null;
            } else if (this.productId == this.appSettings.AutoPredict) {
              this.autpForm[index].toggle = false;
              this.autpForm[index].User = 1;
              this.autpForm[index].Make = [];
              this.autpForm[index].DraftOrderItemId = null;
            } else if (this.productId == this.appSettings.PriceMyTruck) {
              this.priceMyTruckForm[index].toggle = false;
              this.priceMyTruckForm[index].DraftOrderItemId = null;
              this.priceMyTruckForm[index].Make = [];
            }
          } else {
            this.cartDetails = undefined
            this.DraftOrderId = null;
            this.dataStore.setCart(0);
            if (this.productId == this.appSettings.AutoPredict) {
              this.autpForm[index].toggle = false;
              this.autpForm[index].User = 1;
              this.autpForm[index].Make = [];
              this.autpForm[index].DraftOrderItemId = null;
            } else if (this.productId == this.appSettings.PriceMyTruck) {
              this.priceMyTruckForm[index].toggle = false;
              this.priceMyTruckForm[index].DraftOrderItemId = null;
              this.priceMyTruckForm[index].Make = [];
            } else {
              this.instantValForm[index]['Quantity'] = 0;
              this.instantValForm[index]['DraftOrderItemId'] = null;
            }
          }
          // } catch (e) {
          //   this.commonService.commonSnakeBar();
          // }
        },
        (err) => {
          this.deleteCart = null;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
        }
      );
    }
  }
}

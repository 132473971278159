// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fof {
  display: block;
  width: 100%;
  margin: 100px 0;
  line-height: 1.6em;
  text-align: center;
}

#fof .hgroup {
  text-transform: uppercase;
}

#fof .hgroup h1 {
  margin-bottom: 25px;
  font-size: 80px;
}

#fof .hgroup h1 span {
  margin-left: 5px;
  padding: 2px;
  border: 1px solid #CCCCCC;
  overflow: hidden;
}

#fof .hgroup h1 span strong {
  display: inline-block;
  padding: 0 20px 20px;
  font-weight: normal;
}

#fof .hgroup h2 {
  font-size: 33px;
}

#fof .hgroup h2 span {
  display: block;
  font-size: 30px;
}

#fof p {
  margin: 25px 0 0 0;
  padding: 0;
  font-size: 16px;
}

#fof p:first-child {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/core/page-not-found/page-not-found.component.scss"],"names":[],"mappings":"AAAA;EAAK,cAAA;EAAe,WAAA;EAAY,eAAA;EAAgB,kBAAA;EAAmB,kBAAA;AAMnE;;AALA;EAAa,yBAAA;AASb;;AARA;EAAgB,mBAAA;EAAoB,eAAA;AAapC;;AAZA;EAAsB,gBAAA;EAAiB,YAAA;EAAa,yBAAA;EAA0B,gBAAA;AAmB9E;;AAlBA;EAA4B,qBAAA;EAAsB,oBAAA;EAAsB,mBAAA;AAwBxE;;AAvBA;EAAgB,eAAA;AA2BhB;;AA1BA;EAAqB,cAAA;EAAe,eAAA;AA+BpC;;AA9BA;EAAO,kBAAA;EAAmB,UAAA;EAAW,eAAA;AAoCrC;;AAnCA;EAAmB,aAAA;AAuCnB","sourcesContent":["#fof{display:block; width:100%; margin:100px 0; line-height:1.6em; text-align:center;}\n#fof .hgroup{text-transform:uppercase;}\n#fof .hgroup h1{margin-bottom:25px; font-size:80px;}\n#fof .hgroup h1 span{ margin-left:5px; padding:2px; border:1px solid #CCCCCC; overflow:hidden;}\n#fof .hgroup h1 span strong{display:inline-block; padding:0 20px 20px;  font-weight:normal;}\n#fof .hgroup h2{font-size:33px;}\n#fof .hgroup h2 span{display:block; font-size:30px;}\n#fof p{margin:25px 0 0 0; padding:0; font-size:16px;}\n#fof p:first-child{margin-top:0;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

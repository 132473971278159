import { Injectable } from '@angular/core';
import { CommonHttpService } from './common-http.service';
import { ApiUrls } from '../configs/api-urls';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  getSubscriptionList(params) {
    return this.commonHttpService.get(`${this.apiUrls.company.company}/${this.apiUrls.company.subscriptionList}`, params);
  }

  deactivateSubscription(params) {
    return this.commonHttpService.post(`${this.apiUrls.company.company}/${this.apiUrls.company.deactivateSub}`, params);
  }

  /*
  * @name getCompanyDetails
  * @param
  * @description Get company details
  * @return Observable
  */
  getCompanyDetails(companyId) {
      return this.commonHttpService.get(`${this.apiUrls.company.company}/${this.apiUrls.company.details}`, { companyId: companyId })
  }
}

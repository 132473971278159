import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../services/company.service';
import { CommonService } from '../../services/common.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AzureService } from '../../services/azure.service';
import { PermissionsService } from '../../services/permissions.service';
import { DatePipe } from '@angular/common'
import { trigger, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.scss']
})
export class UpdateCompanyComponent implements OnInit {

  public isProcessing: boolean = false;
  public loadingPer: boolean = false
  makArray: Array<string> = []
  permArray: Array<any> = []

  public companyForm: UntypedFormGroup;
  public isEdit: boolean;
  public existingUsers: number;
  public onCompanyUpdate: EventEmitter<any> = new EventEmitter();
  private allowedfiles = ["png", "jpg", "jpeg", "bmp"];
  public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  public isUploading: boolean = false;
  public companyLogoModel: string = '';
  // public defaultMaxUsers: number = 100;
  // public defaultMaxRequests: number = 1000;
  public counter: number = 5;
  public counter_makes: number = 5;

  permissionModel = {
    p_ids: [],

  }

  formError = {};
  start_date = new Date()
  end_date = this.start_date.setDate(this.start_date.getDate() - 30);
  company = {}
  companySub = null
  subPIds = []

  openNewSub: boolean = false
  new_sub: UntypedFormGroup
  has_future_sub = 0
  c_date = new Date()
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdateCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public companyService: CompanyService,
    public commonService: CommonService,
    public permissionService: PermissionsService,
    public azureService: AzureService,
    public dp: DatePipe
  ) {
    this.isEdit = this.data.isEdit;
    this.company = this.data.company
  }

  ngOnInit() {
    this.getCompanyDetails()
    this.initForm();
    this.setDynamicValidators();
  }

  increment(counterVar, control) {
    //if (this[counterVar] != 1) {
    this[counterVar] += 1;
    // }
    this.companyForm.patchValue({ 'new_sub': { control: this[counterVar] } });
    this.new_sub.controls[control].setValue(this[counterVar]);
  }

  decrement(counterVar, control) {
    if (this[counterVar] != 1) {
      this[counterVar] -= 1;
    }
    this.companyForm.patchValue({ 'new_sub': { control: this[counterVar] } });
    this.new_sub.controls[control].setValue(this[counterVar]);
  }

  initForm() {
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    this.companyForm = new UntypedFormGroup({
      'photoProfile': new UntypedFormControl(this.company['CompanyLogo']),
      'thumbnailLogo': new UntypedFormControl(this.company['Thumbnail']),
      'companyName': new UntypedFormControl(this.company['CompanyName'], [
        Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[0-9A-Za-z_-][A-Za-z0-9 _.-]*$")
      ]),
      'email': new UntypedFormControl(this.company['Email'], [
        Validators.required, Validators.email, Validators.maxLength(255)
      ]),
      'phoneNo': new UntypedFormControl(this.company['PhoneNo'], [
        Validators.required, Validators.minLength(8), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]),
      'userName': new UntypedFormControl(this.company['UserName'], [
        Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
      ]),
      'noUsers': new UntypedFormControl({ value: 5, disabled: true }),
      'noMakes': new UntypedFormControl({ value: 5, disabled: true }),
      'makes': new UntypedFormControl({ value: [], disabled: true }),
      'start_date': new UntypedFormControl({ value: new Date(), disabled: true }),
      'end_date': new UntypedFormControl(null),
      'auto_accept': new UntypedFormControl({ value: false, disabled: true }),
      'add_sub': new UntypedFormControl(0),
    });
    this.new_sub = new UntypedFormGroup({
      'start_date': new UntypedFormControl(new Date()),
      'end_date': new UntypedFormControl(endDate),
      'noUsers': new UntypedFormControl(5),
      'noMakes': new UntypedFormControl(5),
      'makes': new UntypedFormControl([]),
      'auto_accept': new UntypedFormControl(false),
    })
    this.companyForm.addControl('new_sub', this.new_sub)
    this.setImageUrl(this.company['CompanyLogo'], this.company['Thumbnail']);
  }

  addNewSub() {
    this.openNewSub = !this.openNewSub
    let onVal = this.openNewSub == true ? 1 : 0
    this.companyForm.controls.add_sub.setValue(onVal)
    if (onVal == 1) {
      this.new_sub.controls.start_date.setValidators(Validators.required);
      this.new_sub.controls.end_date.setValidators(Validators.required);
      this.new_sub.controls.makes.setValidators([Validators.required]);
    } else {
      this.new_sub.controls.start_date.setValidators(null);
      this.new_sub.controls.end_date.setValidators(null);
      this.new_sub.controls.makes.setValidators(null);
    }
    this.new_sub.updateValueAndValidity();
    this.companyForm.updateValueAndValidity()
    //console.log(this.companyForm)
  }

  setDynamicValidators() {
    const noMakesControl = this.new_sub.get('noMakes');
    const makesControl = this.new_sub.get('makes');
    this.new_sub.get('noMakes').valueChanges
      .subscribe(noMakes => {
        //console.log(noMakes, 'noMakes')
        if (makesControl.value.length != noMakesControl.value) {
          this.formError['makes'] = `You can only choose ${noMakesControl.value} makes.`
        } else {
          this.formError['makes'] = ""
        }
      })

    makesControl.valueChanges
      .subscribe(makes => {
        //console.log(makes)
        if (makes.length != noMakesControl.value) {
          this.formError['makes'] = `You can only choose ${noMakesControl.value} makes.`
        } else {
          this.formError['makes'] = ""
        }
      })
  }

  validatePermissions() {
    let isError = false
    // If adding new subscription
    if (this.companyForm.controls.add_sub.value == 1) {
      const noMakesControl = this.new_sub.get('noMakes');
      const makesValue = this.new_sub.get('makes').value;
      console.log(noMakesControl.value, makesValue)
      if (makesValue.length == noMakesControl.value) {
        this.formError['makes'] = ""
      } else {
        this.handleServerFormError({ 'makes': `You can only choose ${noMakesControl.value} makes.` })
        isError = true
      }
    }
    //console.log(isError)
    return isError
  }

  public maxMakeValidation(control: UntypedFormControl) {
    if (control.value && parseInt(control.value) > 200) {
      return { maxValueCheck: true };
    }
    else {
      return null;
    }
  }

  public maxNumberValidation(control: UntypedFormControl) {
    if (control.value && parseInt(control.value) > 200) {
      return { maxValueCheck: true };
    }
    else {
      return null;
    }
  }

  togglePermissions(event, id) {
    if (event.checked) {
      this.permissionModel.p_ids.push(id);
      this.formError['permission'] = ""
    } else {
      const index = this.permissionModel.p_ids.indexOf(id)
      if (index != -1) {
        this.permissionModel.p_ids.splice(index, 1);
      }
    }
  }

  getCompanyDetails() {
    this.loadingPer = true;

    this.companyService.getCompanyDetails(this.company['CompanyId']).subscribe(res => {
      try {
        const response = res;
        let data = response.data
        this.has_future_sub = data['has_future_sub']
        if (data['subscription']) {
          this.companySub = data['subscription']
          let makes = this.companySub['makes']
          if (makes) {
            this.companyForm.controls.makes.setValue(makes.split(','))
          }
          let start_date = this.dp.transform(this.companySub['start_date'], 'd/M/yyyy')
          this.companyForm.controls.start_date.setValue(start_date)
          this.companyForm.controls.end_date.setValue(this.companySub['end_date'])
          this.companyForm.controls.noMakes.setValue(this.companySub['noMakes'])
          this.companyForm.controls.noUsers.setValue(this.companySub['noUsers'])
          this.companyForm.controls.auto_accept.setValue(this.companySub['auto_accept'])
          this.subPIds = this.companySub['p_ids'].map(function (x) {
            return parseInt(x, 10);
          });
          //this.counter = this.companySub['noUsers']
          //this.counter_makes = this.companySub['noMakes']
          // Only on validation for end date if current subscription exists
          this.companyForm.controls.end_date.setValidators([Validators.required]);

          // set new subscription after completing the current one
          let s_date = new Date(this.companySub['end_date'])
          s_date.setDate(s_date.getDate() + 1);
          var endDate = new Date(s_date);
          endDate.setDate(endDate.getDate() + 30);
          this.companyForm.patchValue({ 'new_sub': { start_date: s_date } });
          this.companyForm.patchValue({ 'new_sub': { end_date: endDate } });
        } else {
          // set new subscription for 30 days
          let s_date = new Date()
          var endDate = new Date(s_date);
          endDate.setDate(endDate.getDate() + 30);
          this.companyForm.patchValue({ 'new_sub': { start_date: s_date } });
          this.companyForm.patchValue({ 'new_sub': { end_date: endDate } });
        }
        if (data['makes']) {
          this.makArray = data['makes']
        }
        if (data['permissions']) {
          this.permArray = data['permissions']
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.loadingPer = false;
    }, (err) => {
      let response = err;
      this.loadingPer = false;
      if (response.message) {
        if ('errors' in response.errors) {
          this.handleServerFormError(response.errors)
        }
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.loadingPer = false;
    })
  }

  isChecked(p_id) {
    return this.subPIds.indexOf(p_id) != -1 ? true : false
  }

  saveCompany(formData) {
    this.isProcessing = true;

    this.companyService.updateCompany(formData).subscribe(res => {
      try {
        const response = res;
        this.onCompanyUpdate.emit(response.data[0]);
        this.closeModal();
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.message) {
        if ('errors' in response.errors) {
          this.handleServerFormError(response.errors)
        }
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  updateCompany(form) {
    //console.log(form.value)
    if (form.valid) {
      if (this.isValidRequest(form.value)) {
        let newSub = this.companyForm.controls.add_sub.value;
        if (this.permissionModel.p_ids.length <= 0 && this.companyForm.controls.add_sub.value == 1) {
          this.formError['permission'] = "Please choose permissions."
        } else {

          this.formError['permission'] = ""
          let newSubData = Object.assign({}, form.value['new_sub'])

          delete form.value['new_sub']
          let formValues = Object.assign({}, form.value)

          //console.log(formValues, 'formValues')
          if (newSub == 1) {
            let p_ids: any = this.permissionModel.p_ids
            p_ids = p_ids.join(',')
            newSubData['p_ids'] = p_ids
            //console.log(formValues, 'formValues')
            let makes: any = new Array(newSubData['makes']);
            //console.log(makes, 'makes')
            makes = makes.join(',')
            newSubData['makes'] = makes
            //console.log(formValues, 'formValues', newSubData, 'newSubData')
          }
          this.formError = {}
          formValues['companyId'] = this.company['CompanyId']
          let updateForm = {
            companyId: this.company['CompanyId'],
            companyName: formValues['companyName'],
            email: formValues['email'],
            phoneNo: formValues['phoneNo'],
            photoProfile: formValues['photoProfile'],
            userName: formValues['userName'],
            thumbnailLogo: formValues['thumbnailLogo'],
            add_sub: formValues['add_sub']
          }
          if (this.companySub) {
            //updateForm['end_date'] = formValues['end_date']
            let d = new Date(formValues['end_date'])
            d.setTime(d.getTime() - new Date().getTimezoneOffset() * 60 * 1000)
            updateForm['end_date'] = d.toISOString().slice(0, 10)
          }
          if (newSub == 1) {
            updateForm['new_sub'] = newSubData
            if (this.companySub) {
              if (new Date(newSubData['start_date']) < new Date(formValues['end_date'])) {
                this.commonService.showSnakeBar("New subscription start date must be after the current subscription end date.")
                return false;
              }
            }
            let start_date = new Date(newSubData['start_date'])
            start_date.setTime(start_date.getTime() - new Date().getTimezoneOffset() * 60 * 1000)
            updateForm['new_sub']['start_date'] = start_date.toISOString().slice(0, 10)

            let end_date = new Date(newSubData['end_date'])
            end_date.setTime(end_date.getTime() - new Date().getTimezoneOffset() * 60 * 1000)
            updateForm['new_sub']['end_date'] = end_date.toISOString().slice(0, 10)
          }
          console.log(updateForm, 'updateForm')
          this.saveCompany(updateForm)
        }
      }
    }
    else {
      this.formValidate(form);
    }
  }

  checkPermissionsExs(slug) {
    let ex = this.permArray.findIndex(el => el.slug == slug)
    if (ex != -1) {
      return true
    }
    return false
  }

  skipPer(slug) {
    if (slug == 'users' || slug == 'makes') {
      return false
    }
    return true
  }

  closeModal() {
    this.dialogRef.close();
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (key == 'p_ids') {
          this.formError['permission'] = errors[key]
        }
        if (key == 'companyId') {
          this.commonService.showSnakeBar(errors[key])
        }
        if (this.new_sub.contains(key)) {
          let control = this.new_sub.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        } else if (this.companyForm.contains(key)) {
          let control = this.new_sub.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  isValidRequest(obj) {
    if (this.validatePermissions()) {
      return false;
    } else {
      return true;
    }
  }

  setImageUrl(mainUrl, thumbUrl) {
    if (mainUrl && mainUrl != '') {
      this.companyForm.controls.photoProfile.setValue(mainUrl.toString());
    }
    if (thumbUrl && thumbUrl != '') {
      this.companyForm.controls.thumbnailLogo.setValue(thumbUrl.toString())
    }
    this.companyLogoModel = thumbUrl;
  }

  fileChange(event) {
    if (event.target.files[0] != undefined && this.validateFile(event.target.files[0].name)) {
      this.companyLogoModel = '';
      let file: File = event.target.files[0];
      let fileName: string = (new Date()).getTime() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
      if (!this.validateFileSize(file.size)) {
        this.commonService.showSnakeBar("File size should be less than or equal to 5 MB");
      }
      else {
        this.startImageLoading();
        this.commonService.getImageEssentials(file).subscribe(mainImage => {
          this.commonService.compressImage(mainImage.image, file.type, [400, 100], mainImage.width, mainImage.height).subscribe(compRes => {
            if (compRes.status) {
              const reqModel = {
                fileName: fileName,
                thumbName: 'thumb' + fileName
              }
              this.azureService.getSASToken(reqModel).subscribe(res => {
                try {
                  const result = res;
                  if (result.status) {
                    for (let i = 0; i < result.data.length; i++) {
                      let newFile = <File>this.createFile(compRes.data.blobs[i], file.name);
                      this.azureService.putObject(result.data[i].uri, newFile).subscribe((res: any) => {
                        if (res.ok && i == 1) {
                          this.setImageUrl(reqModel.fileName, reqModel.thumbName);
                          this.stopImageLoading();
                        }
                      })
                    }
                  }
                }
                catch (ex) {
                  this.commonService.showSnakeBar("Something went wrong");
                  this.stopImageLoading();
                }
              }, (err) => {
                let response = err;
                if (response.message) {
                  this.commonService.showSnakeBar(response.message);
                }
                this.stopImageLoading();
              })
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
              this.stopImageLoading();
            }
          })
        })
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowedfiles.indexOf(ext.toLowerCase()) != -1) {
      return true;
    }
    else {
      this.commonService.showSnakeBar("Please try with valid file extensions (.jpg, .jpeg, .bmp, .png)");
      return false;
    }
  }

  resetImageInput(event) {
    if (event.target.files[0] != undefined) {
      event.target.value = "";
    }
  }

  validateFileSize(fileSize: number) {
    if (((fileSize / 1024) / 1024) > 5) {
      return false;
    }
    else {
      return true;
    }
  }

  private createFile(fileBlob: Blob, fileName: string): File {
    const file: File = <File>Object.assign(fileBlob, { name: fileName });
    return file;
  }

  startImageLoading() {
    this.isUploading = true;
    this.isProcessing = true;
  }

  stopImageLoading() {
    this.isUploading = false;
    this.isProcessing = false;
  }

}

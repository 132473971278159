import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from '../../services/common.service';
import { JwtService } from '../../services/jwt.service';
import { ConfirmationPopupComponent } from '../../shared/confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../configs/app-settings';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { OtpVerificationComponent } from 'src/app/ap-common/otp-verification/otp-verification.component';
import { VerificationService } from 'src/app/services/verification.service';
import { IOtpVerificationParams } from 'src/app/models/otp-verification-data.model';
import { verificationTypes } from 'src/app/models/verification-types.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public isProcessing: boolean = false;
  public formError: any = {};
  public showAuthenticateStep1: boolean = false;
  public showAuthenticateStep2: boolean = false;
  public showPhoneVerificationStep: boolean = false;
  public maskedPhoneNumber: string = "";
  public maskedEmail: string = "";
  public verificationMethod: string = "phone";
  public verificationLoader: boolean = false;
  public userDetails;
  public twoFactorVerificationData: IOtpVerificationParams;
  public phoneVerificationData: {
    phone: string,
    verificationFrom: string,
    verificationToken: string,
    countryCode: string
  };
  public otpRecordId: number;
  @ViewChild('otpComponent', { static: false }) otpCmp: OtpVerificationComponent;
  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private jwtService: JwtService,
    public dialog: MatDialog,
    public appSettings: AppSettings,
    private verificationService: VerificationService
  ) {
    this.commonService.setTitle('Login');
  }

  ngOnInit() {
    this.initForm();
  }


  ngOnDestroy() {
  }

  initForm() {
    this.loginForm = new UntypedFormGroup({
      'email': new UntypedFormControl(null, [
        Validators.required, Validators.email
      ]),
      'password': new UntypedFormControl(null, [
        Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
      ])
    });
  }

  submitForm(form) {
    if (form.valid) {
      this.isProcessing = true;
      this.formError = {}
      this.userService.login(form.value).subscribe(res => {
        try {
          const response = res;
          if (response.status === httpStatusCodes.success) {
            this.userDetails = response.data;

            if (this.userDetails.phoneStatus === 'verified') {
              // show two factor authentication form
              this.showAuthenticateStep1 = true;
            } else {

              if (this.userDetails.showPhoneVerificationPopup) {

                this.commonService.showSnakeBar(response.message);
                setTimeout(() => {
                  localStorage.setItem('AUTOP_PHONE_VERIFICATION_MODEL', 'true');
                  this.jwtService.setAccessDetail(this.userDetails);
                }, 800);

              } else {
                this.phoneVerificationData = {
                  phone: this.userDetails.PhoneNo,
                  countryCode: this.userDetails.countryCode,
                  verificationFrom: verificationTypes.forcePhoneVerification,
                  verificationToken: this.userDetails.verificationToken
                }
                this.showPhoneVerificationStep = true;
              }
            }
          }
          else {
            this.commonService.showSnakeBar("Something went wrong");
          }
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        const error = err;
        this.isProcessing = false;
        if (error.message && error.data.data != "notRegisteredEmail") {
          if ('errors' in error.errors) {
            this.handleServerFormError(error.errors);
          }
          this.commonService.showSnakeBar(error.message);
        }
        else if (error.data.data === "notRegisteredEmail") {
          this.notRegisteredEmailPopUp(error.data.data, error.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  changeVerificationMethod() {
    this.verificationMethod = this.verificationMethod === 'phone' ? 'email' : 'phone';
  }

  sendVerificationOtp() {
    this.verificationLoader = true;
    const params = {
      verificationMethod: this.verificationMethod,
      verificationToken: this.userDetails.verificationToken
    };
    this.verificationService.sendVerificationOtp(params, verificationTypes.login).subscribe(
      (res) => {
        const response = res;
        this.verificationLoader = false;
        try {
          if (response.status === httpStatusCodes.success) {
            this.otpRecordId = response.data.otpId;
            this.twoFactorVerificationData = {
              otpId: response.data.otpId,
              phone: this.userDetails.phone,
              email: this.userDetails.email,
              verificationMethod: this.verificationMethod,
              verificationToken: this.userDetails.verificationToken,
              countryCode: this.userDetails.countryCode
            };
            this.showAuthenticateStep1 = false;
            this.showAuthenticateStep2 = true;
            setTimeout(() => {
              this.otpCmp.resetTimer(60);
            }, 1000);
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
      },
      (err) => {
        const error = err;
        this.verificationLoader = false;
        if (error.data.data === 'tokenExpires' || error.data.data === 'notValidToken') {
          this.showAuthenticateStep1 = false;
        }
        if (error.message) {
          this.handleServerFormError(error.errors)
          this.commonService.showSnakeBar(error.message);
        } else {
          this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
        }
      }
    );
  }

  isTokenExpires(event: string) {
    if (event === 'true') {
      this.showAuthenticateStep1 = false;
    }
  }

  otpResponse(event) {
    if (event.status === 'true') {
      this.jwtService.setAccessDetail(event.data);
    }
  }

  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors
* @return none
*/
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }


  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  notRegisteredEmailPopUp(nrEmail, errorMessage) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: errorMessage,
        data: nrEmail
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        // this.changeCompanyStatus(companyId, 2);
      }
    });
  }

  // when phone verification is done, login user and redirect, 
  loginUser(event) {
    if (event.status === 'true') {
      let userDetails = event.data;
      userDetails.phoneStatus = 'verified';
      this.jwtService.setAccessDetail(userDetails);
    }
  }
}

/*
* @ngdoc Services
* @name app.services.CommonHttp
* @description
* This service is act as a wrapper for making all type http requests.
* */

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CommonService, TOKEN_HEADER_KEY, AUTH_PREFIX } from './common.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  baseUrl: String = '';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private azureHeader = new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob' });

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private jwtService: JwtService
  ) {
    this.baseUrl = this.commonService.getApiBaseUrl();
  }


  reloadHeader(cache: boolean = false) {
    if (this.jwtService.IsAuthUser()) {
      const token = this.jwtService.getAccessToken();
      if (!this.headers.has(TOKEN_HEADER_KEY)) {
        this.headers.append(TOKEN_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
      } else {
        this.headers.set(TOKEN_HEADER_KEY, `${AUTH_PREFIX} ${token}`)
      }
    }
    if (cache == true) {
      this.headers.set('Cache-Control', 'no-cache')
      this.headers.set('Pragma', 'no-cache')
    }
  }


  /**
   * get a specific object.
   *
   * @param  {Object|Integer} args - $resource.get arguments, or { id: args } if numeric.
   * @param  {Function} callback - $resource.get callback function if any.
   * @return {Promise} Promise
   */
  public get(url: string, args: any = {}): Observable<any> {
    let path = this.baseUrl + url;
    if (!this.checkObjEmpty(args)) {
      path += "?" + this.paramsToQuery(args);
    }
    //this.reloadHeader(true)
    return this.http.get(`${path}`, { headers: this.headers });
  }

  /**
   * posting form data.
   * @param  {string} url
   * @param  {Object} form data
   * @return {Object} Observable
   */
  public post(url, args): Observable<any> {
    //this.reloadHeader()
    return this.http.post(this.baseUrl + url, JSON.stringify(args), { headers: this.headers })
  };

  /**
    * upload images to azure file paths
    * @param  {string} url
    * @param  {Object} file file
    * @return {Object} Observable
    */
  azureObjectUpload(url, file) {
    return this.http.put(url, file, { headers: this.azureHeader, observe: "response" });
  }

  /**
    * posting form data.
    * @param  {string} url
    * @param  {Object} form data
    * @return {Object} Observable
    */
  postAttachment(url, args): Observable<any> {
    //this.reloadHeader()
    return this.http.post(this.baseUrl + url, JSON.stringify(args), { headers: this.headers, responseType: 'blob' });
  };

  /**
   * Check object empty
   * @return {Boolean}
   */
  checkObjEmpty(obj = {}) {
    return Object.keys(obj).length === 0
  }

  /**
   * paramsToQuery - Convert object to query string
   * @param {Object} params - Data to send.
   * @return {String}
   */
  paramsToQuery(params) {
    let str = [], p;
    for (p in params) {
      if (params.hasOwnProperty(p)) {
        let k = p, v = params[p];
        str.push(
          (v !== null && typeof v === "object") ? this.paramsToQuery(v) : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  }

  /**
   * format errors
   * @return error
   */
  private handleError(error: HttpErrorResponse) {
    console.log(error, 130);
    if (error.status === 401) {
      // Handle 401 Unauthorized error here, e.g., redirect to login page or show an error message.
      console.error('401 Unauthorized:', error);
    } else {
      // Handle other HTTP errors as needed.
      console.error('HTTP Error:', error);
    }

    return throwError(error);
  }

}

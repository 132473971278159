import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OtpVerificationComponent } from 'src/app/ap-common/otp-verification/otp-verification.component';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { IOtpVerificationParams } from 'src/app/models/otp-verification-data.model';
import { IUserData } from 'src/app/models/user-data.model';
import { verificationTypes } from 'src/app/models/verification-types.model';
import { CommonService } from 'src/app/services/common.service';
import { JwtService } from 'src/app/services/jwt.service';
import { VerificationService } from 'src/app/services/verification.service';

enum status {
  pending = 'pending',
  verified = 'verified'
}

@Component({
  selector: 'update-mobile',
  templateUrl: './update-mobile.component.html',
  styleUrls: ['./update-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateMobileComponent implements OnInit {

  public phoneStatus: string;
  public phoneNumberForm: UntypedFormGroup;
  public verificationLoader: boolean = false;
  public phoneVerificationData: IOtpVerificationParams;
  public showOtpComponent: boolean = false;
  public enableSendOtpBtn: boolean = true;
  public existingPhoneNo: string;
  public userDetails: IUserData;
  @ViewChild('otpComponent', { static: false }) otpCmp: OtpVerificationComponent;
  constructor(
    private jwtService: JwtService,
    private commonService: CommonService,
    private verificationService: VerificationService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.userDetails = this.jwtService.getUserDetails();
    this.phoneStatus = this.userDetails.phoneStatus;
    if (this.phoneStatus === status.verified) {
      this.enableSendOtpBtn = false;
    }
    this.existingPhoneNo = this.userDetails.PhoneNo;
    this.phoneNumberForm.patchValue({ phone: this.userDetails.PhoneNo });
  }

  initForm() {
    this.phoneNumberForm = new UntypedFormGroup({
      'phone': new UntypedFormControl(null, [
        Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ])
    });
  }

  checkPhoneNumber() {
    if (this.phoneNumberForm.value.phone !== this.existingPhoneNo) {
      this.phoneStatus = status.pending;
      if (this.phoneNumberForm.valid) {
        this.enableSendOtpBtn = true;
      } else {
        this.enableSendOtpBtn = false;
      }
    } else if (this.phoneNumberForm.value.phone === this.existingPhoneNo) {
      if (this.jwtService.getUserPhoneStatus() === status.verified) {
        this.phoneStatus = status.verified;
        this.enableSendOtpBtn = false;
      } else {
        this.enableSendOtpBtn = true;
      }

    }
  }

  sendOtp(form) {
    if (this.phoneNumberForm.valid) {
      if (this.phoneStatus === status.pending) {
        const params = {
          verificationMethod: 'phone',
          phone: this.phoneNumberForm.value.phone,
          userId: this.jwtService.getUserId(),
          countryCode: this.userDetails.countryCode
        };
        this.verificationLoader = true;
        this.verificationService.sendVerificationOtp(params, verificationTypes.phoneVerifyOrUpdate).subscribe(
          (res) => {
            const response = res;
            this.verificationLoader = false;
            try {
              if (response.status === httpStatusCodes.success) {
                this.phoneStatus = status.pending;
                this.phoneVerificationData = {
                  otpId: response.data.otpId,
                  phone: form.value.phone,
                  email: '',
                  verificationMethod: 'phone',
                  countryCode: this.userDetails.countryCode
                };
                this.showOtpComponent = true;
                setTimeout(() => {
                  this.otpCmp.resetTimer(60);
                }, 1000);
              }
            } catch (e) {
              this.commonService.commonSnakeBar();
            }
          },
          (err) => {
            const error = err;
            this.verificationLoader = false;
            if (error.message) {
              this.commonService.showSnakeBar(error.message);
            } else {
              this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
            }
          }
        );
      } else {
        this.commonService.showSnakeBar('Mobile Number is already verified');
      }
    }
  }

  otpResponse(event) {
    if (event.status === 'true') {
      this.phoneStatus = status.verified;
      this.enableSendOtpBtn = false;
      this.showOtpComponent = false;
      this.existingPhoneNo = this.phoneNumberForm.value.phone;
      this.jwtService.updateUserPhoneAndStatus(this.phoneNumberForm.value.phone, status.verified);
    }
  }

  changePhoneNumber(event: string) {
    if (event === 'change') {
      this.showOtpComponent = false;
    }
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-insta-api-key',
  templateUrl: './insta-api-key.component.html',
  styleUrls: ['./insta-api-key.component.scss']
})
export class InstaApiKeyComponent implements OnInit {

  companyDetails: any;
  constructor(
    public dialogRef: MatDialogRef<InstaApiKeyComponent>, @Inject(MAT_DIALOG_DATA) public data: InstaApiKeyComponent,
    private commonService: CommonService,
    public roleService: RoleCheckerService,
    private _clipboardService: ClipboardService) {
    this.companyDetails = this.data.companyDetails
    //console.log(JSON.stringify(this.companyDetails))
  }

  ngOnInit() {
  }

  triggerAction(action = 'No') {
    this.dialogRef.close(action);
  }

  copiedMsg(key: string, text: string) {
    this._clipboardService.copy(key);
    this.commonService.showSnakeBar(`${text} has been copied successfully.`)
  }

  copiedError() {
    this.commonService.showSnakeBar('An error occurred while coping. Please use Ctrl+C for copy.')
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-row {
  padding: 50px 32px 32px 32px;
  background: #F8F8F8;
}

.table-wrapper {
  padding: 0 32px;
  background: #ffffff;
  overflow: auto;
}
.table-wrapper tr th:nth-child(3), .table-wrapper tr th:nth-child(4) {
  min-width: 120px;
}

::ng-deep .custom-table {
  border-radius: 0 !important;
  box-shadow: none !important;
}
::ng-deep .custom-table th {
  font-size: inherit !important;
  vertical-align: text-top;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
::ng-deep .custom-table td {
  vertical-align: text-top;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.odometer-range {
  position: relative;
}
.odometer-range mat-label {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  top: -20px;
}
.odometer-range .mat-slider-wrapper {
  margin: 0;
  margin: -7px 10px 0 10px;
}
.odometer-range .mat-slider-wrapper .odometer-slider {
  width: 100%;
}

.usage_count {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/usage-details/usage-details.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;EACA,cAAA;AACJ;AACQ;EACI,gBAAA;AACZ;;AAIA;EACI,2BAAA;EACA,2BAAA;AADJ;AAEI;EACI,6BAAA;EACA,wBAAA;EACA,4BAAA;EACA,+BAAA;AAAR;AAEI;EACI,wBAAA;EACA,4BAAA;EACA,+BAAA;AAAR;;AAIA;EACI,kBAAA;AADJ;AAEI;EACI,kBAAA;EACA,yBAAA;EACA,UAAA;AAAR;AAEI;EACI,SAAA;EACA,wBAAA;AAAR;AACQ;EACI,WAAA;AACZ;;AAGA;EACI,eAAA;AAAJ","sourcesContent":[".filter-row{\n    padding:50px 32px 32px 32px;\n    background: #F8F8F8;\n}\n\n.table-wrapper{\n    padding:0 32px;\n    background: #ffffff;\n    overflow: auto;\n    tr{\n        th:nth-child(3), th:nth-child(4){\n            min-width: 120px;\n        }\n    }\n}\n\n::ng-deep .custom-table{\n    border-radius:0 !important;\n    box-shadow: none !important;\n    th{\n        font-size:inherit !important;\n        vertical-align: text-top;\n        padding-top:20px !important;\n        padding-bottom:20px !important;\n    }\n    td{\n        vertical-align: text-top;\n        padding-top:10px !important;\n        padding-bottom:10px !important;\n    }\n}\n\n.odometer-range{\n    position: relative;\n    mat-label{\n        position: absolute;\n        color: rgba(0, 0, 0, 0.6);\n        top: -20px;\n    }\n    .mat-slider-wrapper{\n        margin:0;\n        margin:-7px 10px 0 10px;\n        .odometer-slider{\n            width:100%;\n        }\n    }\n}\n.usage_count {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

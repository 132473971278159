import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './../shared/shared.module';

import { MaterialComponentsModule } from './../material-components.module';

import { RouterModule } from '@angular/router';

import { UikitComponent } from './uikit/uikit.component';



@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    UikitComponent
  ],
  providers: [

  ]
})
export class UiKitModule { }

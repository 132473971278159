import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
    transform(value: any): any {
        if (value) {
            let val = value.replace(/,/g, '').replaceAll('.', '');
            let retNumber = Number(val);
            return isNaN(retNumber) ? 0 : retNumber;
        }
        return ''
    }
}
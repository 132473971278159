import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from '../../services/role-checker.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  public innerWidth: any;
  public showMobileMenu: boolean = false;
  public userName: string = "";
  public userEmail: string = "";

  constructor(
    public dialog: MatDialog,
    public roleCheckService: RoleCheckerService,
    public jwtService: JwtService
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.userName = this.jwtService.getUserName();
    this.userEmail = this.jwtService.getUserEmail();
  }


  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  public chkStatus(status): void {
    this.showMobileMenu = status;
  }

}

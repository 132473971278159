/*
* @ngdoc Config
* @name app.configs.app-settings
* @description
* The service is used to define global app settings
* */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AppSettings {
  APP_NAME = "Datium Web Portal";
  PAGINATOR_SIZE = 10;
  PAGINATOR_SIZE_OPTIONS = [10, 20, 30];
  ASSETS_BASE_PATH = 'assets/images/';
  FULL_DATE = 'dd/MM/yyyy, h:mm a';
  COMMON_DATE_FORMAT = 'dd/MM/yyyy';
  DATE_FORMAT_H = 'dd-MM-yyyy';
  HISTORY_TIME_FORMAT = 'MMM d, y'; //mediumDate
  HISTORY_COMMENT_TIME = 'h:mm a';
  SUBSCRIPTION_FORMAT = 'd MMM y';
  AutoPredict = 1;
  InstantVal = 2;
  PriceMyTruck = 6;
  TruckVal = 8;
  BikeVal = 9;
  AssetInfo = 10;
  SalvageVal = 11;
  AssessVal = 12;
  CustomerTypes = [
    {
      id: 1,
      value: 'Internal'
    },
    {
      id: 2,
      value: 'Partnership'
    },
    {
      id: 3,
      value: 'External'
    }
  ];
  priceFormat = "1.2-2";
  gstText = '(10%)'
  perYear = 'per year';
  daysInterval = [
    'Today',
    'Yesterday',
    'Last Month',
    'Last Week',
    'This Month',
    'This Week'
  ];
  jobTitleRegex = /^[ A-Za-z0-9_@.,:;^`'|#\/&$#!%*=~?<>+{}([\])-]*$/;
  companyProducts = [
    { key: "AutoPredict", value: "AutoPredict" },
    { key: "InstantVal", value: "InstantValuation" },
    { key: "PriceMyTruck", value: "PriceMyTruck" },
    { key: "AssetInfo", value: "AssetInfo" },
    { key: "SalvageVal", value: "SalvageVal" },
    { key: "AssessVal", value: "AssessVal" }
  ];
  regoStateList: Array<string> = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  saleRegionList: Array<string> = ['ACT', 'NSW Metro', 'NSW Regional', 'NT', 'QLD Metro', 'QLD Regional', 'SA', 'TAS', 'VIC', 'WA'];
  saleTypeList: Array<string> = ['Auction', 'Fixed Price', 'Retail'];
}

/*
  *
  * @ngdoc Services
  * @name app.services.VerificationService
  * @description
  * The verification Service is used for handling the otp verification steps
  *
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
import { Observable } from 'rxjs';
import { verificationTypes } from '../models/verification-types.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) {  
    }

    

  /*
  * @name sendPhoneVerificationOtp
  * @param
  * @description send verification otp to number
  * @return Observable
  */
  sendVerificationOtp(params = {}, verificationFrom:string): Observable<any> {
    let apiUrl:string = "";
    if(verificationFrom === verificationTypes.login) {
      apiUrl = this.apiUrls.user.sendOtpLogin;
    } else if(verificationFrom === verificationTypes.signup) {
      apiUrl = this.apiUrls.user.sendOtpSignup;
    } else if(verificationFrom === verificationTypes.phoneVerifyOrUpdate) {
      apiUrl = this.apiUrls.user.sendOtpUpdateMobile;
    }  else if(verificationFrom === verificationTypes.userInvite) {
      apiUrl = this.apiUrls.user.sendOtpUserInvite;
    } else {
      apiUrl = this.apiUrls.user.sendOtpForceUpdate;
    }

    return this.commonHttpService.post(apiUrl, params);
  }

  /*
  * @name verifyOtp
  * @param
  * @description verify otp
  * @return Observable
  */
  verifyOtp(params = {}, verificationFrom:string): Observable<any> {
    let apiUrl:string = "";
    if(verificationFrom === verificationTypes.login) {
      apiUrl = this.apiUrls.user.verifyOtpLogin;
    } else if(verificationFrom === verificationTypes.signup) {
      apiUrl = this.apiUrls.user.verifyOtpSignup;
    } else if(verificationFrom === verificationTypes.userInvite) {
      apiUrl = this.apiUrls.user.verifyOtpUserInvite;
    } else if(verificationFrom === verificationTypes.phoneVerifyOrUpdate) {
      apiUrl = this.apiUrls.user.verifyOtpUpdateMobile;
    } else {
      apiUrl = this.apiUrls.user.verifyOtpUpdateForceUpdate;
    }
    return this.commonHttpService.post(apiUrl, params);
  }

  /*
  * @name resendOtp
  * @param
  * @description resend otp
  * @return Observable
  */
  resendOtp(params = {}, verificationFrom:string): Observable<any> {
    let apiUrl:string = "";
    if(verificationFrom === verificationTypes.login || verificationFrom === verificationTypes.forcePhoneVerification) {
      apiUrl = this.apiUrls.user.resendOtpLogin;
    } else if(verificationFrom === verificationTypes.phoneVerifyOrUpdate) {
      apiUrl = this.apiUrls.user.resendOtpUpdateMobile;
    } else {
      apiUrl = this.apiUrls.user.resendOtpSignup;
    }
    return this.commonHttpService.post(apiUrl, params);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { CommonService } from '../../services/common.service';
import { environment } from 'src/environments/environment';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { OtpVerificationComponent } from 'src/app/ap-common/otp-verification/otp-verification.component';
import { VerificationService } from 'src/app/services/verification.service';
import { IOtpVerificationParams } from 'src/app/models/otp-verification-data.model';
import { verificationTypes } from 'src/app/models/verification-types.model';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'app-company-signup',
  templateUrl: './company-signup.component.html',
  styleUrls: ['./company-signup.component.scss']
})
export class CompanySignupComponent implements OnInit {

  public captchaKey: string = '';
  public isProcessing: boolean = false;

  public companyForm: UntypedFormGroup;
  public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  public isUploading: boolean = false;
  public companyLogoModel: string = '';
  public abnLoader: boolean = false;
  public emailLoader: boolean = false;

  public isSuccess = false;
  public formError = {};
  public showOtpComponent: boolean = false;
  private otpRecordId: number;
  public otp: number;
  public phoneVerificationData: IOtpVerificationParams;
  public saveCompanyLoader: boolean = false;
  public countryCode: string = '+61';
  @ViewChild('otpComponent', { static: false }) otpCmp: OtpVerificationComponent;
  @ViewChild('phoneNo', { static: false }) phoneElem: ElementRef;

  constructor(
    private companyService: CompanyService,
    private commonService: CommonService,
    private verificationService: VerificationService,
    private appSettings: AppSettings
  ) {

    this.commonService.setTitle('Create Account');
    this.captchaKey = environment.gCaptchaKey;
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
  }

  initForm() {
    this.companyForm = new UntypedFormGroup({
      'companyName': new UntypedFormControl(null),
      'email': new UntypedFormControl(null, [
        Validators.required, Validators.email, Validators.maxLength(255)
      ]),
      'phoneNo': new UntypedFormControl(null, [
        Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]),
      'userName': new UntypedFormControl(null, [
        Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
      ]),
      'jobTitle': new UntypedFormControl(null, [
        Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
      ]),
      'abn_number': new UntypedFormControl(null, [Validators.required]),
      'recaptchaReactive': new UntypedFormControl(null, Validators.required),
      'otpRecordId': new UntypedFormControl()
    });
  }

  checkValidABN() {
    if (this.companyForm.get('abn_number')!.valid) {
      this.companyForm.patchValue({ abn_number: this.companyForm.get('abn_number').value.replace(/ /g, '') });
      this.getABNDetails();
    }
  }

  getABNDetails() {
    let abn_number = this.companyForm.get('abn_number').value.replace(/ /g, '');
    if (abn_number && !this.commonService.ABNValidation(abn_number)) {
      this.handleServerFormError({
        'abn_number': 'Please enter a valid ABN number'
      })
      //this.formError['abn_number'] = 'Please enter a valid ABN number'
      this.commonService.showSnakeBar('Please enter a valid ABN number');
      this.companyForm.get('companyName')!.setValue('');
      return false
    }
    this.abnLoader = true
    this.companyService.checkABN(this.companyForm.get('abn_number').value).subscribe(
      (res) => {
        this.abnLoader = false;
        try {
          const response = res;
          if (response.data && response.data['EntityName']) {
            console.log(response.data['EntityName'])
            this.companyForm.get('companyName').setValue(response.data['EntityName']);
          }
        } catch (e) {
          console.log(e, 'error');
        }
      },
      (err) => {
        let response = err;
        this.abnLoader = false;
        // clear invalid abn number
        this.companyForm.get('abn_number')!.setValue('');
        this.companyForm.get('companyName')!.setValue('');
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.showSnakeBar('Invalid ABN number. PLease try to enter valid one.');
        }
      }
    );
  }

  validateBusinessEmail() {
    let email = this.companyForm.get('email').value
    if (!email || !this.companyForm.get('email').valid) {
      this.handleServerFormError({
        'email': 'Please enter a valid business email.'
      })
      return false
    }
    this.emailLoader = true
    this.companyService.checkBusinessEmail(email).subscribe(
      (res) => {
        this.emailLoader = false;
        try {
          const response = res;
          this.formError['email'] = '';
        } catch (e) {
          console.log(e, 'error');
        }
      },
      (err) => {
        let response = err;
        this.emailLoader = false;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.showSnakeBar('Invalid email. Please enter a valid business email.');
        }
      }
    );
  }


  saveCompany() {
    this.saveCompanyLoader = true;
    this.companyForm.value.otpRecordId = this.otpRecordId;
    this.companyService.companySignup(this.companyForm.value).subscribe(res => {
      try {
        const response = res;
        if (response.status === httpStatusCodes.success) {
          this.saveCompanyLoader = false;
          this.isSuccess = true;
        }
      }
      catch (e) {
        this.commonService.showSnakeBar("An error occurred while processing your request. Please try again later");
      }
      this.saveCompanyLoader = false;
    }, (err) => {
      const error = err;
      this.saveCompanyLoader = false;
      if ('errors' in error) {
        setTimeout(() => {
          this.handleServerFormError(error.errors);
        }, 1000);
      }
      if (error.message) {
        this.commonService.showSnakeBar(error.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.saveCompanyLoader = false;
    })
  }

  addNewCompany(form) {
    if (form.valid) {
      const abn_number = form.value.abn_number;

      if (abn_number && !this.commonService.ABNValidation(abn_number)) {
        this.handleServerFormError({
          'abn_number': 'Please enter a valid ABN number'
        });
        this.commonService.showSnakeBar('Please enter a valid ABN number');
        this.companyForm.get('companyName').setValue('');
        return false;
      } else {
        this.formError['abn_number'] = '';
      }
      this.formError = {};
      this.verifyPhoneNumber();
    }
    else {
      this.formValidate(form);
    }
  }

  captchaResolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  errorCaptcha() {
    console.warn(`reCAPTCHA error encountered`);
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.companyForm.contains(key)) {
          let control = this.companyForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  verifyPhoneNumber() {
    if (this.companyForm.value.phoneNo !== null) {
      this.isProcessing = true;
      const params = {
        verificationMethod: 'phone',
        phone: this.companyForm.value.phoneNo
      };
      this.verificationService.sendVerificationOtp(params, verificationTypes.signup).subscribe(
        (res) => {
          const response = res;
          this.isProcessing = false;
          try {
            if (response.status === httpStatusCodes.success) {
              this.otpRecordId = response.data.otpId;
              this.phoneVerificationData = {
                otpId: response.data.otpId,
                phone: this.companyForm.value.phoneNo,
                countryCode: this.countryCode,
                email: '',
                verificationMethod: 'phone'
              };
              this.showOtpComponent = true;
              setTimeout(() => {
                this.otpCmp.resetTimer(60);
              }, 1000);
            }
            this.isProcessing = false;
          } catch (e) {
          }
        },
        (err) => {
          const error = err;
          this.isProcessing = false;
          if (error.message) {
            this.handleServerFormError(error.errors)
            this.commonService.showSnakeBar(error.message);
          } else {
            this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
          }
        }
      );
    }
  }
  changePhoneNumber(event: string) {
    if (event === 'change') {
      this.showOtpComponent = false;
      setTimeout(() => {
        this.phoneElem.nativeElement.focus();
      }, 500);
    }
  }


  otpResponse(event) {
    if (event.status === 'true') {
      this.showOtpComponent = false;
      this.saveCompany();
    }
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/company.service';
import { CommonService } from 'src/app/services/common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-term-sign',
  templateUrl: './term-sign.component.html',
  styleUrls: ['./term-sign.component.scss']
})
export class TermSignComponent implements OnInit {

  checked: boolean = false;
  isProcessing: boolean = false;
  safeHtml: SafeHtml = ''
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TermSignComponent>,
    @Inject(MAT_DIALOG_DATA) public productData,
    public companyService: CompanyService,
    public commonService: CommonService,
    private sanitizer: DomSanitizer
  ) {
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(productData['product']['Term_Conditions_Text']);

  }

  ngOnInit() {
  }


  updateTerms() {
    this.isProcessing = true;

    this.companyService.acceptProductTerms({ id: this.productData['product']['termId'] }).subscribe(res => {
      this.isProcessing = false;
      try {
        this.dialogRef.close(true);
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }

    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

}

import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../../services/company.service';
import { CommonService } from '../../../services/common.service';
import { AzureService } from '../../../services/azure.service';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'app-update-company-v2',
  templateUrl: './update-company-v2.component.html',
  styleUrls: ['./update-company-v2.component.scss']
})
export class UpdateCompanyV2Component implements OnInit {

  public isProcessing: boolean = false;
  public loadingPer: boolean = false

  public companyForm: UntypedFormGroup;
  public isEdit: boolean;
  public onCompanyUpdate: EventEmitter<any> = new EventEmitter();
  private allowedfiles = ["png", "jpg", "jpeg", "bmp"];
  public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  public phoneNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public isUploading: boolean = false;
  public companyLogoModel: string = '';
  public customerTypes = [];
  public offer = null

  formError = {};
  startDate = new Date()
  endDate = this.startDate.setDate(this.startDate.getDate() - 30);
  step = 1
  CompanyId = null

  isSubProcessing = false
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdateCompanyV2Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    public companyService: CompanyService,
    public commonService: CommonService,
    public azureService: AzureService,
    private appSettings: AppSettings
  ) {
    if (this.data.company) {
      this.CompanyId = this.data.company.CompanyId
    } else {
      this.commonService.showSnakeBar("Invalid company.");
      this.closeModal()
    }
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.customerTypes = this.appSettings.CustomerTypes;
    this.companyForm = new UntypedFormGroup({
      'companyId': new UntypedFormControl(this.data.company.CompanyId),
      'photoProfile': new UntypedFormControl(this.data.company.CompanyLogo),
      'thumbnailLogo': new UntypedFormControl(this.data.company.Thumbnail),
      'companyName': new UntypedFormControl(this.data.company.CompanyName, [
        Validators.required, Validators.minLength(3), Validators.maxLength(200)
      ]),
      'userName': new UntypedFormControl(this.data.company.UserName, [
        Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
      ]),
      'jobTitle': new UntypedFormControl(this.data.company.jobTitle, [
        Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
      ]),
      'abn_number': new UntypedFormControl(this.data.company.abn_number),
      'customerType': new UntypedFormControl(this.data.company.CustomerType, [Validators.required])
    });
    this.setImageUrl(this.data.company.CompanyLogo, this.data.company.Thumbnail);
  }

  openProduct(step) {
    this.step = step
  }

  updateCompany(form) {
    if (form.valid) {
      let abn_number = form.value.abn_number
      if (abn_number && !this.commonService.ABNValidation(abn_number)) {
        this.handleServerFormError({
          'abn_number': 'Please enter a valid ABN number'
        })
        //this.formError['abn_number'] = 'Please enter a valid ABN number'
        this.commonService.showSnakeBar('Please enter a valid ABN number')
        return false;
      } else {
        this.formError['abn_number'] = ''
      }
      this.isProcessing = true;
      //console.log(form.value)
      const fmCopy = Object.assign({}, form.value);
      fmCopy['userPic'] = fmCopy['profilePhoto'];
      delete fmCopy['profilePhoto'];
      this.companyService.updateCompany(form.value).subscribe(res => {
        try {
          const response = res;
          let data = response.data
          if (data['CompanyId']) {
            this.onCompanyUpdate.emit(response.data);
            this.CompanyId = data['CompanyId']
            this.closeModal(data);
            //this.step = 2
            //this.onCompanyAdd.emit(response.data[0]);
            this.commonService.showSnakeBar(response.message);
          }
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  onCompanySubAdd(event) {
    //console.log(event)
    this.onCompanyUpdate.emit(event)
  }

  closeModal(data = {}) {
    this.dialogRef.close(data);
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.companyForm.contains(key)) {
          let control = this.companyForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  setImageUrl(mainUrl, thumbUrl) {
    if (mainUrl && mainUrl != '') {
      this.companyForm.controls.photoProfile.setValue(mainUrl.toString());
    }
    if (thumbUrl && thumbUrl != '') {
      this.companyForm.controls.thumbnailLogo.setValue(thumbUrl.toString())
    }
    this.companyLogoModel = thumbUrl;
  }

  fileChange(event) {
    if (event.target.files[0] != undefined && this.validateFile(event.target.files[0].name)) {
      this.companyLogoModel = '';
      let file: File = event.target.files[0];
      let fileName: string = (new Date()).getTime() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
      if (!this.validateFileSize(file.size)) {
        this.commonService.showSnakeBar("File size should be less than or equal to 5 MB");
      }
      else {
        this.startImageLoading();
        this.commonService.getImageEssentials(file).subscribe(mainImage => {
          this.commonService.compressImage(mainImage.image, file.type, [400, 100], mainImage.width, mainImage.height).subscribe(compRes => {
            if (compRes.status) {
              const reqModel = {
                fileName: fileName,
                thumbName: 'thumb' + fileName
              }
              this.azureService.getSASToken(reqModel).subscribe(res => {
                try {
                  const result = res;
                  if (result.status) {
                    for (let i = 0; i < result.data.length; i++) {
                      let newFile = <File>this.createFile(compRes.data.blobs[i], file.name);
                      this.azureService.putObject(result.data[i].uri, newFile).subscribe((res: any) => {
                        if (res.ok && i == 1) {
                          this.setImageUrl(reqModel.fileName, reqModel.thumbName);
                          this.stopImageLoading();
                        }
                      })
                    }
                  }
                }
                catch (ex) {
                  this.commonService.showSnakeBar("Something went wrong");
                  this.stopImageLoading();
                }
              }, (err) => {
                let response = err;
                if (response.message) {
                  this.commonService.showSnakeBar(response.message);
                }
                this.stopImageLoading();
              })
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
              this.stopImageLoading();
            }
          })
        })
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowedfiles.indexOf(ext.toLowerCase()) != -1) {
      return true;
    }
    else {
      this.commonService.showSnakeBar("Please try with valid file extensions (.jpg, .jpeg, .bmp, .png)");
      return false;
    }
  }

  resetImageInput(event) {
    if (event.target.files[0] != undefined) {
      event.target.value = "";
    }
  }

  validateFileSize(fileSize: number) {
    if (((fileSize / 1024) / 1024) > 5) {
      return false;
    }
    else {
      return true;
    }
  }

  private createFile(fileBlob: Blob, fileName: string): File {
    const file: File = <File>Object.assign(fileBlob, { name: fileName });
    return file;
  }

  startImageLoading() {
    this.isUploading = true;
    this.isProcessing = true;
  }

  stopImageLoading() {
    this.isUploading = false;
    this.isProcessing = false;
  }


  setOffer(event) {
    console.log(event, 'event')
    this.offer = event
    this.step = 3
  }

  subInProgress(event) {
    this.isSubProcessing = event
  }
}


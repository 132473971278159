import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { JwtService } from 'src/app/services/jwt.service';
import { CommonService } from 'src/app/services/common.service';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-callback.component.html',
  styleUrls: ['./okta-callback.component.scss']
})
export class OktaCallbackComponent implements OnInit {

  constructor(
    private renderer: Renderer2,
    private userService: UserService,
    private jwtService: JwtService,
    public appSettings: AppSettings,
    private commonService: CommonService
  ) {
    this.renderer.addClass(document.body, 'login_bg');
  }

  ngOnInit() {
    let params: any = this.getHashParams()
    if ('code' in params) {
      this.validateOktaCode(params['code']);
    } else {
      window.location.href = '/error'
    }
  }

  validateOktaCode(code: string) {
    this.userService.oktaLogin({ code: code }).subscribe(res => {
      try {
        const response = res;
        if (response.status == 200) {
          this.jwtService.setAccessDetail(response.data);
        } else {
          this.commonService.showSnakeBar("Something went wrong.Please try again later");
          window.location.href = '/admin/login';
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      let response = err;
      if (response.message) {

        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      window.location.href = '/error'
    }, () => {

    })
  }

  getHashParams() {

    var hashParams = {};
    var e,
      a = /\+/g,  // Regex for replacing addition symbol with a space
      r = /([^&;=]+)=?([^&;]*)/g,
      d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
      q = window.location.hash.substring(1);

    while (e = r.exec(q))
      hashParams[d(e[1])] = d(e[2]);

    return hashParams;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login_bg');
  }
}

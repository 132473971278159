import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { UikitComponent } from './uikit/uikit/uikit.component';
import { MyAccountWrapperComponent } from './my-account/my-account-wrapper/my-account-wrapper.component';
import { ManageUsersComponent } from './my-account/manage-users/manage-users.component';
import { LoginComponent } from './user-auto/login/login.component';
import { ForgotPasswordComponent } from './user-auto/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user-auto/reset-password/reset-password.component';
import { CompanyAdminGuard } from './guards/company-admin.guard';
import { MasterComponent } from './core/master/master.component';
import { HasTokenResolver, AppRoutingAccess } from './services/resolver.service';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { UsageWrapperComponent } from './dashboard/usage/usage-wrapper.component';
import { CompaniesListComponent } from './companies/companies-list/companies-list.component';
import { SubscriptionListComponent } from './companies/subscription/subscription-list/subscription-list.component';

import { SuperAdminGuard } from './guards/super-admin.guard';
import { ProductOfferComponent } from './companies/product-offer/product-offer.component';
import { ProductComponent } from './companies/product/product.component';
import { AdminUsersComponent } from './users/admin-users/admin-users.component';
import { PrivacyPolicyComponent } from './user-auto/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './user-auto/terms-condition/terms-condition.component';
import { CompanyMasterComponent } from './companies/company-master/company-master.component';
import { CompanySignupComponent } from './user-auto/company-signup/company-signup.component';
import { OktaLoginComponent } from './user-auto/okta-login/okta-login.component';
import { OktaCallbackComponent } from './user-auto/okta-callback/okta-callback.component';
import { ErrorComponent } from './user-auto/error/error.component';
import { CouponsComponent } from './companies/coupons/coupons.component';
import { ProductsListingComponent } from './companies/products-listing/products-listing.component';
import { EditProductDetailsComponent } from './companies/edit-product-details/edit-product-details.component';
import { QuotesListingComponent } from './companies/quotes-listing/quotes-listing.component';
import { SuperAdminQuoteDetailsComponent } from './companies/super-admin-quote-details/super-admin-quote-details.component';
import { InvoicesListingComponent } from './companies/invoices-listing/invoices-listing.component';
import { AutoPredictDetailsComponent } from './companies/autopredict-details/autopredict-details.component';
import { CartDetailsComponent } from './companies/cart-details/cart-details.component';
import { CompanyAdminQuotesComponent } from './companies/company-admin-quotes/company-admin-quotes.component';
import { CompanyAdminQuoteDetailsComponent } from './companies/company-admin-quote-details/company-admin-quote-details.component';
import { CompanyAdminPaymentsComponent } from './companies/company-admin-payments/company-admin-payments.component';
import { CompanyAdminSubscriptionsComponent } from './companies/company-admin-subscriptions/company-admin-subscriptions.component';
import { BackupTableComponent } from './backup-table/backup-table.component';
import { UsageDetailsComponent } from './dashboard/usage-details/usage-details.component';
import { PhoneVerificationStepsComponent } from './ap-common/phone-verification-steps/phone-verification-steps.component';
import { LogoutComponent } from './user-auto/logout/logut.component';
import { AssetInfoUsageComponent } from './dashboard/asset-info-usage/asset-info-usage.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { SalvagevalUsageComponent } from './dashboard/salvageval-usage/salvageval-usage.component';

const routes: Routes = [
  { path: 'sign-up', component: CompanySignupComponent, resolve: { access: HasTokenResolver } },
  { path: 'login', component: LoginComponent, resolve: { access: HasTokenResolver } },
  { path: 'logout', component: LogoutComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent, resolve: { access: HasTokenResolver } },
  { path: 'create-password', component: ResetPasswordComponent, resolve: { access: HasTokenResolver } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-condition', component: TermsConditionComponent },
  {
    path: '', component: MasterComponent, children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'verify-phone', component: PhoneVerificationStepsComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'backup-tables', component: BackupTableComponent },
      //{ path: 'ui_kit', component: UikitComponent },
      { path: 'my-account', component: MyAccountWrapperComponent },
      { path: 'my-account/manage-users', component: ManageUsersComponent, canActivate: [CompanyAdminGuard] },
      { path: 'usage', component: UsageWrapperComponent },
      { path: 'usage-details', component: UsageDetailsComponent },
      { path: 'asset-info-usage', component: AssetInfoUsageComponent },
      { path: 'salvageval-usage', component: SalvagevalUsageComponent },
      { path: 'companies', component: CompaniesListComponent, canActivate: [SuperAdminGuard] },
      { path: 'companies/add-config', component: DashboardComponent, canActivate: [SuperAdminGuard] },
      {
        path: 'companies/:id', component: CompanyMasterComponent,
        children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          { path: 'users', component: ManageUsersComponent },
          { path: 'subscriptions', component: SubscriptionListComponent }
        ]
      },
      // { path: 'products', component: ProductComponent, canActivate: [SuperAdminGuard] },
      // { path: 'product-offers', component: ProductOfferComponent, canActivate: [SuperAdminGuard] },
      { path: 'coupons-listing', component: CouponsComponent, canActivate: [SuperAdminGuard] },
      { path: 'product-listing', component: ProductsListingComponent, canActivate: [SuperAdminGuard] },
      { path: 'product-listing/edit-product-details/:id', component: EditProductDetailsComponent, canActivate: [SuperAdminGuard] },
      { path: 'quotes-listing', component: QuotesListingComponent, canActivate: [SuperAdminGuard] },
      { path: 'quote-details/:id', component: SuperAdminQuoteDetailsComponent, canActivate: [SuperAdminGuard] },
      { path: 'invoice-listing', component: InvoicesListingComponent, canActivate: [SuperAdminGuard] },
      { path: 'users', component: AdminUsersComponent, canActivate: [SuperAdminGuard] },

      {
        path: 'product-details/:id', component: AutoPredictDetailsComponent, canActivate: [SuperAdminGuard], data: {
          isCompanyAdminAlso: true
        }
      },
      {
        path: 'cart', component: CartDetailsComponent, canActivate: [SuperAdminGuard], data: {
          isCompanyAdminAlso: true
        }
      },
      {
        path: 'cart', component: CartDetailsComponent, canActivate: [SuperAdminGuard], data: {
          isCompanyAdminAlso: true
        }
      },
      { path: 'my-cart', component: CartDetailsComponent, canActivate: [CompanyAdminGuard] },
      { path: 'my-quotes', component: CompanyAdminQuotesComponent, canActivate: [CompanyAdminGuard] },
      { path: 'my-quotes/:id', component: CompanyAdminQuoteDetailsComponent, canActivate: [CompanyAdminGuard] },
      { path: 'my-payments', component: CompanyAdminPaymentsComponent, canActivate: [CompanyAdminGuard] },
      { path: 'my-subscriptions', component: CompanyAdminSubscriptionsComponent, canActivate: [CompanyAdminGuard] },

    ], resolve: { access: AppRoutingAccess }
  },
  { path: 'admin/login', component: OktaLoginComponent, resolve: { access: HasTokenResolver } },
  { path: 'authorization-code/callback', component: OktaCallbackComponent, resolve: { access: HasTokenResolver } },
  { path: 'error', component: ErrorComponent },
  { path: 'admin/forgot-password', component: ForgotPasswordComponent, resolve: { access: HasTokenResolver } },
  { path: 'admin/create-password', component: ResetPasswordComponent, resolve: { access: HasTokenResolver } },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AppRoutingAccess, HasTokenResolver, SuperAdminGuard]
})

export class RoutingModule { }

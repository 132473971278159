import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() type: string = 'normal'
  @Input() text: string = 'Loading...'
  @Input() ShowLoader: Boolean = false
  @Input() CStyle: any
  constructor() { }

  ngOnInit() {
  }

}

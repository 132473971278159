// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-divider {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.2);
  margin: 15px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/companies/company-admin-subscriptions/company-admin-subscriptions.component.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;EACA,cAAA;AACF","sourcesContent":[".dots-divider {\n  border-bottom: 2px dotted rgba(0, 0, 0, 0.2);\n  margin: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

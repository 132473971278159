import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { JwtService } from 'src/app/services/jwt.service';
import { VerificationService } from 'src/app/services/verification.service';
import { IOtpVerificationParams } from 'src/app/models/otp-verification-data.model';
import { verificationTypes } from 'src/app/models/verification-types.model';

@Component({
  selector: 'app-phone-verification-steps',
  templateUrl: './phone-verification-steps.component.html',
  styleUrls: ['./phone-verification-steps.component.scss']
})
export class PhoneVerificationStepsComponent implements OnInit {
  public verifyNowStep1: boolean = false;
  public sendOtpStep2: boolean = false;
  public verifyOtpStep3: boolean = false;
  public phoneNumberForm: UntypedFormGroup;
  public phoneValidatedStatus: boolean = false;
  public verificationLoader: boolean = false;
  public phoneVerificationData: IOtpVerificationParams;
  public showOtpComponent: boolean = false;
  private userId: number;

  @ViewChild('otpComponent', { static: false }) otpCmp: OtpVerificationComponent;
  @Input() userDetails: {
    phone: string,
    verificationFrom: string,
    userId?: number,
    verificationToken?: string,
    countryCode: string
  };
  @Input() loadingFrom: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() phoneVerifiedSuccess = new EventEmitter();
  @Output() tokenExpiresEvent = new EventEmitter();
  constructor(
    private commonService: CommonService,
    private jwtService: JwtService,
    private verificationService: VerificationService
  ) {

  }

  ngOnInit() {
    this.initForm();
    this.verifyNowStep1 = true;
    if (this.userDetails !== undefined) {
      this.userId = this.userDetails.userId;
      this.phoneNumberForm.patchValue({ phone: this.userDetails.phone });
    } else {
      this.userId = this.jwtService.getUserId();
    }
  }

  initForm() {
    this.phoneNumberForm = new UntypedFormGroup({
      'phone': new UntypedFormControl(null, [
        Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ])
    });
  }

  verifyNow() {
    this.verifyNowStep1 = false;
    this.sendOtpStep2 = true;
  }

  closeModel() {
    this.closeModal.emit('close');
  }

  sendOtp(form) {
    if (this.phoneNumberForm.valid) {
      const params = {
        verificationMethod: 'phone',
        phone: this.phoneNumberForm.value.phone,
        countryCode: this.userDetails.countryCode,
        userId: this.userId,
        verificationToken: this.userDetails.verificationToken
      };
      this.verificationLoader = true;
      if (this.userDetails.verificationFrom === verificationTypes.forcePhoneVerification) {

        this.verificationService.sendVerificationOtp(params, this.userDetails.verificationFrom).subscribe(
          (res) => {
            const response = res;
            this.verificationLoader = false;
            try {
              if (response.status === httpStatusCodes.success) {
                this.phoneVerificationData = {
                  otpId: response.data.otpId,
                  phone: form.value.phone,
                  countryCode: this.userDetails.countryCode,
                  email: '',
                  verificationMethod: 'phone',
                  verificationToken: this.userDetails.verificationToken
                };
                this.sendOtpStep2 = false;
                this.verifyOtpStep3 = true;
                this.showOtpComponent = true;
                setTimeout(() => {
                  this.otpCmp.resetTimer(60);
                }, 1000);
              }
            } catch (e) {
              this.commonService.commonSnakeBar();
            }
          },
          (err) => {
            const error = err;
            this.verificationLoader = false;
            if (error.data.data === 'tokenExpires') {
              this.tokenExpiresEvent.emit('true');
            }
            if (error.message) {
              this.commonService.showSnakeBar(error.message);
            } else {
              this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
            }
          }
        );

      } else {

        this.verificationService.sendVerificationOtp(params, this.userDetails.verificationFrom).subscribe(
          (res) => {
            const response = res;
            this.verificationLoader = false;
            try {
              if (response.status === httpStatusCodes.success) {
                this.phoneVerificationData = {
                  otpId: response.data.otpId,
                  phone: form.value.phone,
                  countryCode: this.userDetails.countryCode,
                  email: '',
                  verificationMethod: 'phone'
                };
                this.sendOtpStep2 = false;
                this.verifyOtpStep3 = true;
                this.showOtpComponent = true;
                setTimeout(() => {
                  this.otpCmp.resetTimer(60);
                }, 1000);
              }
            } catch (e) {
              this.commonService.commonSnakeBar();
            }
          },
          (err) => {
            const error = err;
            this.verificationLoader = false;
            if (error.message) {
              this.commonService.showSnakeBar(error.message);
            } else {
              this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
            }
          }
        );
      }
    }
  }

  otpResponse(event) {
    if (event.status === 'true') {
      if (this.loadingFrom === 'dashboard') {
        this.jwtService.updateUserPhoneAndStatus(this.phoneNumberForm.value.phone, 'verified');
        this.closeModal.emit('close');
        this.verifyOtpStep3 = false;
        this.showOtpComponent = false;
      } else {
        this.phoneVerifiedSuccess.emit({ status: 'true', data: event.data });
      }
    }
  }

  changePhoneNumber(event: string) {
    if (event === 'change') {
      this.verifyOtpStep3 = false;
      this.sendOtpStep2 = true;
    }
  }

}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { JwtService } from 'src/app/services/jwt.service';
import { ProductService } from 'src/app/services/product.service';
import { QuotesService } from 'src/app/services/quotes.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { environment } from 'src/environments/environment';
import { SelectMakesModalComponent } from '../select-makes-modal/select-makes-modal.component';

interface IvForm {
  Quantity: number;
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
  TotalPrice: number,
  ItemPrice: number
}
interface AutpForm {
  User: number;
  Make: Array<string>;
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
}
interface PriceMyTruckForm {
  Price: number;
  Product_Config_Id: number;
  DraftOrderItemId: number;
  TotalPrice: number;
  ItemPrice: number;
}
@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss']
})
export class CartDetailsComponent implements OnInit, OnDestroy {

  formError = {};
  validations = [
    Validators.required, Validators.minLength(3) //, Validators.maxLength(40), Validators.pattern("^[0-9A-Za-z_-][A-Za-z0-9 _-]*$")
  ]
  paymentForm: UntypedFormGroup = new UntypedFormGroup({
    'billing_address': new UntypedFormControl(null, this.validations),
    'state': new UntypedFormControl(null, this.validations),
    'city': new UntypedFormControl(null, this.validations),
  });

  configLoader: boolean = false;
  DraftOrderId = null;
  cartDetails = undefined;

  quoteLoader: boolean = false;
  Makes: Array<string> = [];
  PriceMyTruckMakes: Array<string> = [];
  instantValForm: Array<IvForm> = [];
  priceMyTruckForm: {
    Make: Array<string>,
    Product_Config_Id: number,
    DraftOrderItemId: number,
    ItemPrice: number,
    TotalPrice: number,
    Name: string,
    User: number,
    MaxUser: number,
    UserPrice: number,
    TotalUserPrice: number,
    TotalMakePrice: number,
    MakePrice: number,
    MaxMake: number
  } = undefined;
  priceFormat = "1.2-2";
  deleteCart = null;
  autpOrderDetails = undefined;
  priceMyTruckCouponCode = undefined;
  ivOrderDetails = undefined;
  priceMyTruckDetailOrderDetails = undefined;
  autpForm: {
    Make: Array<string>,
    User: number,
    MakePrice: number,
    MaxMake: number,
    MaxUser: number,
    UserPrice: number,
    ConfigName: string,
    ConfigPermissions: string
    Product_Config_Id: number,
    ItemPrice: number,
    TotalMakePrice: number,
    TotalUserPrice: number,
    DraftOrderItemId: number
  } = undefined;
  cartLoader = null;
  ivLoader = null;
  truckLoader = null;
  autpLoader: number = null;
  autpCouponCode = null
  ivCouponCode = null
  companyId = null;
  queryParams = null;
  companyDetails = null;
  companyLoader = false;
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private productService: ProductService,
    private quoteService: QuotesService,
    public appSettings: AppSettings,
    public dataStore: DataStoreService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private jwtService: JwtService,
    public roleCheckService: RoleCheckerService,
    private activatedRoute: ActivatedRoute,
    public subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.companyId = params['companyId'];
    //   if (this.roleCheckService.isSuperAdmin() && this.companyId) {
    //     this.queryParams = { companyId: this.companyId }
    //   }
    //   this.getCartDetails();
    // });
    this.companyId = null;
    this.getCartDetails();
  }

  sendToCompany() {
    this.commonService.showSnakeBar(
      "Unable to find the requested company."
    );
    this.router.navigate(["/companies"]);
  }

  getCompanyDetails() {
    this.companyLoader = true;
    this.subscriptionService
      .getCompanyDetails({ companyId: this.companyId })
      .subscribe(
        result => {
          this.companyLoader = false;
          try {
            let response = result;
            if (!response.data) {
              this.sendToCompany()
            }
            this.companyDetails = response.data

          } catch (e) { }
        },
        error => {
          //console.log(error)
          this.companyLoader = false;
          let response = error;

          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => { }
      );
  }

  getCartDetails() {
    this.configLoader = true;
    this.productService
      //.getCartDetails({ companyId: this.companyId })
      .getCartDetails()
      .subscribe(
        (res) => {
          this.configLoader = false;
          // try {
          const response = res;
          if (
            response.data &&
            Object.keys(response.data).length > 0
          ) {
            if (this.roleCheckService.isSuperAdmin()) {
              this.companyId = response.data['Company_Id'];
              this.getCompanyDetails()
            }
            this.DraftOrderId = response.data['DraftOrderId'];

            this.setupProductItems(response.data);
            this.Makes = response.data['Makes'].split(',').map(el => String(el));
            this.PriceMyTruckMakes = response.data['PriceMyTruckMakes'];
            this.loadStripe();
          } else {
            this.cartDetails = undefined;
            this.DraftOrderId = null;
            this.autpOrderDetails = undefined;
            this.ivOrderDetails = undefined;
            this.dataStore.setCart(0);
          }
          // } catch (e) {
          //   this.commonService.commonSnakeBar();
          // }
        },
        (err) => {
          this.configLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

  setupProductItems(data = undefined) {
    if (data) {
      let cartDetail = data['cartDetail']
      if ("autpDetail" in cartDetail && "OrderItems" in cartDetail["autpDetail"]) {
        this.autpOrderDetails = cartDetail["autpDetail"];
        this.autpForm = {
          User: parseInt(this.autpOrderDetails["OrderItems"]['TotalUsers']),
          Make: this.autpOrderDetails["OrderItems"]['Make'],
          MakePrice: parseInt(this.autpOrderDetails['ExtraConfig']['MakePrice']),
          MaxMake: parseInt(this.autpOrderDetails['ExtraConfig']['MaxMake']),
          MaxUser: parseInt(this.autpOrderDetails['ExtraConfig']['MaxUser']),
          UserPrice: parseInt(this.autpOrderDetails['ExtraConfig']['UserPrice']),
          ConfigName: this.autpOrderDetails['OrderItems']['ProductConfig']['Name'],
          ConfigPermissions: this.autpOrderDetails['OrderItems']['ProductConfig']['ConfigPermissions'],
          Product_Config_Id: parseInt(this.autpOrderDetails['OrderItems']['ProductConfig']['Product_Config_Id']),
          ItemPrice: this.autpOrderDetails['OrderItems']['ItemPrice'],
          TotalMakePrice: this.autpOrderDetails['OrderItems']['OrderItemConfigs']['TotalMakePrice'],
          TotalUserPrice: this.autpOrderDetails['OrderItems']['OrderItemConfigs']['TotalUserPrice'],
          DraftOrderItemId: parseInt(this.autpOrderDetails['OrderItems']['DraftOrderItemId'])
        };
        if (this.autpOrderDetails.IsCouponApplied == 1) {
          this.autpCouponCode = this.autpOrderDetails.CouponCode
        }
      } else {
        this.autpOrderDetails = undefined;
      }

      if ("ivDetail" in cartDetail && "OrderItems" in cartDetail["ivDetail"]) {
        this.ivOrderDetails = cartDetail["ivDetail"];
        this.instantValForm = [];
        this.ivOrderDetails["OrderItems"].map(el => {
          this.instantValForm.push({
            Quantity: parseInt(el['Quantity']),
            Price: el["ItemPrice"],
            Product_Config_Id: el["ProductConfig"]["Product_Config_Id"],
            DraftOrderItemId: el["DraftOrderItemId"],
            TotalPrice: el["TotalPrice"],
            ItemPrice: el['ItemPrice']
          });
        });
        if (this.ivOrderDetails.IsCouponApplied == 1) {
          this.ivCouponCode = this.ivOrderDetails.CouponCode
        }
      } else {
        this.ivOrderDetails = undefined;
      }

      if ("priceMyTruckDetail" in cartDetail && "OrderItems" in cartDetail["priceMyTruckDetail"]) {
        this.priceMyTruckDetailOrderDetails = cartDetail["priceMyTruckDetail"];
        this.priceMyTruckForm = {
          Make: this.priceMyTruckDetailOrderDetails["OrderItems"]['Make'],
          TotalMakePrice: this.priceMyTruckDetailOrderDetails['OrderItems']['OrderItemConfigs']['TotalMakePrice'],
          MakePrice: parseInt(this.priceMyTruckDetailOrderDetails['ExtraConfig']['MakePrice']),
          MaxMake: parseInt(this.priceMyTruckDetailOrderDetails['ExtraConfig']['MaxMake']),
          DraftOrderItemId: parseInt(this.priceMyTruckDetailOrderDetails['OrderItems']['DraftOrderItemId']),
          ItemPrice: this.priceMyTruckDetailOrderDetails['OrderItems']['ItemPrice'],
          Product_Config_Id: parseInt(this.priceMyTruckDetailOrderDetails['OrderItems']['ProductConfig']['Product_Config_Id']),
          TotalPrice: parseInt(this.priceMyTruckDetailOrderDetails['OrderItems']['TotalPrice']),
          Name: this.priceMyTruckDetailOrderDetails['OrderItems']['ProductConfig']['Name'],
          User: parseInt(this.priceMyTruckDetailOrderDetails["OrderItems"]['TotalUsers']),
          MaxUser: parseInt(this.priceMyTruckDetailOrderDetails['ExtraConfig']['MaxUser']),
          UserPrice: parseInt(this.priceMyTruckDetailOrderDetails['ExtraConfig']['UserPrice']),
          TotalUserPrice: this.priceMyTruckDetailOrderDetails['OrderItems']['OrderItemConfigs']['TotalUserPrice']
        };
        if (this.priceMyTruckDetailOrderDetails.IsCouponApplied == 1) {
          this.priceMyTruckCouponCode = this.priceMyTruckDetailOrderDetails.CouponCode
        }
      } else {
        this.priceMyTruckDetailOrderDetails = undefined;
      }

      if (this.autpOrderDetails || this.ivOrderDetails || this.priceMyTruckDetailOrderDetails) {
        this.cartDetails = data;
        this.dataStore.setCart(data["CartCount"]);
      } else {
        this.dataStore.setCart(0);
      }
      console.log(this.cartDetails, 'cartDetails')
    }
  }

  chooseMakes(isPriceMyTruck: boolean = false): void {
    if (isPriceMyTruck == true) {
      const dialogRef = this.dialog.open(SelectMakesModalComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          autpForm: this.priceMyTruckForm,
          Makes: this.PriceMyTruckMakes
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.action == 'update' && this.priceMyTruckDetailOrderDetails) {
          this.addToCart(1, this.priceMyTruckDetailOrderDetails['Product_Id'], {
            User: this.priceMyTruckForm.User,
            Make: result.makeList,
            Price: this.priceMyTruckForm.ItemPrice,
            Product_Config_Id: this.priceMyTruckForm.Product_Config_Id,
            DraftOrderItemId: this.priceMyTruckForm.DraftOrderItemId
          })
        }
      })
    }
    else {
      const dialogRef = this.dialog.open(SelectMakesModalComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          autpForm: this.autpForm,
          Makes: this.Makes
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.action == 'update' && this.autpOrderDetails) {
          this.addToCart(1, this.autpOrderDetails['Product_Id'], {
            User: this.autpForm.User,
            Make: result.makeList,
            Price: this.autpForm.ItemPrice,
            Product_Config_Id: this.autpForm.Product_Config_Id,
            DraftOrderItemId: this.autpForm.DraftOrderItemId
          })
        }
      })
    }
  }

  toggleLoader(productId: number, toggle: number = null) {
    if (productId == this.appSettings.AutoPredict) {
      this.autpLoader = toggle
    } else if (productId == this.appSettings.InstantVal) {
      this.ivLoader = toggle
    } else if (productId == this.appSettings.PriceMyTruck) {
      this.truckLoader = toggle
    }
  }

  updateUser(action: 'add' | 'rmv', Product_Id = this.appSettings.AutoPredict) {
    let futureUser = 0;

    if (Product_Id == this.appSettings.AutoPredict) {
      if (action == 'add') {
        futureUser = this.autpForm.User + 1;
      } else if (action == 'rmv') {
        futureUser = this.autpForm.User - 1;
      }
      if (futureUser == 0) {
        this.commonService.showSnakeBar("Please choose at least one user.");
        return false;
      }
      if (futureUser > this.autpForm.MaxUser) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${this.autpForm.MaxUser} users.`
        );
        return false;
      }
      this.addToCart(this.appSettings.AutoPredict, this.autpOrderDetails['Product_Id'], {
        User: futureUser,
        Make: this.autpForm.Make,
        Price: this.autpForm.ItemPrice,
        Product_Config_Id: this.autpForm.Product_Config_Id,
        DraftOrderItemId: this.autpForm.DraftOrderItemId
      });
    } else if (Product_Id == this.appSettings.PriceMyTruck) {
      if (action == 'add') {
        futureUser = this.priceMyTruckForm.User + 1;
      } else if (action == 'rmv') {
        futureUser = this.priceMyTruckForm.User - 1;
      }
      if (futureUser == 0) {
        this.commonService.showSnakeBar("Please choose at least one user.");
        return false;
      }
      if (futureUser > this.priceMyTruckForm.MaxUser) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${this.priceMyTruckForm.MaxUser} users.`
        );
        return false;
      }
      this.addToCart(this.appSettings.PriceMyTruck, this.priceMyTruckDetailOrderDetails['Product_Id'], {
        User: futureUser,
        Price: this.priceMyTruckForm.ItemPrice,
        Product_Config_Id: this.priceMyTruckForm.Product_Config_Id,
        DraftOrderItemId: this.priceMyTruckForm.DraftOrderItemId,
        TotalPrice: this.priceMyTruckForm.TotalPrice,
        ItemPrice: this.priceMyTruckForm.ItemPrice,
      });
    }


  }


  updateQuantity(index, action: 'add' | 'rmv', itemModel: IvForm) {
    if (action == 'add') {
      let tempModel: IvForm = Object.assign(itemModel, {});
      tempModel.Quantity = this.instantValForm[index].Quantity + 1;
      this.addToCart(index, this.ivOrderDetails['Product_Id'], tempModel);
    } else if (action == 'rmv') {
      const futureVal = this.instantValForm[index].Quantity - 1;
      if (futureVal == 0 && this.instantValForm[index].DraftOrderItemId) {
        this.deleteCartItem(index, this.ivOrderDetails.Product_Id, this.instantValForm[index].DraftOrderItemId);
        return false;
      } else {
        let tempModel: IvForm = Object.assign(itemModel, {});
        tempModel.Quantity = futureVal;
        this.addToCart(index, this.ivOrderDetails['Product_Id'], tempModel);
      }
    }
  }

  addToCart(index: number, productId, itemModel: AutpForm | IvForm | PriceMyTruckForm) {
    let formData = {};
    productId = parseInt(productId);
    if (productId == this.appSettings.AutoPredict && this.autpForm) {
      const MaxMake = this.autpForm.MaxMake;
      const MaxUser = this.autpForm.MaxUser;
      const pUser = parseInt(itemModel["User"]);
      if (isNaN(pUser) || pUser == 0) {
        this.commonService.showSnakeBar("Please choose at least one user.");
        return false;
      }
      if (itemModel["User"] > MaxUser) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxUser} users.`
        );
        return false;
      }
      if (itemModel["Make"].length == 0) {
        this.commonService.showSnakeBar(`Please choose at least one make.`);
        return false;
      }
      if (itemModel["Make"].length > MaxMake) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxMake} make.`
        );
        return false;
      }
      formData = {
        User: itemModel["User"],
        Make: itemModel["Make"],
        id: itemModel["Product_Config_Id"],
        DraftOrderId: this.DraftOrderId,
        Product_Id: productId,
        DraftOrderItemId: itemModel.DraftOrderItemId,
        companyId: this.companyId
      };
    } else if (productId == this.appSettings.InstantVal) {
      formData = {
        Quantity: itemModel["Quantity"],
        id: itemModel["Product_Config_Id"],
        DraftOrderId: this.DraftOrderId,
        Product_Id: productId,
        DraftOrderItemId: itemModel.DraftOrderItemId,
        companyId: this.companyId
      };
    } else if (productId == this.appSettings.PriceMyTruck && this.priceMyTruckForm) {
      const MaxMake = this.priceMyTruckForm.MaxMake;
      const MaxUser = this.priceMyTruckForm.MaxUser;
      const pUser = parseInt(itemModel["User"]);
      if (isNaN(pUser) || pUser == 0) {
        this.commonService.showSnakeBar("Please choose at least one user.");
        return false;
      }
      if (itemModel["User"] > MaxUser) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxUser} users.`
        );
        return false;
      }
      if (itemModel["Make"].length == 0) {
        this.commonService.showSnakeBar(`Please choose at least one make.`);
        return false;
      }
      if (itemModel["Make"].length > MaxMake) {
        this.commonService.showSnakeBar(
          `You can not choose more than ${MaxMake} make.`
        );
        return false;
      }
      formData = {
        User: itemModel["User"],
        Make: itemModel["Make"],
        id: itemModel["Product_Config_Id"],
        DraftOrderId: this.DraftOrderId,
        Product_Id: productId,
        DraftOrderItemId: itemModel.DraftOrderItemId,
        companyId: this.companyId
      };
    }
    if (Object.keys(formData).length > 0) {
      this.toggleLoader(productId, index);
      this.productService.addUpdateCart(formData).subscribe(
        (res) => {
          this.toggleLoader(productId, null)
          //try {
          const response = res;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          }
          if (
            response.data &&
            Object.keys(response.data).length > 0
          ) {
            this.DraftOrderId = response.data['DraftOrderId'];
            this.setupProductItems(response.data);
            this.Makes = response.data['Makes'].split(',').map(el => String(el));
            this.PriceMyTruckMakes = response.data['PriceMyTruckMakes'];
            this.updatePaymentRequest();
          } else {
            this.cartDetails = undefined;
            this.DraftOrderId = null;
            this.autpOrderDetails = undefined;
            this.ivOrderDetails = undefined;
            this.dataStore.setCart(0);
          }

          // } catch (e) {
          //   console.log(e, 'err')
          //   this.commonService.commonSnakeBar();
          // }

        },
        (err) => {
          let response = err;
          this.toggleLoader(productId, null)
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          this.toggleLoader(productId, null)
        }
      );
    } else {
      this.commonService.showSnakeBar(
        "Invalid details. Please try again later."
      );
    }
  }


  deleteCartItem(index, productId, DraftOrderItemId) {
    let confirmRes = confirm('Are you sure you want to remove?');
    if (confirmRes === true) {
      if (!this.DraftOrderId) {
        this.commonService.showSnakeBar("Invalid details. Please try to refresh the page.")
        return false;
      }
      if (!DraftOrderItemId) {
        this.commonService.showSnakeBar("Invalid item. Please try again later.")
        return false;
      }
      this.toggleLoader(productId, index);
      this.productService.removeCartItem({
        DraftOrderId: this.DraftOrderId,
        DraftOrderItemId: DraftOrderItemId,
        companyId: this.companyId
      }).subscribe(
        (res) => {
          this.toggleLoader(productId, null);
          //try {
          const response = res;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          }
          if (
            response.data &&
            Object.keys(response.data).length > 0
          ) {
            this.DraftOrderId = response.data['DraftOrderId'];
            this.setupProductItems(response.data);
            this.Makes = response.data['Makes'].split(',').map(el => String(el));
            this.PriceMyTruckMakes = response.data['PriceMyTruckMakes'];
            this.updatePaymentRequest();
          } else {
            this.cartDetails = undefined;
            this.DraftOrderId = null;
            this.autpOrderDetails = undefined;
            this.ivOrderDetails = undefined;
            this.dataStore.setCart(0);
          }

          // } catch (e) {
          //   this.commonService.commonSnakeBar();
          // }
        },
        (err) => {
          this.toggleLoader(productId, null);
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          this.toggleLoader(productId, null);
        }
      );
    }
  }


  applyCoupon(Product_Id: number, action, code = null) {
    let DraftOrderProductId = null;
    let CouponCode = '';
    if (!code && action == 'add') {
      this.commonService.showSnakeBar('Please enter coupon code');
      return false;
    }

    if (action == 'add') {
      if (Product_Id == this.appSettings.AutoPredict) {
        this.autpCouponCode = code
      } else if (Product_Id == this.appSettings.InstantVal) {
        this.ivCouponCode = code
      } else if (Product_Id == this.appSettings.PriceMyTruck) {
        this.priceMyTruckCouponCode = code
      }
      CouponCode = code;
    } else if (action == 'rmv') {
      if (Product_Id == this.appSettings.AutoPredict) {
        if (!this.autpCouponCode) {
          this.commonService.showSnakeBar('No coupon is added. Please add a coupon first');
          return false;
        }
        this.autpCouponCode = null
      } else if (Product_Id == this.appSettings.InstantVal) {
        if (!this.ivCouponCode) {
          this.commonService.showSnakeBar('No coupon is added. Please add a coupon first');
          return false;
        }
        this.ivCouponCode = null
      } else if (Product_Id == this.appSettings.PriceMyTruck) {
        if (!this.priceMyTruckCouponCode) {
          this.commonService.showSnakeBar('No coupon is added. Please add a coupon first');
          return false;
        }
        this.priceMyTruckCouponCode = null
      }
    }
    if (Product_Id == this.appSettings.AutoPredict) {
      DraftOrderProductId = this.autpOrderDetails.DraftOrderProductId
    } else if (Product_Id == this.appSettings.InstantVal) {
      DraftOrderProductId = this.ivOrderDetails.DraftOrderProductId
    } else if (Product_Id == this.appSettings.PriceMyTruck) {
      DraftOrderProductId = this.priceMyTruckDetailOrderDetails.DraftOrderProductId
    }
    this.toggleLoader(Product_Id, -1);
    this.productService.applyCoupon({
      DraftOrderId: this.DraftOrderId,
      DraftOrderProductId: DraftOrderProductId,
      CouponCode: CouponCode,
      companyId: this.companyId
    }).subscribe(
      (res) => {
        this.toggleLoader(Product_Id, null);
        //try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        }
        if (
          response.data &&
          Object.keys(response.data).length > 0
        ) {
          this.DraftOrderId = response.data['DraftOrderId'];
          this.setupProductItems(response.data);
          this.Makes = response.data['Makes'].split(',').map(el => String(el));
          this.PriceMyTruckMakes = response.data['PriceMyTruckMakes'];
          this.updatePaymentRequest();
        } else {
          this.cartDetails = undefined;
          this.DraftOrderId = null;
          this.autpOrderDetails = undefined;
          this.ivOrderDetails = undefined;
          this.dataStore.setCart(0);
        }

        // } catch (e) {
        //   this.commonService.commonSnakeBar();
        // }
      },
      (err) => {
        this.toggleLoader(Product_Id, null);
        if (action == 'add') {
          if (Product_Id == this.appSettings.AutoPredict) {
            this.autpCouponCode = null
          }
          else if (Product_Id = this.appSettings.InstantVal) {
            this.ivCouponCode = null
          } else if (Product_Id = this.appSettings.PriceMyTruck) {
            this.priceMyTruckCouponCode = null
          }
        }
        let response = err;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.toggleLoader(Product_Id, null);
      }
    );
  }

  generateQuote() {
    //let confirmRes = confirm('Are you sure you want to generate a quote?');
    let confirmRes = true;
    if (confirmRes === true && this.DraftOrderId) {
      if (this.roleCheckService.isSuperAdmin() || this.paymentForm.valid) {
        this.quoteLoader = true
        this.quoteService.addQuote({
          id: this.DraftOrderId,
          companyId: this.companyId,
          ...this.paymentForm.value
        }).subscribe(
          (res) => {
            this.quoteLoader = false
            const response = res;
            if (
              response.data &&
              Object.keys(response.data).length > 0
            ) {
              if (this.roleCheckService.isSuperAdmin()) {
                this.router.navigate(['/quote-details', response.data['QuoteId']]);
              } else {
                this.router.navigate(['/my-quotes']);
              }
              this.dataStore.setCart(0);
            } else {
              this.commonService.commonSnakeBar();
            }
          },
          (err) => {
            this.quoteLoader = false
            let response = err;
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          },
          () => {

          }
        );
      } else {
        this.formValidate(this.paymentForm);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    const el: HTMLElement | null = document.getElementById("paymentForm");
    if (el) {
      setTimeout(() =>
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
    }
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }


  // Stripe payments
  stripeScriptId = 'stripe-custom-script';
  stripeKeyId = 'stripe-var-id'
  loadStripe() {
    if (!window.document.getElementById(this.stripeScriptId)) {
      var s = window.document.createElement("script");
      s.id = this.stripeScriptId;
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v3/";
      s.onload = () => {
        var scriptEl = document.createElement('script');
        scriptEl.id = this.stripeKeyId
        scriptEl.innerHTML = `window.stripeObj = Stripe('${environment.stripeKey}');
        window.stripeElements = window.stripeObj.elements();`
        document.body.appendChild(scriptEl);
        this.setupStripeCard();
        this.checkAppleGooglePaySupport();
      }
      window.document.body.appendChild(s);
    }
  }

  selMethod = 'card';
  isApplePay: boolean = false;
  isGooglePay: boolean = false;
  paymentError = null;
  paymentLoader: boolean = false;
  paymentRequest: any = null;
  userEmail: string = '';
  setPaymentType(key) {
    if (key == 'apple_pay') {
      this.paymentError = null
      if (!this.isApplePay) {
        alert(this.errorMsg('Apple'));
        return false
      }
    } else if (this.selMethod == 'google_pay') {
      this.paymentError = null
      if (!this.isGooglePay) {
        alert(this.errorMsg('Google'));
        return false
      }
    }
    this.selMethod = key
    // console.log(this.selMethod, 'method')
  }
  errorMsg(method) {
    return `Your device is not support ${method} Pay. Please choose another payment method.`
  }

  generatePayment(token: string = '') {
    if (this.DraftOrderId) {
      if (this.paymentForm.valid) {
        this.paymentLoader = true
        this.quoteService.addPayment({
          id: this.DraftOrderId,
          token: token,
          companyId: this.companyId,
          payMethod: this.selMethod,
          ...this.paymentForm.value
        }).subscribe(
          (res) => {
            this.paymentLoader = false
            const response = res;
            if (
              response.data &&
              Object.keys(response.data).length > 0
            ) {
              if (this.roleCheckService.isSuperAdmin()) {
                this.router.navigate(['/invoice-listing']);
              } else {
                this.router.navigate(['/my-payments']);
              }
              this.dataStore.setCart(0);
            } else {
              this.commonService.commonSnakeBar();
            }
          },
          (err) => {
            this.paymentLoader = false
            let response = err;
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          },
          () => {

          }
        );
      } else {
        this.formValidate(this.paymentForm);
      }
    }
  }

  toCents(value) {
    return Math.round(parseFloat(value) * 100)
  }

  checkAppleGooglePaySupport() {
    window['paymentRequestInstance'] = this.paymentRequest = window['stripeObj'].paymentRequest({
      country: 'AU',
      currency: 'aud',
      total: {
        label: `Web Portal Purchases`,
        amount: this.toCents(this.cartDetails.TotalPrice),
      },
      requestPayerName: true,
      requestPayerEmail: true
    });


    // Check the availability of the Payment Request API first.
    window['paymentRequestInstance'].canMakePayment().then((result) => {
      console.log(result, 'result')
      if (result) {
        this.isGooglePay = true
        if (result.applePay) {
          this.isApplePay = true
        } else {
          this.isApplePay = false
        }
        let payBtn = document.getElementById('appleGooglePayBtn');

      } else {
        this.isApplePay = false
        this.isGooglePay = false
      }
    });

    this.paymentRequest.on('token', (ev) => {
      console.log(ev, 'ev')
      this.zone.run(() => {
        this.generatePayment(ev.token.id)
      });
      // Must call this to finish the payment request
      ev.complete('success');
    });
    this.paymentRequest.on('cancel', (ev) => {
      this.zone.run(() => {
        this.commonService.showSnakeBar('We are unable to process your payment. Please try again later.');
      });
    })
  }

  updatePaymentRequest() {
    if (this.paymentRequest) {
      this.paymentRequest.update({
        total: {
          label: `Web Portal Purchases`,
          amount: this.toCents(this.cartDetails.TotalPrice),
        }
      });
    }
  }

  triggerAppleGooglePay() {
    this.paymentRequest.show()
  }

  async generateStripeToken() {
    let newFormData = this.paymentForm.value;
    let userData = this.paymentForm.value;

    let tokenData = {
      name: this.jwtService.getUserName(),
      address_line1: newFormData.billing_address,
      address_city: newFormData.city,
      address_state: newFormData.state
    }
    this.userEmail = userData.email
    const { token, error } = await window['stripeObj'].createToken(this.card, tokenData);
    return {
      'token': token,
      'error': error
    }
  }

  /*
  * @name handlePaymentForm
  * @param Form
  * @description handle payment form
  * @return none
  */
  async handlePaymentForm() {
    if (this.DraftOrderId) {
      if (this.paymentForm.valid) {
        let token = await this.processStripeToken();
        if (token != false) {
          this.generatePayment(token)
        }
      } else {
        this.formValidate(this.paymentForm);
      }
    } else {
      this.commonService.showSnakeBar('Invalid request')
    }
  }

  async processStripeToken(skipStripe: boolean = false, tokenId = '') {
    this.paymentLoader = true;
    this.userEmail = this.jwtService.getUserEmail()
    if (skipStripe == false) {
      const { token, error } = await this.generateStripeToken()
      if (error) {
        console.log(error, 'error')
        this.paymentLoader = false;
        if (!this.paymentError) {
          this.commonService.showSnakeBar('An error occurred while processing your payment.Please try again later.');
        }
        return false;
      }
      tokenId = token.id
    }
    if (!tokenId) {
      this.commonService.showSnakeBar('Invalid payment source.Please try with valid payment method.');
      return false;
    }
    return tokenId;
  }

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  onChange({ error }) {
    if (error) {
      this.paymentError = error.message;
    } else {
      this.paymentError = null;
    }
    //console.log('loader', this.loader)
    this.cd.detectChanges();
  }

  setupStripeCard() {
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    if (typeof window['stripeElements'] != undefined) {
      this.card = window['stripeElements'].create('card', { hidePostalCode: true, style: style, });
      this.card.mount(this.cardInfo && this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
    }
  }

  ngOnDestroy(): void {
    if (window.document.getElementById(this.stripeScriptId)) {
      window.document.getElementById(this.stripeScriptId).remove();
    }
    if (window.document.getElementById(this.stripeKeyId)) {
      window.document.getElementById(this.stripeKeyId).remove();
    }
    if (this.card) {
      this.card.destroy();
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { JwtService } from 'src/app/services/jwt.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'single-user',
  templateUrl: './single-user.component.html',
  styleUrls: ['./single-user.component.scss']
})

export class SingleUserComponent implements OnInit {

  @Input() User: any;
  @Input() companyId: number;
  public isProcessing: boolean = false;
  public isInvtProcessing: boolean = false;
  constructor(
    public dialog: MatDialog,
    public jwtService: JwtService,
    public dataStore: DataStoreService,
    public userService: UserService,
    public commonService: CommonService,
    public roleCheckService: RoleCheckerService
  ) { }

  ngOnInit() {
    //console.log(this.User);
  }

  editUser(obj) {
    const dialogRef = this.dialog.open(InviteUserComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        companyId: this.companyId,
        isEdit: true,
        user: obj
      }
    });
    dialogRef.componentInstance.onInviteUpdate.subscribe(() => {
      this.dataStore.sendUserListSignal();
    })
  }

  openConfirmPopUp(user, action = 'delete') {
    let constMsg = 'Are you sure you want to';
    let dialogTitle = `${constMsg} delete user?`;
    if (action == 'resend') {
      dialogTitle = `${constMsg} resend invite?`;
    }
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: dialogTitle
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        if (action == 'delete') {
          this.deleteUser(user);
        } else if (action == 'resend') {
          this.resendInvite(user);
        }
      }
    });
  }

  resendInvite(obj) {
    this.isInvtProcessing = true;
    this.userService.resendInvite({ userId: obj.User_Id }).subscribe(res => {
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
          if (obj.User_Status == 0 && obj.InTokenStatus == 1) {
            this.User['InTokenStatus'] = 0;
          }
          //this.dataStore.sendUserListSignal();
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isInvtProcessing = false;
    }, (err) => {
      let response = err;
      this.isInvtProcessing = false;
      if (response.errors && Object.keys(response.errors).length > 0) {
        this.formatErrors(response.errors)
      }
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isInvtProcessing = false;
    })
  }

  deleteUser(obj) {
    this.isProcessing = true;
    this.userService.deleteUser({ userId: obj.User_Id, companyId: this.companyId }).subscribe(res => {
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
          this.dataStore.sendUserListSignal();
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.errors && Object.keys(response.errors).length > 0) {
        this.formatErrors(response.errors)
      }
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  checkIfAUTP(p_ids) {
    if (p_ids) {
      let pArr = p_ids.split(',').map(Number)
      //console.log(pArr, pArr.indexOf(1))
      if (pArr.indexOf(1) != -1) return true;
    }
    return false
  }
}

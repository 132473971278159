import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { MaterialComponentsModule } from "./../material-components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { ApCommonModule } from "../ap-common/ap-common.module";

// Page heading Component
import { PageTitleComponent } from "./page-title/page-title.component";

import { ConfirmationPopupComponent } from "./confirmation-popup/confirmation-popup.component";

import { TextFormatComponent } from "./text-format/text-format.component";
import { NoRecordFoundComponent } from "./no-record-found/no-record-found.component";
import { SmallLoaderComponent } from "./small-loader/small-loader.component";
import { LoaderComponent } from "./loader/loader.component";

import { VeLoaderComponent } from "./ve-loader/ve-loader.component";
import { PopupLoaderComponent } from "./popup-loader/popup-loader.component";
import { VehicleListLoadingComponent } from "./vehicle-list-loading/vehicle-list-loading.component";
import { FooterTextComponent } from "./footer-text/footer-text.component";
import { OnlyIntegerDirective } from "../directives/only-integer.directive";
import { ToNumberPipe } from "../pipes/tonumber.pipe";
import { DateHelper } from "./helpers/date-helper";

@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ApCommonModule,
    ],
    declarations: [
        PageTitleComponent,
        ConfirmationPopupComponent,
        TextFormatComponent,
        NoRecordFoundComponent,
        SmallLoaderComponent,
        LoaderComponent,
        VeLoaderComponent,
        PopupLoaderComponent,
        VehicleListLoadingComponent,
        FooterTextComponent,
        OnlyIntegerDirective,
        //SvgIconComponent
        ToNumberPipe
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        PageTitleComponent,
        DatePipe,
        TextFormatComponent,
        NoRecordFoundComponent,
        SmallLoaderComponent,
        LoaderComponent,
        VehicleListLoadingComponent,
        FooterTextComponent,
        OnlyIntegerDirective,
        //SvgIconComponent
        ToNumberPipe
    ],
    providers: [
        DatePipe,
        DateHelper
    ]
})
export class SharedModule { }

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    public appSettings: AppSettings,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    this.commonService.setTitle('Privacy Policy');
    this.renderer.addClass(document.body, 'outer-pages');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'outer-pages');
  }

}

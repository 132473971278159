import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { JwtService } from 'src/app/services/jwt.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { userRoles } from 'src/app/models/user-roles.model';
import { productsEnum } from 'src/app/models/products.model';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { AppSettings } from 'src/app/configs/app-settings';

export interface PeriodicElement {
  name: string;
  email: string;
  phone: string;
  active_subscriptions: string;
  invite: string;
  role: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { name: 'Daniel F. Anderson', email: 'danielfanderson@dayrep.com', phone: '3017466962', active_subscriptions: 'H', invite: 'Sent', role: 'Admin' },
  { name: 'Daniel F. Anderson', email: 'danielfanderson@dayrep.com', phone: '3017466962', active_subscriptions: 'H', invite: 'Resend', role: 'Read & Write' }
];

type company = {
  CompanyName: string;
  Thumbnail: string;
}

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})

export class ManageUsersComponent implements OnInit {

  public pagingParams: any;
  public getUserListSignal: Subscription;
  public isProcessing: boolean = false;
  public userList: any[] = [];
  public isPaging: boolean = false;
  public totalRecords: number = 0;
  public totalAllowed: number = 0;
  public noUsers: number = 0;
  public listCompleted: boolean = false;
  noMoreRec = false
  sub_status: number
  SubscriptionsDetails = null
  companyId = null;
  isLoading = false;

  public companyDetails = {} as company;
  searchedName: string = '';
  enteredEmail: string = '';
  selectedProduct = '';
  isEmptyResult = false;
  loggedInUserId: number | null = null;
  companyOwnerCount: number = 0;
  companyAdminsCount: number = 0;
  companyUsersCount: number = 0;

  public displayedColumns: string[] = ['name', 'jobTitle', 'email', 'phone', 'active_subscriptions', 'invite', 'role', 'autopredict_permissions', 'action'];
  public isInvtProcessing: boolean = false;
  public userRoles = userRoles;
  public companyProducts = [];
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    public commonService: CommonService,
    private dataStore: DataStoreService,
    private jwtService: JwtService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public roleCheckService: RoleCheckerService,
    private subscriptionService: SubscriptionService,
    public appSettings: AppSettings
  ) {
    this.companyProducts = this.appSettings.companyProducts;
    this.loggedInUserId = this.jwtService.getUserId();
    const userDetails = this.jwtService.getUserDetails();
    if (this.roleCheckService.isSuperAdmin()) {
      this.companyId = this.activatedRoute.parent.snapshot.paramMap.get("id");
      if (!this.companyId) {
        this.sendToCompany()
      }
      this.getCompanyDetails();
    } else {
      this.companyId = this.jwtService.getCompanyId();
      this.companyDetails.CompanyName = userDetails.Company_Name;
      this.companyDetails.Thumbnail = userDetails.Company_Photo !== null ? "thumb" + userDetails.Company_Photo : null;
    }
    this.getUserListSignal = this.dataStore.getUserListSignal().subscribe(res => {
      this.pagingParams = this.getPagingParams();
      this.userList = [];
      this.listCompleted = false;
      this.getUserListing(false);
    })
  }

  ngOnInit() {
    this.pagingParams = this.getPagingParams();
    this.getUserListing(false);
  }

  ngOnDestroy() {
    if (this.getUserListSignal) {
      this.getUserListSignal.unsubscribe();
    }
  }

  isSearch: boolean = false;
  searchUsers() {
    this.isSearch = true;
    this.isEmptyResult = false;
    this.userList = [];
    this.pagingParams = this.getPagingParams();
    this.getUserListing(false);
  }

  getCompanyDetails() {
    this.isLoading = true;
    this.subscriptionService
      .getCompanyDetails({ companyId: this.companyId })
      .subscribe(
        result => {
          this.isLoading = false;
          try {
            let response = result;
            if (!response.data) {
              this.sendToCompany()
            }
            this.companyDetails = response.data;

          } catch (e) { }
        },
        error => {
          //console.log(error)
          this.isLoading = false;
          let response = error;

          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isLoading = false;
        },
        () => { }
      );
  }

  sendToCompany() {
    this.commonService.showSnakeBar(
      "Unable to find the requested company."
    );
    this.router.navigate(["/companies"]);
  }


  inviteNewUser(): void {
    const dialogRef = this.dialog.open(InviteUserComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        companyId: this.companyId,
        isEdit: false
      }
    });
    dialogRef.componentInstance.onInviteSubmit.subscribe(() => {
      this.dataStore.sendUserListSignal();
    })
  }

  getPagingParams() {
    return {
      page: 1,
      limit: 20,
      sort: 'asc',
      companyId: this.companyId
    }
  }

  getUserListing(isForPaging) {
    this.toggleLoader(isForPaging);
    this.pagingParams = {
      ...this.pagingParams,
      ...{
        name: this.searchedName ? this.searchedName : '',
        email: this.enteredEmail ? this.enteredEmail : '',
        product: this.selectedProduct ? this.selectedProduct : ''
      }
    }
    this.userService.getCompanyUserListing(this.pagingParams).subscribe(res => {
      try {
        const response = res;
        if (this.pagingParams['page'] == 1) {
          this.totalRecords = response.data.total;
          this.totalAllowed = response.data.totalAllowed;
          this.noUsers = response.data.noUsers;
          this.sub_status = response.data.sub_status
          this.SubscriptionsDetails = response.data.SubscriptionsDetails
        }
        this.appendCompanyListing(response.data.records);
        //console.log(response.data, '---prin the total records here');
      }
      catch (e) {
        console.log(e, 'e');
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    })
  }

  toggleLoader(isForPaging) {
    if (isForPaging) {
      this.isPaging = !this.isPaging;
    }
    else {
      this.isProcessing = !this.isProcessing;
    }
  }

  loadMore() {
    if (this.isProcessing === true || this.isPaging === true || this.isEmptyResult === true) {
      console.log('here', this.isEmptyResult)
      return false;
    }
    this.pagingParams.page++;
    this.getUserListing(true);
  }

  appendCompanyListing(newList = []) {
    this.companyOwnerCount = 0;
    this.companyAdminsCount = 0;
    this.companyUsersCount = 0;
    if (newList.length > 0) {
      let newState = [];
      if (this.userList.length > 0) {
        newState = this.userList.slice();
      }
      newList.forEach(element => {
        if (element.Role_Id === this.userRoles.companyOwner) {
          this.companyOwnerCount += 1;
        } else if (element.Role_Id === this.userRoles.companyAdmin) {
          this.companyAdminsCount += 1;
        } else {
          this.companyUsersCount += 1;
        }
        let allowedProducts = [];
        let isApSubscribed: boolean = false;
        if (element.Product_DisplayTitle) {
          const products = element.ProductName.split(",");
          element.Term_Condition_Signed.forEach(terms => {
            products.forEach(prod => {
              if (prod === productsEnum.autoPredict) {
                isApSubscribed = true;
              }
              if (terms.Name === prod) {
                allowedProducts.push(terms);
              }
            });
          });
        }
        if (!isApSubscribed) {
          this.displayedColumns = this.displayedColumns.filter(col => col !== "autopredict_permissions");
        }
        element.allowedProduct = allowedProducts;
        newState.push(element);
      });
      this.userList = newState;
      if (this.userList.length == this.totalRecords) {
        this.listCompleted = true;
      }
    } else {
      this.isEmptyResult = true;
    }
  }

  editUser(obj) {
    const dialogRef = this.dialog.open(InviteUserComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        companyId: this.companyId,
        isEdit: true,
        user: obj,
        showRolesDropdown: (this.loggedInUserId !== obj.User_Id ? true : false)
      }
    });
    dialogRef.componentInstance.onInviteUpdate.subscribe(() => {
      this.dataStore.sendUserListSignal();
    });
  }

  openConfirmPopUp(user, action = 'delete') {
    let constMsg = 'Are you sure you want to';
    let dialogTitle = `${constMsg} delete user?`;
    if (action == 'resend') {
      dialogTitle = `${constMsg} resend invite?`;
    }
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: dialogTitle
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        if (action == 'delete') {
          this.deleteUser(user);
        } else if (action == 'resend') {
          this.resendInvite(user);
        }
      }
    });
  }

  deleteUser(obj) {
    this.isProcessing = true;
    this.userService.deleteUser({ userId: obj.User_Id, companyId: this.companyId }).subscribe(res => {
      try {
        const response = res;
        if (response.status === httpStatusCodes.success) {
          this.commonService.showSnakeBar(response.message);
          setTimeout(() => {
            this.dataStore.sendUserListSignal();
          }, 100);
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.errors && Object.keys(response.errors).length > 0) {
        this.formatErrors(response.errors)
      }
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  resendInvite(obj) {
    this.isInvtProcessing = true;
    this.userService.resendInvite({ userId: obj.User_Id }).subscribe(res => {
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
          if (obj.User_Status == 0 && obj.InTokenStatus == 1) {
            //this.User['InTokenStatus'] = 0;
          }
          //this.dataStore.sendUserListSignal();
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isInvtProcessing = false;
    }, (err) => {
      let response = err;
      this.isInvtProcessing = false;
      if (response.errors && Object.keys(response.errors).length > 0) {
        this.formatErrors(response.errors)
      }
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isInvtProcessing = false;
    })
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }


}

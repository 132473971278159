import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/services/common.service';
import { AddCouponComponent } from '../add-coupon/add-coupon.component';
import { CouponService } from 'src/app/services/coupon.service';


@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})

export class CouponsComponent implements OnInit {

  displayedColumns: string[] = ['Name', 'Code', 'Type', 'Value', 'Product_Name', 'IsActive'];
  products = [];
  processing: boolean = false;
  coupons = new MatTableDataSource([]);

  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private couponService: CouponService,
  ) { }

  ngOnInit() {
    this.commonService.setTitle('Coupons');
    this.getAllCoupons();
  }

  applyFilter(filterValue: string) {
    this.coupons.filter = filterValue.trim().toLowerCase();
  }

  addNewCoupon(): void {
    const dialogRef = this.dialog.open(AddCouponComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        products: this.products
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllCoupons();
      }
    })
  }


  getAllCoupons() {
    this.processing = true
    this.couponService.getCouponListing().subscribe(res => {
      this.processing = false
      try {
        const response = res;
        this.coupons.data = response.data['coupons'];
        this.coupons.data = this.coupons.data.slice()
        this.products = response.data['products']
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.processing = true
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  CouponId = null
  toggleCouponStatus(CouponId, $event: MatSlideToggleChange) {
    this.CouponId = CouponId
    this.couponService.couponStatus({
      id: CouponId,
      active: $event.checked
    }).subscribe(res => {
      this.CouponId = null
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message)
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.CouponId = null
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }
}

import { Injectable } from '@angular/core';
import { ApiUrls } from "../configs/api-urls";
import { CommonHttpService } from '../services/common-http.service';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }


  /*
  * @name quoteListing
  * @param
  * @description get all quote listing
  * @return Observable
  */
  quoteListing(data) {
    return this.commonHttpService.post(this.apiUrls.quote.listing, data);
  }


  /*
  * @name paymentListing
  * @param
  * @description get all payment listing
  * @return Observable
  */
  paymentListing(data) {
    return this.commonHttpService.post(this.apiUrls.payment.listing, data);
  }

  /*
  * @name paymentDownload
  * @param
  * @description download payment invoice
  * @return Observable
  */
  paymentDownload(data) {
    return this.commonHttpService.post(this.apiUrls.payment.download, data);
  }

  /*
  * @name addQuote
  * @param
  * @description Add quote
  * @return Observable
  */
  addQuote(data) {
    return this.commonHttpService.post(this.apiUrls.quote.add, data);
  }

  /*
  * @name addPayment
  * @param
  * @description Create payment
  * @return Observable
  */
  addPayment(data) {
    return this.commonHttpService.post(this.apiUrls.quote.payment, data);
  }

  /*
  * @name quoteQuoteDetails
  * @param
  * @description Get quote details
  * @return Observable
  */
  quoteQuoteDetails(id) {
    return this.commonHttpService.post(this.apiUrls.quote.quoteDetail, {
      id: id
    });
  }

  /*
  * @name sendInvoiceDetail
  * @param
  * @description Send invoice details to email
  * @return Observable
  */
  sendInvoiceDetail(data) {
    return this.commonHttpService.postAttachment(this.apiUrls.quote.quoteSend, data);
  }

  /*
  * @name sendInvoiceDetail
  * @param
  * @description Send invoice details to email
  * @return Observable
  */
  acceptRejectQuote(data) {
    return this.commonHttpService.post(this.apiUrls.quote.toggleQuote, data);
  }

  /*
  * @name getListingFilters
  * @param
  * @description get invoice and quote filters
  * @return Observable
  */
  getListingFilters() {
    return this.commonHttpService.get(this.apiUrls.quote.filters);
  }
}

/*
* @ngdoc Module
* @name app.dashboard
* @description
* This module is used to handle all the features related to dashboard.
* */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { ApCommonModule } from '../ap-common/ap-common.module';
import { MaterialComponentsModule } from '../material-components.module';

import { CommonService } from '../services/common.service';

import { UsageWrapperComponent } from './usage/usage-wrapper.component';
import { TermSignComponent } from './term-sign/term-sign.component';
import { BillingAddressComponent } from './billing-address/billing-address.component';
import { BackupTableComponent } from '../backup-table/backup-table.component';
import { UsageDetailsComponent } from './usage-details/usage-details.component';
import { PhoneVerificationModalComponent } from './phone-verification-modal/phone-verification-modal.component';
import { VerificationModule } from '../ap-common/verification-module/verification.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AssetInfoUsageComponent } from './asset-info-usage/asset-info-usage.component';
import { SalvagevalUsageComponent } from './salvageval-usage/salvageval-usage.component';
import { ProductIconsComponent } from './product-icons/product-icons.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        SharedModule,
        RouterModule,
        ApCommonModule,
        VerificationModule
    ],
    declarations: [
        DashboardComponent,
        UsageWrapperComponent,
        TermSignComponent,
        BillingAddressComponent,
        BackupTableComponent,
        UsageDetailsComponent,
        PhoneVerificationModalComponent,
        AssetInfoUsageComponent,
        SalvagevalUsageComponent,
        ProductIconsComponent
    ],
    providers: [
        CommonService
    ],
    exports: [
        ProductIconsComponent
    ]
})
export class DashboardModule { }

export const environment = {
  production: true,
  //apiUrl: 'https://aea-autopredappbe-webapp-dev-1.azurewebsites.net/api/',
  apiUrl: 'https://autopredict.api-dev.env.datiuminsights.com.au/autp/api/',//'https://api-dev.env.autopredict.pvsdf.com.au/autp/api/',
  frontEndUrl: "https://portal-dev.env.datiuminsights.com.au/",
  script: false,
  datiumWebsite: 'https://web-dev.env.datiuminsights.com.au/',
  gCaptchaKey: '6Ld7y7kaAAAAAOq6755T4svCqAOAJSDICFEwAWB_',
  stripeKey: 'pk_test_51HFaCkFUzPfCWHSHKuqYnC6nc4btJJF65NSgb2fVUpkJK7rh2w447Qicq73R8MrByheMg8X7CGUBwG2QorOy0ISM00fB4ByVCn',
  name: 'dev',
  supportEmail: 'support@datiuminsights.com.au',
  bikevalUrl: 'https://bikeval-dev.env.datiuminsights.com.au/',
  carvalUrl: 'https://instantval-dev.env.datiuminsights.com.au/',
  truckvalUrl: 'https://truckval-dev.env.datiuminsights.com.au/',
  priceMyTruckUrl: 'https://pricemytruck-dev.env.datiuminsights.com.au/',
  autopredictUrl: 'https://autopredict-dev.env.datiuminsights.com.au/',
  salvagevalUrl: 'https://salvageval-dev.env.datiuminsights.com.au/',
  assessvalUrl: 'https://assessval-dev.env.datiuminsights.com.au/'
};

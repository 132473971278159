/*
  *
  * @ngdoc Services
  * @name app.services.ProductService
  * @description
  * The ProductService Service is used for handling the request product to User
  *
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }

  /*
  * @name getProductListing
  * @param
  * @description get all product listing
  * @return Observable
  */
  getProductListing(data) {
    return this.commonHttpService.get(this.apiUrls.products.productListing, data);
  }

  /*
  * @name getProductListingByCompany
  * @param
  * @description get all product listing by company
  * @return Observable
  */
  getProductListingByCompany(params = {}) {
    return this.commonHttpService.get(this.apiUrls.products.getProductListByCompany, params);
  }

  /*
  * @name getProductListingByCompany
  * @param
  * @description get all product listing by company
  * @return Observable
  */
  contactUs(params) {
    return this.commonHttpService.post(this.apiUrls.products.contactUs, params);
  }

  /*
  * @name usageData
  * @param
  * @description method for getting the usage data
  * @return Observable
  */
  usageData(params) {
    return this.commonHttpService.post(this.apiUrls.products.usage, params);
  }

  /*
  * @name usageCountData
  * @param
  * @description Get usage count
  * @return Observable
  */
  usageCountData(params) {
    return this.commonHttpService.post(this.apiUrls.products.usageCount, params);
  }

  /*
  * @name usageInterval
  * @param
  * @description method for getting the usage intervals
  * @return Observable
  */
  usageInterval() {
    return this.commonHttpService.post(this.apiUrls.products.usageIntervals, {});
  }

  /* @name createTrialSub
  * @param
  * @description create trial subscription
  * @return Observable
  */
  createTrialSub(params) {
    return this.commonHttpService.post(this.apiUrls.products.trial, params);
  }

  getSubCustomer() {
    return this.commonHttpService.get(this.apiUrls.user.subUser, {});
  }


  saveSubCustomer(formData) {
    return this.commonHttpService.post(this.apiUrls.user.updateSub, formData);
  }


  /*
  * @name getAdminProductListing
  * @param
  * @description get admin all product listing
  * @return Observable
  */
  getAdminProductListing() {
    return this.commonHttpService.get(this.apiUrls.admin.productList);
  }

  /*
  * @name getAdminProductDetail
  * @param
  * @description get admin product detail
  * @return Observable
  */
  getAdminProductDetail(data, isAdmin: boolean = true) {
    return this.commonHttpService.post(isAdmin ? this.apiUrls.admin.adminProductDetail : this.apiUrls.admin.productDetail, data);
  }

  /*
  * @name updateProductInfo
  * @param
  * @description update product details
  * @return Observable
  */
  updateProductInfo(formData: any) {
    return this.commonHttpService.post(this.apiUrls.admin.updateProductDetail, formData);
  }

  /*
  * @name updateProductInfo
  * @param
  * @description update product details
  * @return Observable
  */
  getAdminProductConfigs(formData: any, isAdmin: boolean = true) {
    return this.commonHttpService.post(isAdmin ? this.apiUrls.admin.adminProductConfigDetail : this.apiUrls.admin.productConfigDetail, formData);
  }

  /*
  * @name prdConfigStatus
  * @param
  * @description update product config status
  * @return Observable
  */
  prdConfigStatus(formData: any) {
    return this.commonHttpService.post(this.apiUrls.admin.productConfigStatus, formData);
  }

  /*
  * @name addConfig
  * @param
  * @description add configuration to product
  * @return Observable
  */
  addConfig(formData: any) {
    return this.commonHttpService.post(this.apiUrls.admin.addConfig, formData);
  }

  /*
  * @name addToCart
  * @param
  * @description add product to cart
  * @return Observable
  */
  addUpdateCart(formData: any) {
    return this.commonHttpService.post(this.apiUrls.admin.addCart, formData);
  }

  /*
  * @name getCartCount
  * @param
  * @description Get cart counter
  * @return Observable
  */
  getCartCount(params = {}) {
    return this.commonHttpService.get(this.apiUrls.admin.cartCount, params);
  }

  /*
 * @name getCartDetails
 * @param
 * @description Get cart details
 * @return Observable
 */
  getCartDetails(params = {}) {
    return this.commonHttpService.get(this.apiUrls.admin.cartDetails, params);
  }


  /*
  * @name removeCartItem
  * @param
  * @description Remove cart items
  * @return Observable
  */
  removeCartItem(formData) {
    return this.commonHttpService.post(this.apiUrls.admin.removeCartItem, formData);
  }

  /*
  * @name makeQuote
  * @param
  * @description Generate a quote
  * @return Observable
  */
  makeQuote(formData) {
    return this.commonHttpService.post(this.apiUrls.admin.addQuote, formData);
  }

  /*
  * @name applyCoupon
  * @param
  * @description Apply coupon
  * @return Observable
  */
  applyCoupon(formData) {
    return this.commonHttpService.post(this.apiUrls.admin.applyCoupon, formData)
  }

  /*
  * @name usageDetails
  * @param
  * @description method for getting the usage details
  * @return Observable
  */
  usageDetails(params) {
    return this.commonHttpService.post(this.apiUrls.products.usageDetails, params);
  }

  /*
    * @name usageMakeModel
    * @param
    * @description method for getting the usage Make Model
    * @return Observable
    */
  usageMakeModel(params) {
    return this.commonHttpService.post(this.apiUrls.products.usageMakeModel, params);
  }

  /*
  * @name assetInfoUsageDetails
  * @param
  * @description method for getting the asset info usage filters data
  * @return Observable
  */
  assetInfoUsageDetails(params) {

    return this.commonHttpService.post(this.apiUrls.products.assetInfoUsageDetails, params);
  }

  /*
  * @name assetInfoUsageDetailsFile
  * @param
  * @description method for downloading the asset info usage details
  * @return Observable
  */
  assetInfoUsageDetailsFile(params) {
    return this.commonHttpService.postAttachment(this.apiUrls.products.assetInfoUsageDetails, params);
  }


  /*
    * @name salvageValUsageMakeModel
    * @param
    * @description method for getting th salvage val usage Make Model
    * @return Observable
    */
  salvageValUsageMakeModel(params) {
    return this.commonHttpService.post(this.apiUrls.products.salvageValUsageMakeModel, params);
  }

  /*
  * @name salvagValUsageDetails
  * @param
  * @description method for getting the asset info usage filters data
  * @return Observable
  */
  salvageValUsageDetails(params) {

    return this.commonHttpService.post(this.apiUrls.products.salvageValUsageDetails, params);
  }

  /*
  * @name salvagValUsageDetailsFile
  * @param
  * @description method for downloading the asset info usage details
  * @return Observable
  */
  salvagValUsageDetailsFile(params) {
    return this.commonHttpService.postAttachment(this.apiUrls.products.salvageValUsageDetails, params);
  }

}

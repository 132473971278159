import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appOnlyInteger]",
})
export class OnlyIntegerDirective {
  private el: HTMLInputElement;
  private value: any;
  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }
  @HostListener("focus", ["$event.target.value"])
  onFocus() {
    this.el.value = this.value;
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    this.transform(value);
  }
  ngOnInit() {
    this.transform(this.el.value);
  }
  transform(value: any) {
    this.value = value;
    if (value && !isNaN(value)) {
      this.value = Math.floor(Number(this.el.value));
    } else {
      this.value = null;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public changePassword: UntypedFormGroup;
  public isProcessing: boolean = false;
  public formError: any = {};
  constructor(
    private commonService: CommonService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm()
  }


  initForm() {
    this.changePassword = new UntypedFormGroup({
      'oldPassword': new UntypedFormControl(null, [
        Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
      ]),
      'newPassword': new UntypedFormControl(null, [
        Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
      ]),
      'confirmPassword': new UntypedFormControl(null, [
        Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
      ])
    });
  }

  submit(form) {
    if (form.valid) {
      if (form.value.newPassword === form.value.confirmPassword) {
        if (!(form.value.oldPassword === form.value.newPassword)) {
          this.isProcessing = true;
          this.formError = {};
          this.userService.changePassword(form.value).subscribe(res => {
            try {
              const response = res;
              if (response.message) {
                this.commonService.showSnakeBar(response.message)
              }
            }
            catch (e) {
              this.commonService.commonSnakeBar();
            }
            this.isProcessing = false;
            this.initForm();
          }, (err) => {
            let response = err;
            this.isProcessing = false;
            this.initForm();
            if (response.message) {
              if ('errors' in response.errors) {
                this.handleServerFormError(response.errors)
              }
              this.commonService.showSnakeBar(response.message)
            }
            else {
              this.commonService.commonSnakeBar();
            }
          }, () => {
            this.isProcessing = false;
            this.initForm();
          })
        }
        else {
          this.commonService.showSnakeBar("New password should not be same as old password.")
        }
      }
      else {
        this.commonService.showSnakeBar("New password & Confirm password should be identical")
      }
    }
    else {
      this.formValidate(form);
    }
  }

  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors                  
* @return none
*/
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.changePassword.contains(key)) {
          let control = this.changePassword.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-products-listing',
  templateUrl: './products-listing.component.html',
  styleUrls: ['./products-listing.component.scss']
})
export class ProductsListingComponent implements OnInit {

  displayedColumns: string[] = ['DisplayTitle', 'Status', 'ProductPrice', 'ConfigName', 'actions'];
  processing: boolean = false;
  products = new MatTableDataSource([]);

  constructor(
    private commonService: CommonService,
    private productService: ProductService,
  ) { }

  ngOnInit() {
    this.commonService.setTitle('Products');
    this.getAllProducts();
  }


  getAllProducts() {
    this.processing = true
    this.productService.getAdminProductListing().subscribe(res => {
      this.processing = false
      try {
        const response = res;
        this.products.data = response.data;
        this.products.data = this.products.data.slice();
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.processing = true
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

}

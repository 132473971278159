// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page-wrapper {
  padding: 30px 0;
}
.login-page-wrapper .login-form-box {
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  margin: 2rem auto;
  max-width: 500px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}
.login-page-wrapper .login-bg-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  z-index: -1;
}
.login-page-wrapper .mat-flat-button {
  width: 100%;
  max-width: 250px;
}
.login-page-wrapper .form-wrapper h2 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.login-page-wrapper .form-wrapper h4 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
  .login-page-wrapper {
    padding-top: 60px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/user-auto/forgot-password/forgot-password.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAAI;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,2CAAA;EACA,kBAAA;EACA,WAAA;AAEN;AACI;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,gBAAA;EACA,WAAA;AACN;AAEI;EACE,WAAA;EACA,gBAAA;AAAN;AAIM;EACE,iBAAA;EACA,qBAAA;EACA,gBAAA;AAFR;AAIM;EACE,eAAA;EACA,qBAAA;AAFR;;AAQA;EACE;IACE,iBAAA;EALF;AACF","sourcesContent":[".login-page-wrapper {\n    padding:30px 0;\n    .login-form-box{\n      padding: 2rem;\n      background: #fff;\n      border-radius: 10px;\n      margin: 2rem auto;\n      max-width: 500px;\n      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);\n      position: relative;\n      z-index: 10;\n    }\n  \n    .login-bg-icon{\n      position: absolute;\n      bottom: 0;\n      left: 0;\n      width:100%;\n      max-width: 400px;\n      z-index: -1;\n    }\n\n    .mat-flat-button{\n      width: 100%;\n      max-width:250px;\n    }\n\n    .form-wrapper{\n      h2{\n        font-size: 1.4rem;\n        margin-bottom: 0.5rem;\n        font-weight: 600;\n      }\n      h4{\n        font-size: 1rem;\n        margin-bottom: 0.5rem;\n      }\n    }\n\n}\n\n@media screen and (max-width: 768px) {\n  .login-page-wrapper{\n    padding-top:60px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

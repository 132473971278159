// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-to-home {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  right: 20px;
  top: 20px;
}
.back-to-home svg {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/user-auto/back-button/back-button.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AACJ;AAAI;EACE,iBAAA;AAEN","sourcesContent":[".back-to-home{\n    display: flex;\n    align-items: center;\n    text-decoration: none;\n    position: absolute;\n    right:20px;\n    top:20px;\n    svg{\n      margin-right:8px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

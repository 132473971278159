import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';



import { MaterialComponentsModule } from '../material-components.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';


import { ConfirmationPopupComponent } from '../shared/confirmation-popup/confirmation-popup.component';

import { MyAccountWrapperComponent } from './my-account-wrapper/my-account-wrapper.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SingleUserComponent } from './single-user/single-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { ApCommonModule } from '../ap-common/ap-common.module';
import { UpdateSubUserComponent } from './update-sub-user/update-sub-user.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UpdateMobileComponent } from './update-mobile/update-mobile.component';
import { VerificationModule } from '../ap-common/verification-module/verification.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        FormsModule,
        SharedModule,
        ApCommonModule,
        InfiniteScrollModule,
        VerificationModule
    ],
    declarations: [
        MyAccountWrapperComponent,
        MyProfileComponent,
        ManageUsersComponent,
        ChangePasswordComponent,
        SingleUserComponent,
        InviteUserComponent,
        UpdateSubUserComponent,
        UpdateMobileComponent
    ],
    exports: [],
    providers: [
        DatePipe
    ]
})
export class MyAccountModule { }

import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../services/company.service';
import { CommonService } from '../../services/common.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AzureService } from '../../services/azure.service';
import { PermissionsService } from '../../services/permissions.service';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})

export class AddCompanyComponent implements OnInit {
  public isProcessing: boolean = false;
  public loadingPer: boolean = false
  makArray: Array<string> = []
  permArray: Array<any> = []

  public companyForm: UntypedFormGroup;
  public isEdit: boolean;
  public existingUsers: number;
  public onCompanyAdd: EventEmitter<any> = new EventEmitter();
  public onCompanyUpdate: EventEmitter<any> = new EventEmitter();
  private allowedfiles = ["png", "jpg", "jpeg", "bmp"];
  public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  public isUploading: boolean = false;
  public companyLogoModel: string = '';
  // public defaultMaxUsers: number = 100;
  // public defaultMaxRequests: number = 1000;
  public counter: number = 5;
  public counter_makes: number = 5;

  permissionModel = {
    p_ids: [],

  }

  formError = {};
  start_date = new Date()
  end_date = this.start_date.setDate(this.start_date.getDate() - 30);
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public companyService: CompanyService,
    public commonService: CommonService,
    public permissionService: PermissionsService,
    public azureService: AzureService
  ) {
    this.isEdit = this.data.isEdit;
  }

  ngOnInit() {
    this.getPermissions();
    this.initForm();
    this.setDynamicValidators();
  }

  getPermissions() {
    this.loadingPer = true
    this.permissionService.getPermissionsList().subscribe(res => {
      this.loadingPer = false
      try {
        const response = res;
        this.makArray = response['data']['makes']
        this.permArray = response['data']['permissions']
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      this.loadingPer = false
      let response = err;
      this.isProcessing = false;
      if (response.message) {
        if ('errors' in response.errors) {
          this.handleServerFormError(response.errors)
        }
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  increment(counterVar, control) {
    //if (this[counterVar] != 1) {
    this[counterVar] += 1;
    // }
    this.companyForm.controls[control].setValue(this[counterVar]);
  }

  decrement(counterVar, control) {
    if (this[counterVar] != 1) {
      this[counterVar] -= 1;
    }
    this.companyForm.controls[control].setValue(this[counterVar]);
  }

  initForm() {
    if (this.isEdit) {
      this.existingUsers = this.data.company.No_User;
      this.companyForm = new UntypedFormGroup({
        'companyId': new UntypedFormControl(this.data.company.CompanyId),
        'photoProfile': new UntypedFormControl(this.data.company.CompanyLogo),
        'thumbnailLogo': new UntypedFormControl(this.data.company.Thumbnail),
        'companyName': new UntypedFormControl(this.data.company.CompanyName, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[0-9A-Za-z_-][A-Za-z0-9 _.-]*$")
        ]),
        'email': new UntypedFormControl(this.data.company.Email, [
          Validators.required, Validators.email, Validators.maxLength(255)
        ]),
        'phoneNo': new UntypedFormControl(this.data.company.PhoneNo, [
          Validators.required, Validators.minLength(8), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
        ]),
        'userName': new UntypedFormControl(this.data.company.UserName, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'noUsers': new UntypedFormControl(this.data.company.No_User_Allowed, [
          Validators.required, this.maxNumberValidation
        ])
      });
      this.setImageUrl(this.data.company.CompanyLogo, this.data.company.Thumbnail);
    }
    else {
      var endDate = new Date();
      endDate.setDate(endDate.getDate() + 30);
      this.companyForm = new UntypedFormGroup({
        'photoProfile': new UntypedFormControl(null),
        'thumbnailLogo': new UntypedFormControl(null),
        'companyName': new UntypedFormControl(null, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[0-9A-Za-z_-][A-Za-z0-9 _.-]*$")
        ]),
        'email': new UntypedFormControl(null, [
          Validators.required, Validators.email, Validators.maxLength(254)
        ]),
        'phoneNo': new UntypedFormControl(null, [
          Validators.required, Validators.minLength(8), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
        ]),
        'userName': new UntypedFormControl(null, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'noUsers': new UntypedFormControl(5, [
          Validators.required, this.maxNumberValidation
        ]),
        'noMakes': new UntypedFormControl(5, [
          Validators.required
        ]),
        'makes': new UntypedFormControl([], [
          Validators.required
        ]),
        'start_date': new UntypedFormControl(new Date(), [
          Validators.required
        ]),
        'end_date': new UntypedFormControl(endDate, [
          Validators.required
        ]),
        'auto_accept': new UntypedFormControl(false),
      });
    }
  }

  setDynamicValidators() {
    const noMakesControl = this.companyForm.get('noMakes');
    const makesControl = this.companyForm.get('makes');
    this.companyForm.get('noMakes').valueChanges
      .subscribe(noMakes => {
        console.log(noMakes, 'noMakes')
        if (makesControl.value.length != noMakesControl.value) {
          this.formError['makes'] = `You can only choose ${noMakesControl.value} makes.`
        } else {
          this.formError['makes'] = ""
        }
      })

    makesControl.valueChanges
      .subscribe(makes => {
        console.log(makes)
        if (makes.length != noMakesControl.value) {
          this.formError['makes'] = `You can only choose ${noMakesControl.value} makes.`
        } else {
          this.formError['makes'] = ""
        }
      })
  }

  validatePermissions() {
    let isError = false
    const noMakesControl = this.companyForm.get('noMakes');
    const makesValue = this.companyForm.get('makes').value;
    console.log(makesValue, makesValue.length, noMakesControl.value)
    if (makesValue.length == noMakesControl.value) {
      this.formError['makes'] = ""
    } else {
      this.handleServerFormError({ 'makes': `You can only choose ${noMakesControl.value} makes.` })
      isError = true
    }
    return isError
  }

  public maxMakeValidation(control: UntypedFormControl) {
    if (control.value && parseInt(control.value) > 200) {
      return { maxValueCheck: true };
    }
    else {
      return null;
    }
  }

  public maxNumberValidation(control: UntypedFormControl) {
    if (control.value && parseInt(control.value) > 200) {
      return { maxValueCheck: true };
    }
    else {
      return null;
    }
  }

  togglePermissions(event, id) {
    if (event.checked) {
      this.permissionModel.p_ids.push(id);
      this.formError['permission'] = ""
    } else {
      const index = this.permissionModel.p_ids.indexOf(id)
      if (index != -1) {
        this.permissionModel.p_ids.splice(index, 1);
      }
    }
  }

  saveCompany(formData) {
    this.isProcessing = true;

    this.companyService.addCompany(formData).subscribe(res => {
      try {
        const response = res;
        this.onCompanyAdd.emit(response.data[0]);
        this.closeModal();
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.message) {
        if ('errors' in response.errors) {
          this.handleServerFormError(response.errors)
        }
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  addNewCompany(form) {
    //console.log(form.controls, form.valid)
    if (form.valid) {
      if (this.isValidRequest(form.value)) {
        if (this.permissionModel.p_ids.length <= 0) {
          this.formError['permission'] = "Please choose permissions."
        } else {
          this.formError['permission'] = ""
          let formValues = Object.assign({}, form.value)
          formValues['p_ids'] = this.permissionModel.p_ids.join(',')
          //console.log(formValues, 'formValues')
          formValues['makes'] = formValues['makes'].join(',')
          console.log(formValues, 'formValues')
          this.formError = {}
          this.saveCompany(formValues)
        }
      }
    }
    else {
      this.formValidate(form);
    }
  }

  updateCompany(form) {
    if (form.valid) {
      if (this.isValidRequest(form.value)) {
        this.isProcessing = true;
        //console.log(form.value)
        this.companyService.updateCompany(form.value).subscribe(res => {
          try {
            const response = res;
            this.onCompanyUpdate.emit(response.data[0]);
            this.closeModal();
          }
          catch (e) {
            this.commonService.commonSnakeBar();
          }
          this.isProcessing = false;
        }, (err) => {
          let response = err;
          this.isProcessing = false;
          if (response.message) {
            if ('errors' in response.errors) {
              this.handleServerFormError(response.errors)
            }
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }, () => {
          this.isProcessing = false;
        })
      }
    }
    else {
      this.formValidate(form);
    }
  }

  checkPermissionsExs(slug) {
    let ex = this.permArray.findIndex(el => el.slug == slug)
    if (ex != -1) {
      return true
    }
    return false
  }

  skipPer(slug) {
    if (slug == 'users' || slug == 'makes') {
      return false
    }
    return true
  }

  closeModal() {
    this.dialogRef.close();
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (key == 'p_ids') {
          this.formError['permission'] = errors[key]
        }
        if (this.companyForm.contains(key)) {
          let control = this.companyForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  // requestInput() {
  //   if (this.companyForm.controls.noRequests.value) {
  //     this.companyForm.controls.noRequests.patchValue(parseInt(this.companyForm.controls.noRequests.value));
  //     this.defaultMaxRequests = parseInt(this.companyForm.controls.noRequests.value);
  //   }
  //   else {
  //     this.companyForm.controls.noRequests.patchValue(undefined);
  //   }
  // }

  // userInput() {
  //   if (this.companyForm.controls.noUsers.value) {
  //     this.companyForm.controls.noUsers.patchValue(parseInt(this.companyForm.controls.noUsers.value));
  //     this.defaultMaxUsers = parseInt(this.companyForm.controls.noUsers.value);
  //   }
  //   else {
  //     this.companyForm.controls.noUsers.patchValue(undefined);
  //   }
  // }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  isValidRequest(obj) {
    /*if (obj.noUsers < this.existingUsers) {
      this.commonService.showSnakeBar("User quota should be greater than existing users");
      return false;
    }
    else {*/
    if (this.validatePermissions()) {
      return false;
    } else {
      return true;
    }
    //}
  }

  setImageUrl(mainUrl, thumbUrl) {
    if (mainUrl && mainUrl != '') {
      this.companyForm.controls.photoProfile.setValue(mainUrl.toString());
    }
    if (thumbUrl && thumbUrl != '') {
      this.companyForm.controls.thumbnailLogo.setValue(thumbUrl.toString())
    }
    this.companyLogoModel = thumbUrl;
  }

  fileChange(event) {
    if (event.target.files[0] != undefined && this.validateFile(event.target.files[0].name)) {
      this.companyLogoModel = '';
      let file: File = event.target.files[0];
      let fileName: string = (new Date()).getTime() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
      if (!this.validateFileSize(file.size)) {
        this.commonService.showSnakeBar("File size should be less than or equal to 5 MB");
      }
      else {
        this.startImageLoading();
        this.commonService.getImageEssentials(file).subscribe(mainImage => {
          this.commonService.compressImage(mainImage.image, file.type, [400, 100], mainImage.width, mainImage.height).subscribe(compRes => {
            if (compRes.status) {
              const reqModel = {
                fileName: fileName,
                thumbName: 'thumb' + fileName
              }
              this.azureService.getSASToken(reqModel).subscribe(res => {
                try {
                  const result = res;
                  if (result.status) {
                    for (let i = 0; i < result.data.length; i++) {
                      let newFile = <File>this.createFile(compRes.data.blobs[i], file.name);
                      this.azureService.putObject(result.data[i].uri, newFile).subscribe((res: any) => {
                        if (res.ok && i == 1) {
                          this.setImageUrl(reqModel.fileName, reqModel.thumbName);
                          this.stopImageLoading();
                        }
                      })
                    }
                  }
                }
                catch (ex) {
                  this.commonService.showSnakeBar("Something went wrong");
                  this.stopImageLoading();
                }
              }, (err) => {
                let response = err;
                if (response.message) {
                  this.commonService.showSnakeBar(response.message);
                }
                this.stopImageLoading();
              })
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
              this.stopImageLoading();
            }
          })
        })
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowedfiles.indexOf(ext.toLowerCase()) != -1) {
      return true;
    }
    else {
      this.commonService.showSnakeBar("Please try with valid file extensions (.jpg, .jpeg, .bmp, .png)");
      return false;
    }
  }

  resetImageInput(event) {
    if (event.target.files[0] != undefined) {
      event.target.value = "";
    }
  }

  validateFileSize(fileSize: number) {
    if (((fileSize / 1024) / 1024) > 5) {
      return false;
    }
    else {
      return true;
    }
  }

  private createFile(fileBlob: Blob, fileName: string): File {
    const file: File = <File>Object.assign(fileBlob, { name: fileName });
    return file;
  }

  startImageLoading() {
    this.isUploading = true;
    this.isProcessing = true;
  }

  stopImageLoading() {
    this.isUploading = false;
    this.isProcessing = false;
  }
}

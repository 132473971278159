/*
* @ngdoc Service
* @name app.services.DataStoreService
* @description
* This service is used to handle the task related to temporary storing the data as Observable
* */
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  private userListSignal = new Subject<any>()
  private userSubPopupSignal = new Subject<any>()
  private companyListSignal = new Subject<any>();
  private adminUserListSignal = new Subject<any>();
  private cartCounter = new Subject<number>();
  private userProductAccess = new BehaviorSubject(false);  
  constructor() {

  }

  public showMobileMenu$ = new BehaviorSubject(false);

  //used
  sendUserListSignal() {
    this.userListSignal.next();
  }

  //Used
  getUserListSignal(): Observable<any> {
    return this.userListSignal.asObservable();
  }

  setSubPopupSignal() {
    this.userSubPopupSignal.next()
  }

  getSubPopupSignal(): Observable<any> {
    return this.userSubPopupSignal.asObservable();
  }

  sendCompanyListSignal(data) {
    this.companyListSignal.next(data);
  }

  getCompanyListSignal(): Observable<any> {
    return this.companyListSignal.asObservable();
  }

  sendAdminUserListSignal(data) {
    this.adminUserListSignal.next(data);
  }

  getAdminUserListSignal(): Observable<any> {
    return this.adminUserListSignal.asObservable();
  }

  setCart(counter: number) {
    this.cartCounter.next(counter);
  }

  getCart() {
    return this.cartCounter.asObservable();
  }

  getShowMobileMenuStatus(): Observable<any> {
    return this.showMobileMenu$.asObservable();
  }

  setShowMobileMenuStatus(status:boolean) {
      this.showMobileMenu$.next(status);
  }

  setUserProductAccess(trigger: boolean) {
    this.userProductAccess.next(trigger);
  }

  getUserProductAccess() {
    return this.userProductAccess.asObservable();
  }

}

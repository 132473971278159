// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-without-login-verify-otp {
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 40px auto;
  line-height: 1.4;
}
.auth-without-login-verify-otp .verification-notify-step .verify-mobile-number {
  padding: 0;
}
.auth-without-login-verify-otp .verification-notify-step .verify-mobile-number .verification-code-wrapper {
  margin: 0 !important;
}
.auth-without-login-verify-otp .verification-code-wrapper {
  margin: 0 auto;
  padding: 0;
  background: transparent;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/ap-common/phone-verification-steps/phone-verification-steps.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,2CAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACM;EACE,UAAA;AACR;AAAQ;EACE,oBAAA;AAEV;AAGI;EACE,cAAA;EACA,UAAA;EACA,uBAAA;EACA,gBAAA;AADN","sourcesContent":[".auth-without-login-verify-otp{\n    padding:40px;\n    background: #FFFFFF;\n    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n    margin:40px auto;\n    line-height: 1.4;\n    .verification-notify-step{\n      .verify-mobile-number{\n        padding:0;\n        .verification-code-wrapper{\n          margin:0 !important;\n        }\n      }\n    }\n  \n    .verification-code-wrapper{\n      margin:0 auto;\n      padding:0;\n      background: transparent;\n      box-shadow: none;\n    }\n  \n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { QuotesService } from 'src/app/services/quotes.service';


@Component({
  selector: 'app-invoices-listing',
  templateUrl: './invoices-listing.component.html',
  styleUrls: ['./invoices-listing.component.scss']
})
export class InvoicesListingComponent implements OnInit {


  displayedColumns: string[] = ['InvoiceId', 'CompanyName', 'OrderItems', 'TotalPrice', 'Created_At', 'PaymentMethod', 'action'];
  dataSource = [];
  paymentLoader: boolean = false;
  priceFormat
  downloadLoader: boolean = false;
  downloadId = null;
  coupons = [];
  companies = [];
  formModel = {
    companyId: '',
    CouponCode: '',
    PaymentType: '',
    PaymentMethod: '',
    DaysInterval: '',
    productId: ''
  };
  public productsList: Array<{
    productId: number,
    productName: string
  }> = [];
  constructor(
    private commonService: CommonService,
    private quoteService: QuotesService,
    public appSettings: AppSettings
  ) {
    this.priceFormat = this.appSettings.priceFormat;
  }

  ngOnInit() {
    this.commonService.setTitle("Invoice");
    this.getInvoiceListing();
    this.getFilters()
  }
  getFilters() {
    this.quoteService.getListingFilters().subscribe(
      (res) => {
        this.paymentLoader = false;
        const response = res;
        this.coupons = response.data['couponCodes'];
        this.companies = response.data['companyList'];
        this.productsList = response.data['productsList'];
      },
      (err) => {
        this.paymentLoader = false;
        let response = err;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }
    );
  }

  getInvoiceListing() {
    this.paymentLoader = true;
    this.quoteService.paymentListing(this.formModel)
      .subscribe(
        (res) => {
          const response = res;
          this.dataSource = response.data;
          this.paymentLoader = false;
        },
        (err) => {
          this.paymentLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

  downloadInvoice(InvoiceId, Company_Id) {
    this.downloadLoader = true;
    this.downloadId = InvoiceId
    this.quoteService.sendInvoiceDetail({ id: InvoiceId, action: 'download', companyId: Company_Id })
      .subscribe(
        (res) => {
          this.downloadId = null;
          this.downloadLoader = false;
          const response = res;
          let filename = `invoice-${InvoiceId}.pdf`;
          var blob = new Blob([response], { type: 'application/pdf' });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
          }
          else {
            var link = document.createElement("a");
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        },
        (err) => {
          this.downloadId = null;
          this.downloadLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

}

import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { DataStoreService } from 'src/app/services/data-store.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {

  public innerWidth: any;
  public cartCounterSub: Subscription;
  public cartCounter: number = 0;
  cartLoader: boolean = false;
  companyId = null;
  cartUrl = '/my-cart';
  queryParams = null;
  nameAbbr: string = "";
  constructor(
    public jwtService: JwtService,
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
    public userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataStore: DataStoreService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.roleCheckService.isCompanyOwner() || this.roleCheckService.isSuperAdmin() || this.roleCheckService.isCompanyAdmin()) {
      this.cartCounterSub = this.dataStore.getCart().subscribe(res => {
        this.cartCounter = res;
      })
      this.getCartCounter();
      this.cartUrl = '/cart'
    }
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.companyId = params['companyId'];
    //   if (this.roleCheckService.isSuperAdmin() && this.companyId) {
    //     this.cartUrl = '/cart'
    //     this.queryParams = { companyId: this.companyId };
    //   }
    // });
    if(this.jwtService.getUserName()) {
      let matches: any = this.jwtService.getUserName().match(/\b(\w)/g);
      this.nameAbbr = matches.join('');
    }
  }

  ngOnDestroy() {
    if (this.cartCounterSub) {
      this.cartCounterSub.unsubscribe();
    }
  }


  getCartCounter() {
    this.productService
      //.getCartCount({ companyId: this.companyId })
      .getCartCount()
      .subscribe(res => {
        try {
          const response = res;
          this.cartCounter = response.data['counter'];
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.cartLoader = false;
      }, (err) => {
        let response = err;
        this.cartLoader = false;
        if (response.message) {
          this.commonService.showSnakeBar(response.message)
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.cartLoader = false;
      })
  }

  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  logout() {
    this.logoutUser()
    this.jwtService.deleteLocalStorage();

  }

  reRoutePage() {
    this.router.navigate(["/my-account/manage-users"])
  }

  logoutUser() {
    this.userService.logout().subscribe(res => {
      try {
        const response = res;
      }
      catch (e) {
      }
    }, (err) => {

    })
  }

}

/*
* @ngdoc Module
* @name app.vehicle
* @description
* This module is used to handle all the features related to vehicle.
* */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { SharedModule } from '../shared/shared.module';

import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { CommonService } from '../services/common.service';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CompanySignupComponent } from './company-signup/company-signup.component';
import { OktaLoginComponent } from './okta-login/okta-login.component';
import { OktaCallbackComponent } from './okta-callback/okta-callback.component';
import { ErrorComponent } from './error/error.component';
import { BackButtonComponent } from './back-button/back-button.component'
import { VerificationModule } from '../ap-common/verification-module/verification.module';
import { LogoutComponent } from './logout/logut.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        SharedModule,
        RouterModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        VerificationModule
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        CompanySignupComponent,
        OktaLoginComponent,
        OktaCallbackComponent,
        ErrorComponent,
        BackButtonComponent
    ],
    providers: [
        CommonService
    ]
})
export class UserAuthModule { }

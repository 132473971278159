import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { NgOtpInputComponent } from 'ng-otp-input';
import { VerificationService } from 'src/app/services/verification.service';
import { verificationTypes } from 'src/app/models/verification-types.model';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  public config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  public resendloader: boolean = false;
  public verificationLoader: boolean = false;
  public otpError: string = "";
  @Input() data: {
    otpId: number,
    phone: string,
    email: string,
    verificationMethod: string,
    verificationFrom: string,
    otp?: number,
    name?: string,
    verificationToken: string,
    countryCode?: string
  };
  @Input() verificationFrom: string = "";
  private totalSeconds: number = 60;
  public timer: number = this.totalSeconds;
  public enableVerifyButton: boolean = false;
  public maskedPhoneNumber: string = "";
  public maskedEmail: string = "";
  public verifyingOtp: boolean;
  public apiTriggeredCount: number = 0;
  @Output() signupOtpVerifyResponse = new EventEmitter();
  @Output() changePhone = new EventEmitter();
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;
  constructor(
    private commonService: CommonService,
    private verificationService: VerificationService
  ) {
  }

  ngOnInit() {
    if (this.data.verificationMethod === 'phone') {
      this.maskedPhoneNumber = this.data.verificationFrom !== verificationTypes.login ? this.data.countryCode + " *** *** *" + this.data.phone.substring(7) : this.data.phone;
    } else {
      if (this.data.verificationFrom !== verificationTypes.login) {
        const indexOf = this.data.email.indexOf('@');
        this.maskedEmail = this.data.email.substring(0, 3) + "*******" + this.data.email.substring(indexOf);
      } else {
        this.maskedEmail = this.data.email;
      }
    }
  }

  resetTimer(secs: number) {
    if (secs === this.totalSeconds) {
      this.ngOtpInput.setValue(0);
    }
    this.timer = secs - 1;
    setTimeout(() => {
      if (secs > 1) {
        this.resetTimer(this.timer);
      }
    }, 1000);
  }

  onOtpChange(otp) {
    if (otp.length === this.config.length) {
      this.data.otp = otp;
      this.enableVerifyButton = true;
      this.verifyOtp();
    } else {
      this.enableVerifyButton = false;
    }
  }

  verifyOtp() {
    const params = this.data;
    this.verificationLoader = true;
    this.enableVerifyButton = false;
    this.verifyingOtp = true;
    this.apiTriggeredCount = 1;
    if (this.apiTriggeredCount === 1) {
      this.verificationService.verifyOtp(params, this.verificationFrom).subscribe(
        (res) => {
          const response = res;
          try {
            if (response.status === httpStatusCodes.success) {
              this.otpError = "";
              this.commonService.showSnakeBar(response.message);
              setTimeout(() => {
                this.signupOtpVerifyResponse.emit({ status: 'true', data: response.data });
              }, 1500);
            } else {
              this.commonService.showSnakeBar(response.message);
            }
            this.verificationLoader = false;
            this.enableVerifyButton = true;
            this.verifyingOtp = false;
          } catch (e) {
            this.commonService.commonSnakeBar();
          }
          this.apiTriggeredCount = 0;
        },
        (err) => {
          const error = err;
          this.enableVerifyButton = true;
          this.verificationLoader = false;
          this.verifyingOtp = false;
          this.apiTriggeredCount = 0;
          if (error.status === httpStatusCodes.formError) {
            this.otpError = error.message;
          } else {
            this.commonService.showSnakeBar(error.message);
          }
        }
      );
    }
  }


  resendOtp() {
    const params = {
      otpId: this.data.otpId,
      verificationMethod: this.data.verificationMethod,
      phone: this.data.phone,
      email: this.data.email,
      name: this.data.name,
      verificationToken: this.data.verificationToken,
      countryCode: this.data.countryCode
    };
    this.resendloader = true;
    this.verificationService.resendOtp(params, this.verificationFrom).subscribe(
      (res) => {
        const response = res;
        this.resendloader = false;
        try {
          if (response.status === httpStatusCodes.success) {
            this.otpError = "";
            this.commonService.showSnakeBar(response.message);
            this.ngOtpInput.setValue(0);
            this.resetTimer(this.totalSeconds);
          } else {
            this.commonService.showSnakeBar(response.message);
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
      },
      (err) => {
        const error = err;
        this.resendloader = false;
        if (error.message) {
          this.commonService.showSnakeBar(error.message);
        } else {
          this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
        }
      }
    );
  }

  changePhoneNumber() {
    this.changePhone.emit('change');
  }
}

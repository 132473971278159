import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CouponService } from 'src/app/services/coupon.service';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent implements OnInit {

  public couponForm: UntypedFormGroup;
  public isProcessing: boolean = false;
  public formError: any = {};
  products = []
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<AddCouponComponent>,
    private commonService: CommonService,
    private couponService: CouponService
  ) {
    this.products = dialogData.products
  }

  ngOnInit() {
    this.couponForm = new UntypedFormGroup({
      'Name': new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
      'Code': new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
      'Value': new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      'Type': new UntypedFormControl('percent_off', [Validators.required]),
      'IsActive': new UntypedFormControl(true, [Validators.required]),
      'Product_Id': new UntypedFormControl(1, [Validators.required])
    });
  }

  submit(form) {
    if (form.valid) {
      const formValue = form.value;
      if (formValue.Type == 'amount_off' && formValue.Value !== parseInt(formValue.Value, 10)) {
        return this.commonService.showSnakeBar('Invalid coupon value. Please choose a number without decimal point.');
      }
      this.isProcessing = true;
      this.formError = {};
      this.couponService.addCoupon(form.value).subscribe(res => {
        try {
          const response = res;
          if (response.message) {
            this.commonService.showSnakeBar(response.message)
          }
          this.closeModal(form.value);
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message)
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.couponForm.contains(key)) {
          let control = this.couponForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  closeModal(data = null) {
    this.dialogRef.close(data);
  }

}

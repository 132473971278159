// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-single-product-details {
  margin-top: 30px;
}
.edit-single-product-details .single-configuration {
  padding: 25px;
  background-color: #f5f5f5;
}
.edit-single-product-details .single-configuration .select-config-features {
  display: flex;
  flex-direction: column;
}
.edit-single-product-details .single-configuration .select-config-features .mat-checkbox {
  margin-bottom: 0.7rem;
}
.edit-single-product-details .single-configuration .select-config-features .mat-checkbox:last-child {
  margin-bottom: 0;
}
.edit-single-product-details .single-configuration .config-field {
  position: relative;
}
.edit-single-product-details .single-configuration .config-field .mat-mdc-slide-toggle {
  position: absolute;
  right: 0;
  top: -5px;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .edit-single-product-details .single-configuration {
    padding: 15px;
  }
  .edit-single-product-details .single-configuration .config-field:last-child .mat-mdc-form-field {
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/companies/edit-product-details/edit-product-details.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;AAEI;EACI,aAAA;EACA,yBAAA;AAAR;AACQ;EACI,aAAA;EACA,sBAAA;AACZ;AAAY;EACI,qBAAA;AAEhB;AADgB;EACI,gBAAA;AAGpB;AACQ;EACI,kBAAA;AACZ;AAAY;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;AAEhB;;AAKA;EAEQ;IACI,aAAA;EAHV;EAMkB;IACI,gBAAA;EAJtB;AACF","sourcesContent":["@import '../../../assets/styles/variables';\n\n.edit-single-product-details{\n    margin-top:30px;\n    .single-configuration{\n        padding:25px;\n        background-color: #f5f5f5;\n        .select-config-features{\n            display: flex;\n            flex-direction: column;\n            .mat-checkbox{\n                margin-bottom:0.7rem;\n                &:last-child{\n                    margin-bottom:0;\n                }\n            }\n        }\n        .config-field{\n            position: relative;\n            .mat-mdc-slide-toggle{\n                position: absolute;\n                right:0;\n                top:-5px;\n                z-index: 10;\n            }\n        }\n    }\n}\n\n\n@media screen and (max-width: $md) {\n    .edit-single-product-details{\n        .single-configuration{\n            padding:15px;\n            .config-field{\n                &:last-child{\n                    .mat-mdc-form-field{\n                        margin-bottom:0;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { CommonService } from '../../services/common.service';
import { AppSettings } from '../../configs/app-settings';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {

  public forgotPassword: UntypedFormGroup;
  public isProcessing: boolean = false;
  public formError: any = {};
  constructor(
    private renderer: Renderer2,
    private userService: UserService,
    private commonService: CommonService,
    public appSettings: AppSettings
  ) {
    // this.renderer.addClass(document.body, 'login_bg');
  }

  ngOnInit() {
    this.initForm();
  }


  ngOnDestroy() {
    // this.renderer.removeClass(document.body, 'login_bg');
  }

  initForm() {
    this.forgotPassword = new UntypedFormGroup({
      'email': new UntypedFormControl(null, [
        Validators.required, Validators.email, Validators.maxLength(255)
      ])
    });
  }

  submit(form) {
    if (form.valid) {
      this.isProcessing = true;
      this.userService.forgotPassword(form.value).subscribe(res => {
        try {
          const response = res;
          if (response.message) {
            this.commonService.showSnakeBar(response.message)
          }
          this.initForm();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message)
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors
* @return none
*/
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.forgotPassword.contains(key)) {
          let control = this.forgotPassword.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  getLoginUrl() {
    return location.href.includes('admin') ? '/admin/login' : '/login'
  }
}

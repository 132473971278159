import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { QuotesService } from 'src/app/services/quotes.service';

export interface PeriodicElement {
  UserQuoteId: string;
  creationDate: string;
  QuoteItems: { [key: string]: any };
  TotalPrice: string;
  Status: string;
  action: string;
}
@Component({
  selector: 'app-quotes-listing',
  templateUrl: './quotes-listing.component.html',
  styleUrls: ['./quotes-listing.component.scss']
})
export class QuotesListingComponent implements OnInit {

  displayedColumns: string[] = ['CompanyName', 'UserQuoteId', 'Created_At', 'QuoteItems', 'TotalPrice', 'Status', 'Action'];
  dataSource = [];
  priceFormat = "1.2-2";
  quoteLoader: boolean = false;
  paymentLoader: boolean = false;
  coupons = [];
  companies = [];
  formModel = {
    companyId: '',
    CouponCode: '',
    Status: '',
    DaysInterval: '',
    productId: ''
  }
  public productsList: Array<{
    productId: number,
    productName: string
  }> = [];
  constructor(
    private commonService: CommonService,
    private quoteService: QuotesService,
    public appSettings: AppSettings
  ) { }

  ngOnInit() {
    this.commonService.setTitle("My Quotes");
    this.getQuoteListing();
    this.getFilters();
  }


  getFilters() {
    this.quoteService.getListingFilters().subscribe(
      (res) => {
        this.paymentLoader = false;
        const response = res;
        this.coupons = response.data['couponCodes'];
        this.companies = response.data['companyList'];
        this.productsList = response.data['productsList'];
      },
      (err) => {
        this.paymentLoader = false;
        let response = err;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }
    );
  }

  getQuoteListing() {
    this.quoteLoader = true;
    this.quoteService.quoteListing(this.formModel)
      .subscribe(
        (res) => {
          const response = res;
          this.dataSource = response.data;
          this.quoteLoader = false;
        },
        (err) => {
          this.quoteLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }


}

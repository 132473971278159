import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { QuotesService } from 'src/app/services/quotes.service';

@Component({
  selector: 'app-company-admin-payments',
  templateUrl: './company-admin-payments.component.html',
  styleUrls: ['./company-admin-payments.component.scss']
})
export class CompanyAdminPaymentsComponent implements OnInit {

  displayedColumns: string[] = ['OrderItems', 'TotalPrice', 'Created_At', 'PaymentMethod', 'action'];
  dataSource = [];
  paymentLoader: boolean = false;
  priceFormat
  downloadLoader: boolean = false;
  downloadId = null;
  constructor(
    private commonService: CommonService,
    private quoteService: QuotesService,
    public appSettings: AppSettings
  ) {
    this.priceFormat = this.appSettings.priceFormat;
  }

  ngOnInit() {
    this.commonService.setTitle("My Payments");
    this.getInvoiceListing();
  }

  getInvoiceListing() {
    this.paymentLoader = true;
    this.quoteService.paymentListing({})
      .subscribe(
        (res) => {
          this.paymentLoader = false;
          const response = res;
          this.dataSource = response.data;
        },
        (err) => {
          this.paymentLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

  downloadInvoice(InvoiceId) {
    this.downloadLoader = true;
    this.downloadId = InvoiceId
    this.quoteService.sendInvoiceDetail({ id: InvoiceId, action: 'download' })
      .subscribe(
        (res) => {
          this.downloadId = null;
          this.downloadLoader = false;
          const response = res;
          let filename = `invoice-${InvoiceId}.pdf`;
          var blob = new Blob([response], { type: 'application/pdf' });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
          }
          else {
            var link = document.createElement("a");
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        },
        (err) => {
          this.downloadId = null;
          this.downloadLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }


}

/*
  *
  * @ngdoc Services
  * @name app.services.CouponService
  * @description
  * The CouponService Service is used for handling the request related to coupons
  *
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }

  /*
  * @name getCouponListing
  * @param
  * @description get Coupon Listing
  * @return Observable
  */
  getCouponListing() {
    return this.commonHttpService.get(this.apiUrls.admin.couponList);
  }

  /*
  * @name addCoupon
  * @param
  * @description add Coupon
  * @return Observable
  */
  addCoupon(formData) {
    return this.commonHttpService.post(this.apiUrls.admin.addCoupon, formData);
  }

  /*
  * @name couponStatus
  * @param
  * @description activate/deactivate Coupon status
  * @return Observable
  */
  couponStatus(formData) {
    return this.commonHttpService.post(this.apiUrls.admin.toggleCoupon, formData);
  }
}

import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IfStmt } from '@angular/compiler';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { userRoles } from 'src/app/models/user-roles.model';
import { JwtService } from 'src/app/services/jwt.service';
import { AppSettings } from 'src/app/configs/app-settings';

export enum userTypes {
  adminUser = 1,
  companyUser = 0
}

@Component({
  selector: 'invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {

  public userInvite: UntypedFormGroup;
  public userRoles: any = [];
  public productListing: any = [];
  public isProcessing: boolean = false;
  public isFetchingFrom: boolean = false;
  public formError = {};
  public onInviteSubmit: EventEmitter<any> = new EventEmitter();
  public onInviteUpdate: EventEmitter<any> = new EventEmitter();
  public isEdit: boolean = false;
  public user: any = {};
  public companyId: number;
  public productSelected = []
  public assigned = []
  public oldProducts = []
  public userTypes = [
    { id: userTypes.adminUser, label: "Admin" },
    { id: userTypes.companyUser, label: "Member" },
  ];
  public isUserRoleChanged: boolean = false;
  public showRolesDropdown: boolean = true;
  public isAutoPredictSelected: boolean = false;
  public loggedInUserRole: number;
  public showProductsAndRoleFields: boolean = false;
  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private commonService: CommonService,
    private productService: ProductService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InviteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appSettings: AppSettings
  ) {
    this.loggedInUserRole = this.jwtService.getUserRole();

    if (this.data && this.data.isEdit) {
      this.isEdit = this.data.isEdit;
      this.user = this.data.user;
      if (this.user.Role_Id !== userRoles.companyOwner) {
        this.showProductsAndRoleFields = true;
      }
      this.user.isAdmin = this.user.Role_Id === 2 ? 1 : 0;
      this.showRolesDropdown = this.data.showRolesDropdown;
      if (this.user.Product_Ids && this.user.Product_Ids.length > 0) {
        this.assigned = this.user.Product_Ids.split(',')
        this.oldProducts = this.assigned.map(Number)
      }
    } else {
      this.showProductsAndRoleFields = true;
    }
    if (!this.data.companyId) {
      this.commonService.showSnakeBar('Unable to find the company. Please try again later.')
      this.dialogRef.close()
    }
    this.companyId = this.data.companyId;
  }

  ngOnInit() {
    this.initForm();
    if (this.showProductsAndRoleFields === true) {
      this.getUserPermissionList();
    }
    //this.getProducts();
  }

  getUserPermissionList() {
    //this.isFetchingFrom = true;
    this.userService.getUserPermissionList().subscribe(res => {
      try {
        const response = res;
        this.userRoles = response.data.Permissions;
        this.getProducts()
      }
      catch (e) {

      }
      //this.isFetchingFrom = false;
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      //this.isFetchingFrom = false;
    })
  }

  initForm() {
    if (this.isEdit) {
      const isAdmin = this.user.Role_Id === userRoles.companyAdmin ? userTypes.adminUser : userTypes.companyUser;
      if (this.showProductsAndRoleFields) {
        this.userInvite = new UntypedFormGroup({
          'name': new UntypedFormControl(this.user.Name, [
            Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
          ]),
          'jobTitle': new UntypedFormControl(this.user.jobTitle, [
            Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
          ]),
          'userId': new UntypedFormControl(this.user.User_Id),
          'email': new UntypedFormControl(this.user.Email, [
            Validators.required, Validators.email, Validators.maxLength(255)
          ]),
          'isAdmin': new UntypedFormControl(isAdmin, [Validators.required]),
          'permission_id': new UntypedFormControl(this.user.Permission_Id.toString()),
          'companyId': new UntypedFormControl(this.companyId, [Validators.required])
        });
      } else {
        this.userInvite = new UntypedFormGroup({
          'name': new UntypedFormControl(this.user.Name, [
            Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
          ]),
          'jobTitle': new UntypedFormControl(this.user.jobTitle, [
            Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
          ]),
          'userId': new UntypedFormControl(this.user.User_Id),
          'email': new UntypedFormControl(this.user.Email, [
            Validators.required, Validators.email, Validators.maxLength(255)
          ]),
          'companyId': new UntypedFormControl(this.companyId, [Validators.required])
        });
      }
    }
    else {
      this.userInvite = new UntypedFormGroup({
        'name': new UntypedFormControl(null, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'jobTitle': new UntypedFormControl(null, [
          Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.appSettings.jobTitleRegex)
        ]),
        'email': new UntypedFormControl(null, [
          Validators.required, Validators.email, Validators.maxLength(255)
        ]),
        'isAdmin': new UntypedFormControl(null, [Validators.required]),
        'permission_id': new UntypedFormControl(null),
        'companyId': new UntypedFormControl(this.companyId, [Validators.required])
      });
    }
  }

  checkUserRole(role) {
    if (this.isEdit) {
      if (this.user.isAdmin !== role.value) {
        this.isUserRoleChanged = true;
      } else {
        this.isUserRoleChanged = false;
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  sendInvite(form) {
    if (form.valid) {
      this.isProcessing = true;
      this.formError = {}
      form.value.products = this.productSelected
      if (this.isAutoPredictSelected === false) {
        form.value.permission_id = 2;
      }
      this.userService.addUserToCompany(form.value).subscribe(res => {
        try {
          const response = res;
          this.onInviteSubmit.emit();
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.userInvite.contains(key)) {
          let control = this.userInvite.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  updateUser(form) {
    if (form.valid) {
      this.isProcessing = true;
      form.value.products = this.oldProducts;
      // if(form.value.isAdmin === userRoles.adminUser) { //if user role is admin
      //   form.value.permission_id = this.userRoles[0].value;
      // }
      if (this.isAutoPredictSelected === false) {
        form.value.permission_id = 2;
      }
      this.userService.updateCompanyUser(form.value).subscribe(res => {
        try {
          const response = res;
          this.onInviteUpdate.emit();
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        // this.closeModal();
      }, () => {
        this.isProcessing = false;
        //this.closeModal();
      })
    }
    else {
      this.formValidate(form);
    }
  }

  updateUserRoleConfirmation(form) {
    if (form.valid) {
      let msg: string = "";
      if (form.value.isAdmin === userTypes.adminUser) {
        msg = "Giving this user Admin privileges allows them to have the same level of access as you. Are you sure?";
      } else {
        msg = "You are removing Admin privileges for this user. Are you sure?";
      }
      const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          title: msg
        }
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result == 'yes') {
          this.updateUser(form);
        }
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  getProducts() {
    this.isFetchingFrom = true;
    this.isAutoPredictSelected = false;
    this.productService.getProductListing({ companyId: this.companyId }).subscribe(res => {
      try {
        const response = res;
        this.productListing = response.data;
        this.productListing.forEach(prod => {
          if (prod.Product_Id === 1 && this.oldProducts.indexOf(prod.Product_Id) != -1) {
            this.isAutoPredictSelected = true;
          }
        })
        if (this.productListing.length <= 0) {
          this.commonService.showSnakeBar('You cannot add user, please buy product first.')
          this.dialogRef.close()
        }
      }
      catch (e) {

      }
      this.isFetchingFrom = false;
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isFetchingFrom = false;
    })
  }

  productsCheck(event: MatCheckboxChange, products) {
    if (this.isEdit) {
      if (event.checked) {
        this.oldProducts.push(products.Product_Id);
        if (products.Product_Id === 1 && this.oldProducts.indexOf(products.Product_Id) != -1) {
          this.isAutoPredictSelected = true;
        }
      }
      else {
        const index = this.oldProducts.indexOf(products.Product_Id);
        if (index != -1) {
          this.oldProducts.splice(index, 1);
        }
      }
    } else {
      if (event.checked) {
        this.productSelected.push(products.Product_Id);
        if (products.Product_Id === 1 && this.productSelected.indexOf(products.Product_Id) != -1) {
          this.isAutoPredictSelected = true;
        }
      }
      else {
        const index = this.productSelected.indexOf(products.Product_Id);
        if (index != -1) {
          this.productSelected.splice(index, 1);
        }
      }
    }
    if (!event.checked && products.Product_Id === 1) {
      this.isAutoPredictSelected = false;
    }
    console.log(this.isAutoPredictSelected);
  }
}

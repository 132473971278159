import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { httpStatusCodes } from '../configs/httpStatusCodes';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtService } from '../services/jwt.service';
import { DataStoreService } from '../services/data-store.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    public snackBar: MatSnackBar,
    private jwtService: JwtService,
    private dataStore: DataStoreService,
    private router: Router,
    private dialogRef: MatDialog
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Response>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  /**
  * Globally handling the errorsd
  */
  private errorHandler(response: Response | any): Observable<HttpEvent<Response>> {
    let rtResponse = response;
    if (response.error instanceof Error) {
      //A client-side or network error occurred.
    } else if (response instanceof HttpErrorResponse) {
      rtResponse = response.error;
    }
    const httpErrorCode = rtResponse.status;
    switch (httpErrorCode) {
      case httpStatusCodes.unauthorized:
        this.jwtService.deleteLocalStorage();
        this.snackBar.open('Your token has been expired. Please login again.', 'Done', {
          duration: 3000
        });
        break;
      case httpStatusCodes.forbidden:
        let isPopup = false
        try {
          const body = rtResponse || '';
          console.log(body, 'body', typeof (body) == "object", 'isPopup' in body['data'])
          const err = body.error || JSON.stringify(body);
          if (typeof (body) == "object") {
            if ('data' in body) {
              let dbData = body['data']
              if ('isPopup' in dbData) {
                isPopup = true
                this.handleLicenceUser()
                //this.router.navigate(["/dashboard"]);
              }
            }
          }
        } catch (error) {
          //console.log(error)
        }
        if (isPopup == true) {

        } else {
          this.snackBar.open('You are not authorize to view this page.', 'Done', {
            duration: 3000
          });
          this.dialogRef.closeAll()
          this.router.navigate(["/dashboard"]);
        }
        break;
      case httpStatusCodes.notFound:
        //this.router.navigateByUrl('/pages/404');
        break;
    }
    return throwError(rtResponse)
  }

  handleLicenceUser() {
    this.dataStore.setSubPopupSignal()
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.odometer-range {
  position: relative;
}
.odometer-range mat-label {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  top: -20px;
}
.odometer-range .mat-slider-wrapper {
  margin: 0;
  margin: -7px 10px 0 10px;
}
.odometer-range .mat-slider-wrapper .odometer-slider {
  width: 100%;
}

.company-error {
  position: absolute;
  bottom: 26px;
}

.filter-row {
  padding: 50px 32px 32px 32px;
  background: #F8F8F8;
}

.table-wrapper {
  padding: 0 32px;
  background: #ffffff;
  overflow: auto;
}
.table-wrapper tr th:nth-child(3), .table-wrapper tr th:nth-child(4) {
  min-width: 120px;
}

.select-count {
  max-width: 160px;
}

.table-wrapper {
  position: relative;
}
.table-wrapper .table-loader {
  top: 0;
  position: absolute;
  width: calc(100% - 35px);
  z-index: 10;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/asset-info-usage/asset-info-usage.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,yBAAA;EACA,UAAA;AAER;AAAI;EACI,SAAA;EACA,wBAAA;AAER;AADQ;EACI,WAAA;AAGZ;;AAEA;EACI,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,4BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;EACA,cAAA;AACJ;AACQ;EACI,gBAAA;AACZ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;AAEI;EACI,MAAA;EACA,kBAAA;EACA,wBAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;AAAR","sourcesContent":[".odometer-range{\n    position: relative;\n    mat-label{\n        position: absolute;\n        color: rgba(0, 0, 0, 0.6);\n        top: -20px;\n    }\n    .mat-slider-wrapper{\n        margin:0;\n        margin:-7px 10px 0 10px;\n        .odometer-slider{\n            width:100%;\n        }\n    }\n}\n\n.company-error{\n    position: absolute;\n    bottom: 26px;\n}\n\n.filter-row{\n    padding:50px 32px 32px 32px;\n    background: #F8F8F8;\n}\n\n.table-wrapper{\n    padding:0 32px;\n    background: #ffffff;\n    overflow: auto;\n    tr{\n        th:nth-child(3), th:nth-child(4){\n            min-width: 120px;\n        }\n    }\n}\n\n.select-count{\n    max-width:160px;\n}\n\n.table-wrapper{\n    position: relative;\n    .table-loader{\n        top:0;\n        position: absolute;\n        width:calc(100% - 35px);\n        z-index: 10;\n        min-height:350px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        background: rgba(255,255,255,0.6);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

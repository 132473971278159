import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'app-edit-product-details',
  templateUrl: './edit-product-details.component.html',
  styleUrls: ['./edit-product-details.component.scss']
})
export class EditProductDetailsComponent implements OnInit {

  configFeature: boolean = true;
  productId = null;
  processing: boolean = true;
  productDetail: any = {};
  productForm: UntypedFormGroup;
  productFields = []
  productUpdate = false
  formError = {};
  productConfigs = [];
  configLoader = false;
  permissions = []
  Makes = [];
  toggleAddConfig: boolean = false;
  constructor(
    private commonService: CommonService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public appSettings: AppSettings
  ) {
    this.productId = this.activatedRoute.snapshot.paramMap.get("id");
    if (!this.productId) {
      this.sendToProduct();
    }
    this.getProductDetails();
    this.getProductConfigurations();
  }

  ngOnInit() {

  }

  sendToProduct() {
    this.commonService.showSnakeBar(
      "Unable to find the requested product."
    );
    this.router.navigate(["/product-listing"]);
  }


  setFormValues() {
    if (this.productFields.length > 0) {
      let fieldsArray = {}
      this.productFields.forEach(element => {
        if (['IsTrial', 'TotalDays', 'TrialProduct_Config_Id', 'TrialUser', 'TrialMakes'].indexOf(element) != -1) {
          fieldsArray[element] = new UntypedFormControl(this.productDetail[element] ? this.productDetail[element] : null)
        } else {
          fieldsArray[element] = new UntypedFormControl(this.productDetail[element] ? this.productDetail[element] : null, [Validators.required])
        }
      });
      this.productForm = new UntypedFormGroup(fieldsArray);
      if (this.productForm.contains('IsTrial')) {
        this.productForm.controls['IsTrial'].valueChanges.subscribe(result => {
          const extraFields = [
            // 'UserPrice',
            // 'MakePrice',
            // 'MaxUser',
            // 'MaxMake',
            'TrialMakes',
            'TrialUser',
            'TotalDays',
            'TrialProduct_Config_Id'
          ];
          extraFields.forEach(el => {
            if (this.productForm.contains(el)) {
              this.productForm.controls[el].setValidators(result == true ? [Validators.required] : null);
              this.productForm.controls[el].updateValueAndValidity();
            }
          });
        })
      }
    }
  }

  getProductDetails() {
    this.processing = true
    this.productService.getAdminProductDetail({
      id: this.productId
    }).subscribe(res => {
      this.processing = false
      try {
        const response = res;
        this.productDetail = response.data['product'];
        this.productFields = response.data['productFields'];
        this.permissions = response.data['permissions'];
        if (this.productDetail && this.productFields) {
          this.setFormValues();
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.processing = true
      let response = err;
      if (response.status == 404) {
        this.sendToProduct()
      } else {
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }
    })
  }

  updateProduct(form) {
    if (form.valid) {
      this.productUpdate = true;
      this.formError = {};
      this.productService.updateProductInfo(form.value).subscribe(res => {
        try {
          const response = res;
          if (response.message) {
            this.commonService.showSnakeBar(response.message)
          }
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.productUpdate = false;
      }, (err) => {
        let response = err;
        this.productUpdate = false;
        if (response.message) {
          if ('errors' in response) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message)
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.productUpdate = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  getProductConfigurations() {
    this.configLoader = true
    this.productService.getAdminProductConfigs({
      id: this.productId
    }).subscribe(res => {
      this.configLoader = false
      try {
        const response = res;
        this.productConfigs = response.data['configs']
        if ('makes' in response.data) {
          this.Makes = response.data['makes']
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.configLoader = false
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }


  toggleConfigStatus(Product_Config_Id, $event: MatSlideToggleChange) {
    this.productService.prdConfigStatus({
      id: Product_Config_Id,
      IsActive: $event.checked
    }).subscribe(res => {
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message)
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }



  configAdded(event) {
    this.toggleAddConfig = false;
    if (event == true) {
      this.getProductConfigurations()
    }
  }

  handleAddConfig() {
    if (this.toggleAddConfig === false) {
      this.toggleAddConfig = true;
    }
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.productForm.contains(key)) {
          let control = this.productForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    console.log(formGroup, 'formGroup')
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }
}

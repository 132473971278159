import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CommonService } from '../../services/common.service';
import { AdminUsersService } from '../../services/admin-users.service';
import { DataStoreService } from '../../services/data-store.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { AddUpdateUserComponent } from '../add-update-user/add-update-user.component';
import { JwtService } from '../../services/jwt.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.scss']
})
export class AdminUserDetailComponent implements OnInit {

  @Input() User: any;
  @Output() processToggle = new EventEmitter();
  isProcessing: boolean = false
  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    public adminUserService: AdminUsersService,
    public dataStore: DataStoreService,
    public jwtService: JwtService
  ) { }

  ngOnInit() {
  }

  editUser() {
    const dialogRef = this.dialog.open(AddUpdateUserComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        user: this.User,
        isEdit: true
      },
      disableClose: true
    });
    dialogRef.componentInstance.onAddUser.subscribe(res => {
      this.processToggle.emit({ action: 'add', data: res })
    })
    dialogRef.componentInstance.onUpdateUser.subscribe(res => {
      this.User.Name = res.name
      this.User.Email = res.email
      this.User.PhoneNo = res.phone_no
      //this.processToggle.emit({ action: 'update', data: res })
    })
  }

  openConfirmPopUp() {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Delete User'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.deleteUser();
      }
    });
  }

  deleteUser() {
    this.isProcessing = true;
    this.adminUserService.deleteAdminUser({ userId: this.User.User_Id }).subscribe(res => {
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
          this.processToggle.emit({ action: 'delete' })
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.errors && Object.keys(response.errors).length > 0) {
        this.formatErrors(response.errors)
      }
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleCheckerService } from '../services/role-checker.service';

@Injectable({
  providedIn: "root"
})
export class SuperAdminGuard implements CanActivate {

  constructor(
    private roleCheckerService: RoleCheckerService,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isCompanyAdminAlso = next.data.isCompanyAdminAlso as boolean;
    if (this.roleCheckerService.isSuperAdmin() || isCompanyAdminAlso === true) {
      return true;
    }
    else {
      this.router.navigate(["/dashboard"]);
    }
  }
}

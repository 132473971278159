// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-configuration {
  padding: 25px;
  background-color: #f5f5f5;
}
.single-configuration .select-config-features {
  display: flex;
  flex-direction: column;
}
.single-configuration .select-config-features .mat-checkbox {
  margin-bottom: 0.7rem;
}
.single-configuration .select-config-features .mat-checkbox:last-child {
  margin-bottom: 0;
}
.single-configuration .config-field {
  position: relative;
}
.single-configuration .config-field .mat-mdc-slide-toggle {
  position: absolute;
  right: 0;
  top: -5px;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .single-configuration {
    padding: 15px;
  }
  .single-configuration .config-field:last-child .mat-mdc-form-field {
    margin-bottom: 0 !important;
  }
  .single-configuration .product-actions-buttons {
    margin: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/companies/add-product-config/add-product-config.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,yBAAA;AADJ;AAEI;EACI,aAAA;EACA,sBAAA;AAAR;AACQ;EACI,qBAAA;AACZ;AAAY;EACI,gBAAA;AAEhB;AAEI;EACI,kBAAA;AAAR;AACQ;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;AACZ;;AAIA;EACI;IACI,aAAA;EADN;EAIc;IACI,2BAAA;EAFlB;EAMM;IACI,oBAAA;EAJV;AACF","sourcesContent":["@import '../../../assets/styles/variables';\n\n.single-configuration{\n    padding:25px;\n    background-color: #f5f5f5;\n    .select-config-features{\n        display: flex;\n        flex-direction: column;\n        .mat-checkbox{\n            margin-bottom:0.7rem;\n            &:last-child{\n                margin-bottom:0;\n            }\n        }\n    }\n    .config-field{\n        position: relative;\n        .mat-mdc-slide-toggle{\n            position: absolute;\n            right:0;\n            top:-5px;\n            z-index: 10;\n        }\n    }\n}\n\n@media screen and (max-width: $md) {\n    .single-configuration{\n        padding:15px;\n        .config-field{\n            &:last-child{\n                .mat-mdc-form-field{\n                    margin-bottom:0 !important;\n                }\n            }\n        }\n        .product-actions-buttons{\n            margin:0 !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'my-account-wrapper',
  templateUrl: './my-account-wrapper.component.html',
  styleUrls: ['./my-account-wrapper.component.scss']
})
export class MyAccountWrapperComponent implements OnInit {

  constructor(public roleChecker: RoleCheckerService) { }

  ngOnInit() {
  }

}

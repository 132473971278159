import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { PermissionsService } from 'src/app/services/permissions.service';

const uUsers = 100
const uDays = 15
const uReq = 50000

const formConfigData = {
  'AutoPredict': {
    'Product_Id': null,
    'TotalDays': uDays,
    'TotalUsers': uUsers,
    'Makes': [],
    'Permission_Id': []
  },
  'InstantValuation': {
    'Product_Id': null,
    'TotalDays': uDays,
    'TotalUsers': uUsers,
    'TotalRequests': uReq,
  },
  'Rego': {
    'Product_Id': null,
    'TotalDays': uDays,
    'TotalUsers': uUsers,
    'TotalRequests': uReq,
  },
  'Vin': {
    'Product_Id': null,
    'TotalDays': uDays,
    'TotalUsers': uUsers,
    'TotalRequests': uReq,
  }
}
@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  public isProcessing: boolean = false;
  public productForm: UntypedFormGroup;
  public isEdit: boolean;
  onUpdateProduct: EventEmitter<any> = new EventEmitter();
  productOffers = [];
  //public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  isMultiple = false;
  isTotalDays = true;
  formError = {};
  makArray: Array<string> = []
  permArray: Array<any> = []
  ProductOffers: Array<any> = []
  productData: Array<any> = []

  selMakeArray: Array<any> = []
  selPermArray: Array<any> = []
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public commonService: CommonService,
    public permissionService: PermissionsService
  ) {
    this.isEdit = this.data.isEdit;
    this.productData = this.data.editProduct
    this.makArray = this.data.makes
    this.permArray = this.data.permissions
    this.ProductOffers = this.data.ProductOffers
    if (this.productData['Makes'] != null) {
      this.selMakeArray = this.productData['Makes'].split(',')
    }
    if (this.productData['Permissions'] != null) {
      this.selPermArray = this.productData['Permissions'].split(',').map(Number)
    }
    console.log(this.data, ' this.data')
  }

  ngOnInit() {
    this.initForm();
    this.getProductOffers()
  }

  getProductControls(productKey = null, productData = []) {
    let formObj = {}
    let fConfig = formConfigData[productKey]
    //console.log(productData)
    if (fConfig) {
      if (productKey == 'AutoPredict') {
        Object.keys(fConfig).forEach((key) => {
          let selVal = this.getFormSelVal(key, productData)
          if (key == 'Makes') {
            formObj[key] = new UntypedFormControl(selVal ? selVal.split(',') : fConfig[key], Validators.required)
          } else if (key == 'Permission_Id') {
            formObj[key] = new UntypedFormArray(this.getPermissionsControls(selVal ? selVal.split(',').map(Number) : []), [this.minSelectedCheckbox()])
          }
          else if (key == 'Product_Id') {
            formObj[key] = new UntypedFormControl(productData['Product_Id'], Validators.required)
          }
          else {
            formObj[key] = new UntypedFormControl(selVal ? selVal : fConfig[key], Validators.required)
          }
        })
      } else {
        Object.keys(fConfig).forEach((key) => {
          if (key == 'Product_Id') {
            formObj[key] = new UntypedFormControl(productData['Product_Id'], Validators.required)
          } else {
            let selVal = this.getFormSelVal(key, productData)
            formObj[key] = new UntypedFormControl(selVal ? selVal : fConfig[key], Validators.required)
          }
        })
      }
      return formObj
    }
    return null;
  }

  getFormSelVal(key, arr) {
    let obj = arr.find(o => o.Name === key);
    if (obj) {
      return obj['Value']
    } else {
      return null
    }
  }

  getProductOfferControls(ProductOffer_Id = 1) {
    let productsForm: any = {};
    if (ProductOffer_Id == 2) {
      if (this.productData['Products'] && Array.isArray(this.productData['Products'])) {
        this.productData['Products'].forEach(product => {
          let selVal = []
          if ('Characteristic' in product) {
            if ('Item' in product['Characteristic']) {
              selVal = product['Characteristic']['Item']
            }
          }
          selVal['Product_Id'] = product['Product_Id']
          //console.log(selVal)
          let fControls = this.getProductControls(product['ProductName'], selVal)
          if (fControls != null) {
            productsForm[product['ProductName']] = new UntypedFormGroup(fControls)
          }
        })
      }
    }
    let formObj = {
      'ProductOffer_Id': new UntypedFormControl(ProductOffer_Id, Validators.required),
      'Plan_Id': new UntypedFormControl(this.productData['Plan_Id'], Validators.required),
      'Products': new UntypedFormGroup(productsForm)
    }
    return new UntypedFormGroup(formObj)
  }


  selectedValue(event) {
    //console.log(event)
    this.productForm = this.getProductOfferControls(event.value)
  }

  getControl(path) {
    return this.productForm.get(path) as UntypedFormControl
  }

  checkControlError(path, pForm, errorType: string = 'required') {
    return this.getControlError(path, errorType) && (this.checkControlDirty(path))
  }

  getControlError(path, type: string = 'required') {
    return this.productForm.get(path).hasError(type)
  }

  checkControlDirty(path) {
    return this.productForm.get(path).invalid && (this.productForm.get(path).pristine || this.productForm.get(path).touched)
  }


  /*
  * @name initForm
  * @param none
  * @description Initialize forms             
  * @return none
  */
  initForm() {
    if (this.isEdit) {
      this.productForm = this.getProductOfferControls(this.productData['OfferTypeId'])
      console.log(this.productForm, 'this.productForm')
    }
  }

  getMakesList() {
    let Makes = this.getControl('Products.AutoPredict.Makes').value
    if (Makes.length > 0) {
      return Makes.join(', ')
    } else {
      return ' N/A'
    }
  }

  checkAuto() {
    let found = false
    if (this.productData['Products'] && Array.isArray(this.productData['Products'])) {
      this.productData['Products'].forEach(product => {
        if (product['Product_Id'] == "1") {
          found = true;
        }
      })
    }
    return found;
  }


  isProductExists(ProductName = null) {
    let find = false
    if (this.productData['Products'] && Array.isArray(this.productData['Products'])) {
      this.productData['Products'].forEach(product => {
        if (product['ProductName'] == ProductName) {
          find = true
        }
      })
    }
    return find
  }


  getProductIds() {
    let pIds = []
    if (this.productData['Products'] && Array.isArray(this.productData['Products'])) {
      this.productData['Products'].forEach(product => {
        pIds.push(product['Product_Id'])
      })
    }
    return pIds
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  checkTrialOffer() {
    return this.productData['OfferTypeId'] == 2
  }

  minSelectedCheckbox(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkbox
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }


  getPermissionsControls(selPermArray = []) {
    //show selected controls if product is AutoPredict and offer type is trial
    let isTrialAuto = this.checkAuto() && this.checkTrialOffer()
    //console.log(this.selPermArray)
    return this.permArray.map(control => {
      // console.log(control)
      if (isTrialAuto) {
        //console.log(this.selPermArray.indexOf(control['key']), control['key'])
        if (selPermArray.indexOf(control['key']) != -1) {
          return new UntypedFormControl(true)
        }
        return new UntypedFormControl(false)
      } else {
        return new UntypedFormControl(false)
      }
    })
  }

  /*
  * @name getVFactsGroups
  * @param none
  * @description Get VFactsGroups filter list                  
  * @return none
  */
  getProductOffers() {
    this.productOffers = this.data.ProductOffers
  }


  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.productForm.contains(key)) {
          let control = this.productForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  /*
  * @name updateNewAdjustment
  * @param none
  * @description update new risk adjustments             
  * @return none
  */
  updateProduct(pForm) {
    if (pForm.valid) {

      let formData = pForm.value
      //console.log(formData)
      let ProductOffer_Id = this.getControl('ProductOffer_Id').value
      //Add permission and makes if product is AutoPredict
      if (this.checkAuto() && ProductOffer_Id == 2) {
        let selectedPermissions: Array<Number> = this.getControl('Products.AutoPredict.Permission_Id').value
        selectedPermissions = selectedPermissions.map((checked, index) => checked ? this.permArray[index].key : null)
          .filter(value => value !== null);
        if (selectedPermissions.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one permission.")
          return false
        }
        //console.log(selectedPermissions)
        let copyPermissions = selectedPermissions.slice()
        formData['Products']['AutoPredict']['Permission_Id'] = copyPermissions.join(',')

        let Makes: Array<string> = this.getControl('Products.AutoPredict.Makes').value
        //console.log(Makes, Makes.length)
        if (Makes.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one make.")
          return false
        }
        let copyMakes = Makes.slice()
        formData['Products']['AutoPredict']['Makes'] = copyMakes.join(',')
      }
      let copyFormData = JSON.parse(JSON.stringify(formData))
      let products = []
      Object.keys(copyFormData['Products']).forEach(key => {
        let temp = copyFormData['Products'][key]
        let pId = temp['Product_Id']
        delete temp['Product_Id']
        products.push({ Product_Id: pId, 'Characteristic': temp })
      })
      copyFormData['Products'] = products
      this.isProcessing = true;
      //console.log(copyFormData)
      this.permissionService.updateProductOffer(copyFormData).subscribe(res => {
        try {
          const response = res;
          //console.log(response)
          this.onUpdateProduct.emit(true)
          this.commonService.showSnakeBar(response.message)
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          this.commonService.showSnakeBar(response.message)
        }
        else if (response.errors) {
          this.commonService.showSnakeBar('Invalid form data.');
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(pForm);
    }
  }

  closeModal(data = {}) {
    this.dialogRef.close(data);
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-small-loader',
  templateUrl: './small-loader.component.html',
  styleUrls: ['./small-loader.component.scss']
})
export class SmallLoaderComponent implements OnInit {

  @Input() ShowLoader: boolean
  @Input() CStyle: any

  constructor(private sanitization: DomSanitizer) {
  }

  ngOnInit() {
  }

}

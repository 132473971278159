import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-add-make-popup',
  templateUrl: './add-make-popup.component.html',
  styleUrls: ['./add-make-popup.component.scss']
})
export class AddMakePopupComponent implements OnInit {

  public makesList: Array<[]> = [];
  public oldMakes: Array<[]> = [];
  public selectedMakes: Array<[]> = [];
  public isProcessing: boolean = false;
  public errorMsg: string = "";
  public disableSaveBtn: boolean = true;
  @Output() makesUpdateEvent = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private companyService: CompanyService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<AddMakePopupComponent>,
  ) {
    if (this.data.allMakes) {
      this.makesList = this.data.allMakes;
      this.selectedMakes = this.data.product.Makes;
      this.oldMakes = this.data.product.Makes; //to keep history for comparison
    }
  }

  ngOnInit() {
  }

  onNgModelChange() {
    const makesUpdated = this.selectedMakes.filter((o) => this.oldMakes.indexOf(o) === -1);
    if (makesUpdated.length || (this.selectedMakes.length < this.data.product.TotalMakes)) { //if any make update or unselected any old make then show save button
      this.disableSaveBtn = false;
    } else { // disable button
      this.disableSaveBtn = true;
    }
    if (this.selectedMakes.length > this.data.product.TotalMakes) {
      this.errorMsg = "Cannot select more than " + this.data.product.TotalMakes + " makes";
    } else if (this.selectedMakes.length === 0) {
      this.errorMsg = "Select atleast 1 make";
    } else {
      this.errorMsg = "";
    }
  }

  updateMakes() {
    if (this.errorMsg === "" && !this.disableSaveBtn) {
      const params = {
        productId: this.data.product.Product_Id,
        subscriptionId: this.data.product.CompanySubscription_Id,
        companyId: this.data.companyId,
        makes: this.selectedMakes
      };
      this.isProcessing = true;
      this.companyService.updateSubscriptionMakes(params).subscribe(res => {
        try {
          const response = res;
          if (response.status === httpStatusCodes.success) {
            this.commonService.showSnakeBar(response.message);
            this.dialogRef.close({
              status: 'updated'
            });
          } else {
            this.errorMsg = response.message;
          }
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        const response = err;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      });
    }
  }

}

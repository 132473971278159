import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-backup-table',
  templateUrl: './backup-table.component.html',
  styleUrls: ['./backup-table.component.scss']
})
export class BackupTableComponent implements OnInit {

  backupForm: any = {};
  loading: boolean = false;
  formLoader: boolean = false;
  constructor(
    private userService: UserService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getTableData();
  }

  getTableData() {
    this.loading = true;
    this.userService.getBackupTableData().subscribe(res => {
      this.loading = false;
      try {
        const response = res;
        this.backupForm = response.data;
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }

    }, (err) => {
      this.loading = false;
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, () => {

    })
  }

  saveTableData() {
    this.formLoader = true;
    this.userService.updateBackupTableData(this.backupForm).subscribe(res => {
      this.formLoader = false;
      this.commonService.showSnakeBar('Updated');
    }, (err) => {
      this.formLoader = false;
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, () => {

    })
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fof {
  display: block;
  width: 100%;
  margin: 100px 0;
  line-height: 1.6em;
  text-align: center;
}

#fof .hgroup {
  text-transform: uppercase;
}

#fof .hgroup h1 {
  margin-bottom: 25px;
  font-size: 80px;
}

#fof .hgroup h1 span {
  margin-left: 5px;
  padding: 2px;
  border: 1px solid #CCCCCC;
  overflow: hidden;
}

#fof .hgroup h1 span strong {
  display: inline-block;
  padding: 0 20px 20px;
  font-weight: normal;
}

#fof .hgroup h2 {
  font-size: 33px;
}

#fof .hgroup h2 span {
  display: block;
  font-size: 30px;
}

#fof p {
  margin: 25px 0 0 0;
  padding: 0;
  font-size: 16px;
}

#fof p:first-child {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/user-auto/error/error.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":["#fof {\n  display: block;\n  width: 100%;\n  margin: 100px 0;\n  line-height: 1.6em;\n  text-align: center;\n}\n\n#fof .hgroup {\n  text-transform: uppercase;\n}\n\n#fof .hgroup h1 {\n  margin-bottom: 25px;\n  font-size: 80px;\n}\n\n#fof .hgroup h1 span {\n  margin-left: 5px;\n  padding: 2px;\n  border: 1px solid #CCCCCC;\n  overflow: hidden;\n}\n\n#fof .hgroup h1 span strong {\n  display: inline-block;\n  padding: 0 20px 20px;\n  font-weight: normal;\n}\n\n#fof .hgroup h2 {\n  font-size: 33px;\n}\n\n#fof .hgroup h2 span {\n  display: block;\n  font-size: 30px;\n}\n\n#fof p {\n  margin: 25px 0 0 0;\n  padding: 0;\n  font-size: 16px;\n}\n\n#fof p:first-child {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';
import { QuotesService } from 'src/app/services/quotes.service';

export interface PeriodicElement {
  UserQuoteId: string;
  creationDate: string;
  QuoteItems: { [key: string]: any };
  TotalPrice: string;
  Status: string;
  action: string;
}

@Component({
  selector: 'app-company-admin-quotes',
  templateUrl: './company-admin-quotes.component.html',
  styleUrls: ['./company-admin-quotes.component.scss']
})
export class CompanyAdminQuotesComponent implements OnInit {

  displayedColumns: string[] = ['UserQuoteId', 'Created_At', 'QuoteItems', 'TotalPrice', 'Status', 'Action'];
  dataSource = [];
  priceFormat = "1.2-2";
  quoteLoader: boolean = false;
  constructor(
    private commonService: CommonService,
    private quoteService: QuotesService,
    public appSettings: AppSettings
  ) { }

  ngOnInit() {
    this.commonService.setTitle("My Quotes");
    this.getQuoteListing();
  }

  getQuoteListing() {
    this.quoteLoader = true;
    this.quoteService.quoteListing({})
      .subscribe(
        (res) => {
          this.quoteLoader = false;
          const response = res;
          this.dataSource = response.data;
        },
        (err) => {
          this.quoteLoader = false;
          let response = err;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
      );
  }

}

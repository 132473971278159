/**
  * @ngdoc Services
  * @name app.services.common
  * @description
  * The common service is used for getting and setting common vars used across the application
  * */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { environment } from "../../environments/environment";
import { AppSettings } from "../configs/app-settings";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { FormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public azureURL: string = 'https://autopredictmicroservice.blob.core.windows.net/autopredict-images/';
  constructor(
    public snackBar: MatSnackBar,
    public router: Router,
    public titleService: Title,
    public appSettings: AppSettings,
    private date: DatePipe) { }

  /**
  * Get api base url
  * @name getApiBaseUrl
  * @param  none
  * @return {String}
  */
  getApiBaseUrl() {
    return environment.apiUrl;
  }

  /**
  * logging console data
  * @name logging
  * @param  none
  * @return {String}
  */
  logging(data) {
  }


  transErrorResponse(response) {
    response = response ? response.json() : {};
    return response;
  }

  /**
  * Show snakebar message
  * @name showSnakeBar
  * @param  msg{String,action:action to show}
  * @return none
  */
  showSnakeBar(msg, action = 'Done') {
    this.snackBar.open(msg, action, {
      duration: 5000
    });
  }


  /*
  * @name diffCheck
  * @param source:Array<string>,Array<string>
  * @description Get only selected value that are currently present in all filters
  * @return Array
  */
  diffCheck(source, dest) {
    //console.log(source, dest, '-----print source and the dest')
    let temp = []
    if (dest) {
      dest.forEach(element => {
        temp.push(element.filter)
      });

      // console.log(source, temp, 'source and the temp here')
      if (source['filter']) {
        return source.filter(i => { return temp.indexOf(i) != -1; });
      } else {
        return []
      }
    } else {
      return temp
    }
  };

  /*
  * @name mergeArr
  * @param a1,a2
  * @description merge tow array remove duplicate one
  * @return none
  */
  mergeArr(a1: Array<any> = [], a2: Array<any> = []) {
    var hash = {};
    var arr = [];
    for (var i = 0; i < a1.length; i++) {
      if (hash[a1[i]] !== true) {
        hash[a1[i]] = true;
        arr[arr.length] = a1[i];
      }
    }
    for (var i = 0; i < a2.length; i++) {
      if (hash[a2[i]] !== true) {
        hash[a2[i]] = true;
        arr[arr.length] = a2[i];
      }
    }
    return arr;
  }

  /*
  * @name uniqueArray
  * @param a
  * @description get total unique records
  * @return none
  */
  uniqueArray(a: Array<any> = [], len: boolean = true): any {
    let un = a.filter(function (item, pos) {
      return a.indexOf(item) == pos;
    })
    if (len == true) {
      return un.length
    } else {
      return un
    }
  }

  /*
  * @name getApiParams
  * @param Object = {filter:'string',filterVal:Array<any>}
  * @description get api params for vehicle filter based on selected filters
  * @return none
  */
  getFilterApiParams(controlsArr: UntypedFormGroup, filterArray: Array<any>, eventData: { filter: string, filterVal: Array<any> }) {
    //console.log(event)
    let obj: any = { filter: eventData.filter, type: 'bi' }
    // Filter out only selected values from filter list to api
    Object.keys(controlsArr.controls).forEach(key => {
      // current selected filter matched
      if (eventData.filter == key) {
        //console.log(key, 'key')
        //console.log(eventData.filterVal.length, filterArray[key].length)
        //if (eventData.filterVal.length == filterArray[key].length) {
        //obj[key] = '';
        //} else {
        let separator = ','
        if (key == 'VFactsGroups') {
          separator = '|'
        } else {
          separator = ','
        }
        if (Array.isArray(eventData.filterVal)) {
          obj[eventData.filter] = eventData.filterVal.join(separator)
        } else {
          obj[eventData.filter] = eventData.filterVal
        }

        //}
      } else {
        //console.log(obj[key], 'obj[key]')
        try {
          let diff;
          if (Array.isArray(controlsArr.controls[key].value)) {
            // console.log(controlsArr.controls[key].value, '-------------print thr values here')
            diff = this.diffCheck(controlsArr.controls[key].value, filterArray[key])
            //if (diff.length == filterArray[key].length) {
            //obj[key] = '';
            //} else {
            //console.log(diff, 'diff')
            if (key == 'VFactsGroups') {
              separator = '|'
            } else {
              separator = ','
            }
            obj[key] = diff.length > 0 ? diff.join(separator) : ''
            //console.log(obj[key], '------print the obj here')
            //}
          }
          else {
            diff = controlsArr.controls[key].value
            obj[key] = diff
          }
        }
        catch (e) {

        }
      }
    })

    // assigned selected filter values to api directly
    let separator = ','
    if (eventData.filter == 'VFactsGroups') {
      separator = '|'
    } else {
      separator = ','
    }
    return obj;
  }


  /*
  * @name getInitialFilterApiParams
  * @param
  * @description get initial api params
  * @return none
  */
  getInitialFilterApiParams(filterArray: any, allFilters: Array<any> = []): any {
    //console.log(event)
    let obj: any = { type: 'bi' }
    // Filter out only selected values from filter list to api
    let separator = ',';
    if (allFilters) {
      Object.keys(filterArray).forEach(key => {
        let diff = this.diffCheck(filterArray[key], allFilters[key])
        //console.log(diff, 'diff', key, filterArray[key])
        try {
          //if (diff.length == filterArray[key].length) {
          //obj[key] = '';
          //} else {
          //console.log('diff', diff)
          if (key == 'recentlyAdded') {
            obj[key] = filterArray[key]
          } else {
            if (key == 'VFactsGroups') {
              separator = '|'
            } else {
              separator = ','
            }
            if (Array.isArray(diff)) {
              obj[key] = diff.length > 0 ? diff.join(separator) : ''
            }
            else {
              //console.log(diff, 'diff')
              obj[key] = diff
            }
          }
          //}
        } catch (e) { }
      })
    } else {
      Object.keys(filterArray).forEach(key => {
        // current selected filter matched
        if (Array.isArray(filterArray[key])) {
          if (key == 'recentlyAdded') {
            obj[key] = filterArray[key]
          } else {
            if (key == 'VFactsGroups') {
              separator = '|'
            } else {
              separator = ','
            }
            obj[key] = filterArray[key].length > 0 ? filterArray[key].join(separator) : ''
          }
        } else {
          obj[key] = filterArray[key]
        }
      })
    }
    return obj;
  }


  /**
  * Showing common error message
  * @name commonSnakeBar
  * @param  none
  * @return none
  */
  commonSnakeBar() {
    this.showSnakeBar('An error occurred while fetching the response. Please try again.', 'Done');
  }

  /**
  * navigate user to given url
  * @name navigate
  * @param  url {String}
  * @return none
  */
  navigate(url) {
    this.router.navigate([url]);
  }

  navigateSkipLocation(url) {
    this.router.navigateByUrl(url, { skipLocationChange: true });
  }

  /**
  * set page title
  * @name title
  * @param  {String}
  * @return none
  */
  setTitle(title) {
    let appName = this.appSettings.APP_NAME;
    let pageTitle = appName;
    if (title.length > 0) {
      pageTitle = pageTitle + " | " + title;
    }
    this.titleService.setTitle(pageTitle);
  }

  /**
  * Send user to given route
  * @name sendUserTo
  * @param  link:String,params:Object
  * @return None
  */
  sendUserTo(link = 'dashboard', params = {}) {
    this.router.navigate([link], { queryParams: params })
  }

  /**
  * Transform date
  * @name transFormDate
  * @param  date, format(Date Format)
  * @return String
  */
  transFormDate(date, format = null) {
    let dateFormat = this.appSettings.COMMON_DATE_FORMAT;
    if (format != null) {
      dateFormat = format;
    }
    return this.date.transform(date, dateFormat);
  }


  setLocalStorage(key, value) {
    window.localStorage.setItem(DM_PREFIX + key, value);
  }

  removeLocalStorage(key) {
    window.localStorage.removeItem(DM_PREFIX + key);
  }

  getLocalStorage(key) {
    return window.localStorage.getItem(DM_PREFIX + key);
  }


  getSessionStorage(key, parse = true) {
    if (window.sessionStorage.getItem(key)) {
      let data = window.sessionStorage.getItem(key)
      if (parse == true) {
        try {
          return JSON.parse(data);
        } catch (e) {
          return false;
        }
      } else {
        return data
      }
    } else {
      return false
    }
  }


  setSessionStorage(key, data = null, parse = true) {
    let seData = parse ? JSON.stringify(data) : data
    //console.log(key, 'key')
    window.sessionStorage.setItem(key, seData)
  }

  clearSessionStorage(key) {
    //console.log(key, 'clear key')
    window.sessionStorage.removeItem(key)
  }


  getCarSelectionLength(): number {
    let list = `${location.pathname.replace(/[/-]/g, '_')}_sel_counter`;
    let len = this.getSessionStorage(list, false)
    if (len != false) {
      return len
    } else {
      return 0
    }
  }

  currentDateFormat = (date = null) => {
    if (date == null) {
      var currentDate: any = new Date();
    } else {
      var currentDate: any = new Date(date);
    }
    var dd: any = currentDate.getDate();

    var mm: any = currentDate.getMonth() + 1;
    var yy: any = currentDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    currentDate = dd + '-' + mm + '-' + yy;
    //console.log(currentDate);
    return currentDate
  }
  setCarSelectionLength(len) {
    let list = `${location.pathname.replace(/[/-]/g, '_')}_sel_counter`;
    this.setSessionStorage(list, len, false)
  }

  clearCarSelectionLength() {
    let list = `${location.pathname.replace(/[/-]/g, '_')}_sel_counter`;
    this.clearSessionStorage(list)
  }

  getImageEssentials(file: File): Observable<any> {
    return Observable.create(observer => {
      let newImage: any = new Image();
      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = function (loadEvent: any) {
        newImage.src = loadEvent.target.result;
        newImage.onload = function () {
          observer.next({
            status: true,
            width: newImage.width,
            height: newImage.height,
            image: newImage
          });
        }
      }
      fileReader.readAsDataURL(file);
    })
  }

  compressImage(image, fileType, maxWidth, imageWidth, imageHeight): Observable<any> {
    return Observable.create(observer => {
      let originalWidth = imageWidth;
      let originalHeight = imageHeight;
      let ratio = originalHeight / originalWidth;
      let compressedImage = [];
      let blobs = [];
      for (let i = 0; i < maxWidth.length; i++) {
        if (originalWidth > maxWidth[i]) {
          originalWidth = maxWidth[i];
          originalHeight = ratio * maxWidth[i];
        }
        let canvas = document.createElement('canvas');
        compressedImage[i] = new Image();
        canvas.width = originalWidth;
        canvas.height = originalHeight;
        canvas.getContext("2d").drawImage(image, 0, 0, originalWidth, originalHeight);
        compressedImage[i].src = canvas.toDataURL(fileType, 1);
        this.dataURLtoBlob(compressedImage[i].src).subscribe(blob => {
          blobs.push(blob.data);
        })
      }
      observer.next({
        status: true,
        data: {
          images: compressedImage,
          blobs: blobs
        }
      });
    })
  }

  dataURLtoBlob(dataURI): Observable<any> {
    return Observable.create((observer) => {
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      observer.next({
        data: blob
      });
    });

  }

  checkAllEmpty(obj) {
    return Object.keys(obj).every(x => (obj[x] == null || obj[x] == ''));
  }


  roundToXDigits(value, digits = 2) {
    /*if (!digits) {
      digits = 2;
    }*/
    value = value * Math.pow(10, digits);
    value = Math.round(value);
    value = value / Math.pow(10, digits);
    return value;
  }

  toggleIndex(vehicle = null, type = 'class') {
    try {
      if (vehicle) {
        //if accepted index is found
        if ('accepted' in vehicle) {
          if (type == 'class') {
            if (vehicle['accepted'] == 0 || vehicle['accepted'] == 2) {
              return ""
            }
            return "double_col"
          } else if (type == 'toggle') {
            if (vehicle['accepted'] == 0) {
              return false
            }
            return true
          }
          else if (type == 'active') {
            if (vehicle['accepted'] == 1 || vehicle['accepted'] == 0) {
              return true
            } else {
              return false
            }
          }
          else if (type == 'rejected') {
            if (vehicle['accepted'] == 2) {
              return true
            } else {
              return false
            }
          } else if (type == 'accepted') {
            if (vehicle['accepted'] == 1) {
              return true
            } else {
              return false
            }
          }
        } else {
          if (type == 'class') {
            return "double_col"
          } else if (type == 'toggle') {
            return true
          } else if (type == 'active') {
            return true
          } else if (type == 'rejected') {
            return false
          } else if (type == 'accepted') {
            return true
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  ABNValidation(val) {
    //val = val.replace(/[^0-9]/g, '');
    let weights = new Array(10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19);
    if (val.length === 11) {
      let sum = 0;
      weights.forEach(function (weight, position) {
        let digit = val[position] - (position ? 0 : 1);
        sum += weight * digit;
      });
      return sum % 89 == 0;
    }
    return false;
  }

  validateIsEmpty(input, list: Array<String> = []) {
    if ((typeof input).toLowerCase() == 'boolean') {
      return false;
    }
    else if (input != undefined && input != null && input != '') {
      if (input instanceof Date) {
        return false;
      }
      else if (Array.isArray(input) && input.length != 0) {
        return false;
      }
      else if ((typeof input).toLowerCase() == 'object') {
        let isedit = false;
        Object.keys(input).map(key => {
          if (list.indexOf(key.toString()) == -1 && (input[key] != undefined && input[key] != null && input[key] != '')) {
            isedit = true;
          }
        });
        if (isedit) {
          return false;
        }
        else {
          return true;
        }
      }
      else if ((typeof input).toLowerCase() == 'string' && input.length != 0) {
        return false;
      }
      else if ((typeof input).toLowerCase() == 'number') {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }
}

export const TOKEN_NAME = "api_token";
export const TOKEN_HEADER_KEY = "Authorization";
export const AUTH_PREFIX = "Bearer";
export const USER_STORAGE_KEY = "user";
export const USER_ROLE_STORAGE_KEY = "roleName";
export const USER_WRITE_PERMISSION = "write";
export const ROLE_KEY = 'roles';
export const DM_PREFIX = 'AUTP_PORTAL_';
export const AUTP_PORTAL_USER = 'AUTP_PORTAL_USER';


import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { AppSettings } from 'src/app/configs/app-settings';
import { environment } from 'src/environments/environment';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'products-dropdown',
  templateUrl: './products-dropdown.component.html',
  styleUrls: ['./products-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsDropdownComponent implements OnInit {

  public urlWithToken: string = "";
  public datiumProducts: Array<any> = [];
  public productsAccess: Array<any> = [];
  private accessToken: string = "";
  public companyId = null;
  public queryParams = null;
  public hasTruckValBikeValAccess: boolean = false;
  public isMenuLoading: boolean = false;
  @Input() viewFrom: string = 'desktop';
  @Input() showMobileMenu: boolean = false;
  constructor(
    public router: Router,
    public jwtService: JwtService,
    public roleCheckService: RoleCheckerService,
    public appSettings: AppSettings,
    private dataStoreService: DataStoreService,
    private activatedRoute: ActivatedRoute,
  ) {
    if (this.roleCheckService.isSuperAdmin()) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.companyId = params['companyId'] ? params['companyId'] : null;
      });
    } else {
      this.companyId = this.jwtService.getCompanyId();
    }
    if (this.jwtService.getUserProductsAccess()) {
      this.productsAccess = this.jwtService.getUserProductsAccess();
    }
    if (this.jwtService.getAccessToken()) {
      this.accessToken = this.jwtService.getAccessToken();
    }
    this.dataStoreService.getUserProductAccess().subscribe(res => {
      if (res === true) {
        if (this.jwtService.getUserProductsAccess()) {
          this.productsAccess = this.jwtService.getUserProductsAccess();
          this.assignProductAccess();
        }
      }
    })
  }

  ngOnInit(): void {
    this.assignProductAccess();
  }

  assignProductAccess() {
    this.datiumProducts = [];
    if (this.productsAccess.length) {
      let instaProducts = [];
      const hasInstantValAccess = this.productsAccess.includes(this.appSettings.InstantVal);
      if (!hasInstantValAccess) {
        if (this.productsAccess.includes(this.appSettings.SalvageVal)) {
          const index = this.productsAccess.indexOf(this.appSettings.SalvageVal);
          this.productsAccess.splice(index, 1);
          this.productsAccess.splice(0, 0, this.appSettings.SalvageVal);
        }
      }
      this.productsAccess.forEach((productId, index) => {
        if (index === 0 && (productId === this.appSettings.InstantVal || productId === this.appSettings.SalvageVal)) {
          if (productId === this.appSettings.InstantVal) {
            instaProducts.push(
              {
                icon: 'directions_car',
                name: 'Car',
                url: environment.carvalUrl + "login?token=" + this.accessToken
              }
            );
            if (this.productsAccess.includes(this.appSettings.TruckVal)) {
              instaProducts.push(
                {
                  icon: 'local_shipping',
                  name: 'Truck',
                  url: environment.truckvalUrl + "login?token=" + this.accessToken
                }
              );
            }
            if (this.productsAccess.includes(this.appSettings.BikeVal)) {
              instaProducts.push(
                {
                  icon: 'motorcycle',
                  name: 'Motorcycle',
                  url: environment.bikevalUrl + "login?token=" + this.accessToken
                }
              );
            }
            if (this.productsAccess.includes(this.appSettings.SalvageVal)) {
              instaProducts.push(
                {
                  icon: 'auto_towing',
                  name: 'Salvage',
                  url: environment.salvagevalUrl + "login?token=" + this.accessToken
                }
              );
            }
          } else if (productId === this.appSettings.SalvageVal) {
            instaProducts.push(
              {
                icon: 'auto_towing',
                name: 'Salvage',
                url: environment.salvagevalUrl + "login?token=" + this.accessToken
              }
            );
          }
          this.datiumProducts.push(
            {
              category: 'Valuation',
              products: instaProducts
            }
          );
        }

        if (productId === this.appSettings.AssessVal) {
          this.datiumProducts.push(
            {
              category: 'AssessVal',
              products: [
                {
                  icon: 'verified_user',
                  name: 'AssessVal',
                  url: environment.assessvalUrl + "login?token=" + this.accessToken
                }
              ]
            }
          );
        }

        if (productId === this.appSettings.AutoPredict) {
          this.datiumProducts.push(
            {
              category: 'Residual Values',
              products: [
                {
                  icon: 'manage_search',
                  name: 'AutoPredict',
                  url: environment.autopredictUrl + "single-sign?token=" + this.accessToken
                }
              ]
            }
          );
        }

        if (productId === this.appSettings.PriceMyTruck) {
          this.datiumProducts.push(
            {
              category: 'Sold Prices',
              products: [
                {
                  icon: 'sell',
                  name: 'PriceMyTruck',
                  url: environment.priceMyTruckUrl + "login?token=" + this.accessToken
                }
              ]
            }
          );
        }


      });

      this.moveAssessValToSecondPosition();

    }
  }

  redirect(url) {
    if (url === '/') {
      this.router.navigate(['/']);
    } else {
      window.location.href = url;
    }
  }

  moveAssessValToSecondPosition() {
    // Find the AssessVal category
    const assessvalCategoryIndex = this.datiumProducts.findIndex(category => category.category === "AssessVal");

    if (assessvalCategoryIndex > -1) {
      // Remove the AssessVal category from its current position
      const assessvalCategory = this.datiumProducts.splice(assessvalCategoryIndex, 1)[0];

      // Insert it into the second position (index 1)
      this.datiumProducts.splice(1, 0, assessvalCategory);
    }
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-otp-step .auth-without-login-verify-otp {
  margin: 0 0 40px 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/my-account/update-mobile/update-mobile.component.scss"],"names":[],"mappings":"AACI;EACI,6BAAA;EACA,2BAAA;EACA,qBAAA;AAAR","sourcesContent":[".verify-otp-step{\n    .auth-without-login-verify-otp{\n        margin: 0 0 40px 0 !important;\n        box-shadow: none !important;\n        padding: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

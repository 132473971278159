import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnInit {

  formError = {};
  validations = [
    Validators.required, Validators.minLength(3) //, Validators.maxLength(40), Validators.pattern("^[0-9A-Za-z_-][A-Za-z0-9 _-]*$")
  ]
  paymentForm: UntypedFormGroup = new UntypedFormGroup({
    'billing_address': new UntypedFormControl(null, this.validations),
    'state': new UntypedFormControl(null, this.validations),
    'city': new UntypedFormControl(null, this.validations),
  });
  Product_Id = null;
  loader: boolean = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BillingAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public productData,
    private productService: ProductService,
    private commonService: CommonService
  ) {
    // console.log(this.productData);
    this.Product_Id = this.productData['Product_Id']
  }

  ngOnInit() {
  }


  generateTrial() {
    let params = {
      Product_Id: this.Product_Id,
      ...this.paymentForm.value
    };
    this.loader = true;
    this.productService.createTrialSub(params).subscribe(res => {
      this.loader = false;
      try {
        const response = res;
        this.commonService.showSnakeBar(response.message);
        this.dialogRef.close(response.data);
      }
      catch (e) {
      }
    }, (err) => {
      this.loader = false;
      try {
        let response = err;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }
      catch (e) {
      }
    });
  }


  cancel() {
    this.dialogRef.close();
  }

  formValidate(formGroup: UntypedFormGroup) {
    const el: HTMLElement | null = document.getElementById("paymentForm");
    if (el) {
      setTimeout(() =>
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
    }
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }
}

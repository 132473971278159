import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js'; // Import zone.js

if (environment.production) {
  enableProdMode();
}
if (environment.script) {

}

const gtmTagDevProp = 'GTM-WVCK92V';
const gtmTagTestProp = 'GTM-WLD78TD';
const gtmTagProdProp = 'GTM-M45TBH2';

const getGaScriptData = () => {

  if (environment.name == 'local') {
    return false;
  }
  let gtmCode = gtmTagDevProp;
  if (environment.name == 'test') {
    gtmCode = gtmTagTestProp;
  } else if (environment.name == 'prod') {
    gtmCode = gtmTagProdProp;
  }

  const scriptGtm = document.createElement('script');
  scriptGtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmCode}');`;
  document.head.appendChild(scriptGtm);

  const noscriptBody = document.createElement('noscript');
  noscriptBody.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(noscriptBody);
};

getGaScriptData();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

/*
  *
  * @ngdoc Services
  * @name app.services.CompanyService
  * @description
  * The Company Service is used for handling the request related to Company
  *
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  /*
  * @name getCompanyListing
  * @param
  * @description get Company Listing
  * @return Observable
  */
  getCompanyListing(filter) {
    return this.commonHttpService.get(this.apiUrls.company.list, filter);
  }

  /*
  * @name resentCompanyInvite
  * @param
  * @description Resend company invite
  * @return Observable
  */
  resentCompanyInvite(params = {}) {
    return this.commonHttpService.post(this.apiUrls.company.resendInvite, params);
  }

  /*
  * @name addCompany
  * @param
  * @description add Company
  * @return Observable
  */
  addCompany(data) {
    return this.commonHttpService.post(this.apiUrls.company.addCompany, data)
  }

  /*
  * @name companySignup
  * @param
  * @description company signup
  * @return Observable
  */
  companySignup(data) {
    return this.commonHttpService.post(this.apiUrls.company.signup, data)
  }

  /*
  * @name addSubscription
  * @param
  * @description add Company subscription and product
  * @return Observable
  */
  addSubscription(data) {
    return this.commonHttpService.post(`${this.apiUrls.company.company}/${this.apiUrls.company.addSubscription}`, data)
  }


  /*
  * @name updateSubscription
  * @param
  * @description update Company subscription and product
  * @return Observable
  */
  updateSubscription(data) {
    return this.commonHttpService.post(`${this.apiUrls.company.company}/${this.apiUrls.company.updateSubscription}`, data)
  }

  /*
  * @name updateCompany
  * @param
  * @description update Company
  * @return Observable
  */
  updateCompany(data) {
    return this.commonHttpService.post(this.apiUrls.company.updateCompany, data)
  }

  /*
  * @name getCompanyDetails
  * @param
  * @description Get company details
  * @return Observable
  */
  getCompanyDetails(id) {
    return this.commonHttpService.get(this.apiUrls.company.getCompany, { id: id })
  }

  /*
  * @name companyStatus
  * @param
  * @description company Status
  * @return Observable
  */
  companyStatus(data) {
    return this.commonHttpService.post(this.apiUrls.company.companyStatus, data)
  }

  /*
  * @name getCompanySubscription
  * @param
  * @description get company subscription
  * @return Observable
  */
  getCompanySubscription(companyId: number) {
    return this.commonHttpService.post(`${this.apiUrls.company.company}/${this.apiUrls.company.subscription}`, { companyId: companyId })
  }

  /*
  * @name removeCompanySubscription
  * @param
  * @description remove company subscription
  * @return Observable
  */
  removeCompanySubscription(companyId: number, Product_Id: number) {
    return this.commonHttpService.post(`${this.apiUrls.company.company}/${this.apiUrls.company.removeSubscription}`, { companyId: companyId, Product_Id: Product_Id })
  }

  /*
  * @name createConsumer
  * @param
  * @description create consumer
  * @return Observable
  */
  createConsumer(companyId: number) {
    return this.commonHttpService.post(`${this.apiUrls.company.company}/${this.apiUrls.company.createConsumer}`, { companyId: companyId })
  }

  /*
  * @name acceptProductTerms
  * @param
  * @description accept terms and condition for a product
  * @return Observable
  */
  acceptProductTerms(data) {
    return this.commonHttpService.post(this.apiUrls.company.acceptTerms, data)
  }

  /*
  * @name checkABN
  * @param
  * @description validate ABN number
  * @return Observable
  */
  checkABN(abn_number: string): Observable<any> {
    return this.commonHttpService.post(this.apiUrls.company.abn, { abn_number: abn_number })
  }

  /*
  * @name checkBusinessEmail
  * @param
  * @description validate user business email
  * @return Observable
  */
  checkBusinessEmail(email: string): Observable<any> {
    return this.commonHttpService.post(this.apiUrls.company.bEmail, { email: email })
  }

  /*
  * @name updateSubscriptionMakes
  * @param
  * @description to update product makes
  * @return Observable
  */
  updateSubscriptionMakes(params) {
    return this.commonHttpService.post(this.apiUrls.company.updateSubscriptionMakes, params);
  }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    public appSettings: AppSettings,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    this.commonService.setTitle('Terms & Condition');
    this.renderer.addClass(document.body, 'outer-pages');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'outer-pages');
  }

}

import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class HasTokenResolver {
  constructor(
    private jwtService: JwtService,
    private router: Router
  ) { }
  resolve(): any {
    if (this.jwtService.IsAuthUser()) {
      this.router.navigate(["/dashboard"]);
    }
  }
}

@Injectable()
export class AppRoutingAccess {
  constructor(
    private jwtService: JwtService,
    private router: Router
  ) { }
  resolve(): any {
    if (!this.jwtService.IsAuthUser()) {
      this.router.navigate(["/login"]);
    }
  }
}

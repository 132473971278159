import { Injectable } from '@angular/core';
import { AUTP_PORTAL_USER } from './common.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateSubUserComponent } from '../my-account/update-sub-user/update-sub-user.component';

@Injectable({
  providedIn: 'root'
})

export class JwtService {

  constructor(
    private router: Router,
    private dialogRef: MatDialog
  ) { }

  IsAuthUser(): boolean {
    if (window.localStorage[AUTP_PORTAL_USER] && JSON.parse(window.localStorage[AUTP_PORTAL_USER]).authToken) {
      return true;
    }
    else {
      return false;
    }
  }

  setAccessDetail(obj: any) {
    window.localStorage[AUTP_PORTAL_USER] = JSON.stringify(obj);
    this.router.navigate(["/dashboard"]);
  }

  getAccessToken() {
    return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).authToken
  }

  deleteLocalStorage() {
    let userRole = this.getUserRole()

    window.localStorage.clear();
    //window.location.href = '/login'
    this.dialogRef.closeAll()
    let url = ["/login"];
    if (userRole == 1 || userRole == 4) {
      url = ["/admin/login"]
    }
    this.router.navigate(url);
  }

  hasWriteAccess(): boolean {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      const result = JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Permission_Id == 1 ? true : false;
      return result;
    }
  }

  getUserPermissions(): Array<any> {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      if (JSON.parse(window.localStorage[AUTP_PORTAL_USER]).permissions) {
        let permissions = JSON.parse(window.localStorage[AUTP_PORTAL_USER]).permissions;
        if (permissions) {
          return permissions
        } else {
          return []
        }
      }
      else {
        return [];
      }
    }
  }


  getUserRole() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Role_Id;
    }
  }

  getCompanyId() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Company_Id;
    }
  }

  getUserEmail() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Email;
    }
  }

  getUserName() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Name;
    }
  }

  getUserCompanyName() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Company_Name;
    }
  }

  getNameLetter() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Name.substring(0, 1);
    }
  }

  getUserPhone() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).PhoneNo;
    }
  }

  getUserPhoneStatus() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).phoneStatus;
    }
  }

  getProfileImage() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      if (JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Profile_Photo) {
        let photo = JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Profile_Photo;
        if (photo) {
          return photo
        } else {
          return ''
        }
      }
      else {
        return '';
      }
    }
  }

  getCompanyImage() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      if (JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Company_Photo) {
        let photo = JSON.parse(window.localStorage[AUTP_PORTAL_USER]).Company_Photo;
        if (photo) {
          return photo
        } else {
          return ''
        }
      }
      else {
        return '';
      }
    }
  }

  getProfileThumbImage() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      if (JSON.parse(window.localStorage[AUTP_PORTAL_USER]).thumbnailUrl) {
        return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).thumbnailUrl;
      }
      else {
        return '';
      }
    }
  }

  updateUserDetails(userData) {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      let authObject = JSON.parse(window.localStorage[AUTP_PORTAL_USER]);
      if (userData.Name) { authObject['Name'] = userData.Name; }
      if (userData.Email) { authObject['Email'] = userData.Email; }
      if (userData.jobTitle) { authObject['jobTitle'] = userData.jobTitle; }
      if (userData.PhoneNo) { authObject['PhoneNo'] = userData.PhoneNo; }
      if (userData.Profile_Photo) { authObject['Profile_Photo'] = userData.Profile_Photo; }
      if (userData.thumbnailUrl) { authObject['thumbnailUrl'] = userData.thumbnailUrl }
      window.localStorage[AUTP_PORTAL_USER] = JSON.stringify(authObject);
    }
  }

  getUserId() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).User_Id;
    }
  }

  getUserDetails() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]);
    }
  }

  updateUserPhoneAndStatus(phone: string, status: string) {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      let authObject = JSON.parse(window.localStorage[AUTP_PORTAL_USER]);
      authObject['PhoneNo'] = phone;
      authObject['phoneStatus'] = status;
      window.localStorage[AUTP_PORTAL_USER] = JSON.stringify(authObject);
    }
  }

  updateUserProductsAccess(products: Array<number>) {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      let authObject = JSON.parse(window.localStorage[AUTP_PORTAL_USER]);
      authObject['ProductsAccess'] = products;
      window.localStorage[AUTP_PORTAL_USER] = JSON.stringify(authObject);
    }
  }

  getUserProductsAccess() {
    if (window.localStorage[AUTP_PORTAL_USER]) {
      return JSON.parse(window.localStorage[AUTP_PORTAL_USER]).ProductsAccess;
    }
  }
}

import { Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../../services/jwt.service';
import { AppSettings } from '../../configs/app-settings';
import { httpStatusCodes } from 'src/app/configs/httpStatusCodes';
import { OtpVerificationComponent } from 'src/app/ap-common/otp-verification/otp-verification.component';
import { VerificationService } from 'src/app/services/verification.service';
import { IOtpVerificationParams } from 'src/app/models/otp-verification-data.model';
import { verificationTypes } from 'src/app/models/verification-types.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ResetPasswordComponent implements OnInit {

  public setPassword: UntypedFormGroup;
  public isProcessing: boolean = false;
  public resetToken: string = '';
  public inviteToken: string = '';
  public formError: any = {};

  public validatingTokenLoader: boolean = false;
  public phoneNumberForm: UntypedFormGroup;
  public phoneValidatedStatus: boolean = false;
  public verificationLoader: boolean = false;
  public otpRecordId: number = null;
  public phoneVerificationData: IOtpVerificationParams;
  public showOtpComponent: boolean = false;
  public countryCode: string = '+61';
  @ViewChild('otpComponent', { static: false }) otpCmp: OtpVerificationComponent;
  constructor(
    private renderer: Renderer2,
    private userSerice: UserService,
    private commonService: CommonService,
    private jwtService: JwtService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public appSettings: AppSettings,
    private verificationService: VerificationService

  ) {
    this.renderer.addClass(document.body, 'login_bg');
    this.activatedRoute.queryParams.subscribe(params => {
      this.resetToken = params["resetToken"];
      this.inviteToken = params["inviteToken"];
    });
    this.commonService.setTitle('Reset Password')
  }

  ngOnInit() {
    if (this.inviteToken) {
      const param = {
        inviteToken: this.inviteToken
      }
      this.validatingTokenLoader = true;
      this.userSerice.validateInviteToken(param).subscribe(res => {
        try {
          const response = res;
          if (response.status === httpStatusCodes.success) {
            this.countryCode = response.data.countryCode;
            if (response.data.phoneStatus === 'verified') {
              this.phoneValidatedStatus = true;
              this.showOtpComponent = false;
            }
          }
          this.commonService.showSnakeBar(response.message);
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.validatingTokenLoader = false;
      }, (err) => {
        let response = err;
        this.validatingTokenLoader = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.router.navigateByUrl('/');
      }, () => {
        this.validatingTokenLoader = false;
      })
    }
    this.initForm();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login_bg');
  }

  initForm() {
    if (this.resetToken) {
      this.setPassword = new UntypedFormGroup({
        'password': new UntypedFormControl('null', [
          Validators.required, Validators.maxLength(40), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
        ]),
        'confirmPassword': new UntypedFormControl(null, [
          Validators.required, Validators.maxLength(40), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
        ]),
        'token': new UntypedFormControl(this.resetToken)
      });
    }
    else if (this.inviteToken) {
      this.phoneNumberForm = new UntypedFormGroup({
        'phone': new UntypedFormControl(null, [
          Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)
        ])
      });
      this.setPassword = new UntypedFormGroup({
        'password': new UntypedFormControl(null, [
          Validators.required, Validators.maxLength(40), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
        ]),
        'confirmPassword': new UntypedFormControl(null, [
          Validators.required, Validators.maxLength(40), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).*$")
        ])
      });
    }
    else {
      this.router.navigate(["/login"])
    }
  }

  setUserPassword(form) {
    if (form.valid) {
      if (form.value.password !== form.value.confirmPassword) {
        this.commonService.showSnakeBar("Password should be identical");
      }
      else {
        this.isProcessing = true;
        this.formError = {}
        const params = {
          password: form.value.password,
          confirmPassword: form.value.confirmPassword,
          inviteToken: this.inviteToken,
          phone: this.phoneNumberForm.value.phone,
          otpId: this.otpRecordId
        }
        this.userSerice.setPassword(params).subscribe(res => {
          try {
            const response = res;
            if (response.status === httpStatusCodes.success) {
              this.jwtService.setAccessDetail(response.data);
              this.router.navigate(["/login"]);
              this.commonService.showSnakeBar(response.message);
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
            }
          }
          catch (e) {
            this.commonService.commonSnakeBar();
          }
          this.isProcessing = false;
        }, (err) => {
          const error = err;
          this.isProcessing = false;
          if (error.message) {
            if ('errors' in error.errors) {
              this.handleServerFormError(error.errors)
            }
            this.commonService.showSnakeBar(error.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }, () => {
          this.isProcessing = false;
        })
      }
    }
    else {
      this.formValidate(form);
    }
  }

  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors
* @return none
*/
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.setPassword.contains(key)) {
          let control = this.setPassword.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  resetUserPassword(form) {
    if (form.valid) {
      if (form.value.password !== form.value.confirmPassword) {
        this.commonService.showSnakeBar("Password should be identical");
      }
      else {
        this.isProcessing = true;
        this.userSerice.resetPassword(form.value).subscribe(res => {
          try {
            const response = res;
            if (response.status == 200) {
              this.router.navigate(["/login"]);
              this.commonService.showSnakeBar(response.message);
            }
            else {
              this.commonService.showSnakeBar("Something went wrong");
            }
          }
          catch (e) {
            this.commonService.commonSnakeBar();
          }
          this.isProcessing = false;
        }, (err) => {
          const error = err;
          this.isProcessing = false;
          if (error.message) {
            if ('errors' in error.errors) {
              this.handleServerFormError(error.errors)
            }
            this.commonService.showSnakeBar(error.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }, () => {
          this.isProcessing = false;
        })
      }
    }
    else {
      this.formValidate(form);
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  sendOtp(form) {
    if (this.phoneNumberForm.valid) {
      const params = {
        verificationMethod: 'phone',
        phone: this.phoneNumberForm.value.phone,
        countryCode: this.countryCode
      };
      this.verificationLoader = true;
      this.verificationService.sendVerificationOtp(params, verificationTypes.userInvite).subscribe(
        (res) => {
          const response = res;
          this.verificationLoader = false;
          try {
            if (response.status === httpStatusCodes.success) {
              this.otpRecordId = response.data.otpId;
              this.phoneVerificationData = {
                otpId: response.data.otpId,
                phone: form.value.phone,
                email: '',
                verificationMethod: 'phone',
                countryCode: this.countryCode
              };
              this.showOtpComponent = true;
              setTimeout(() => {
                this.otpCmp.resetTimer(60);
              }, 1000);
            }
          } catch (e) {
          }
        },
        (err) => {
          const error = err;
          this.verificationLoader = false;
          if (error.message) {
            this.handleServerFormError(error.errors)
            this.commonService.showSnakeBar(error.message);
          } else {
            this.commonService.showSnakeBar('Invalid number. Please enter a valid phone number.');
          }
        },
        () => { }
      );
    }
  }

  changePhoneNumber(event: string) {
    if (event === 'change') {
      this.phoneValidatedStatus = false;
      this.showOtpComponent = false;
    }
  }

  otpResponse(event) {
    if (event.status === 'true') {
      //show set password form
      this.phoneValidatedStatus = true;
      this.showOtpComponent = false;
    }
  }

  openEmailBrowser() {
    window.open('mailto:' + environment.supportEmail);
  }
}



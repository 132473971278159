import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AppSettings } from 'src/app/configs/app-settings';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from '../../services/role-checker.service'
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MenuComponent implements OnInit {

  public menu_items;
  public currentRoute: string = "";
  public productsAccess: Array<number> = [];
  constructor(
    private roleCheckService: RoleCheckerService,
    private router: Router,
    public jwtService: JwtService,
    public appSettings: AppSettings,
    private dataStoreService: DataStoreService,
  ) {
    this.currentRoute = this.router.url;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit() {
    if (this.jwtService.getUserProductsAccess()) {
      this.productsAccess = this.jwtService.getUserProductsAccess();
      this.updateMenuList();
    }
    this.dataStoreService.getUserProductAccess().subscribe(res => {
      if (res === true) {
        if (this.jwtService.getUserProductsAccess()) {
          this.productsAccess = this.jwtService.getUserProductsAccess();
          this.updateMenuList();
        }
      }
    });
  }

  updateMenuList() {
    if (this.roleCheckService.isCompanyUser()) {
      this.menu_items = this.companyUserTiles().items;
    }
    else if (this.roleCheckService.isCompanyOwner() || this.roleCheckService.isCompanyAdmin()) {
      this.menu_items = this.companyAdminTiles().items;
    }
    else if (this.roleCheckService.isSuperAdmin()) {
      this.menu_items = this.superAdminTiles().items;
    }
  }

  companyUserTiles() {
    let menu_items: any = [
      {
        "id": "1",
        "menu_name": "Dashboard",
        "menu_link": "/dashboard",
        "slug": "dashboard"
      }
    ];
    if (this.getUsageMenuItems()) {
      menu_items.push(
        this.getUsageMenuItems()
      );
    }
    return {
      items: menu_items
    }
  }

  companyAdminTiles() {
    let menuItems = [
      {
        "id": "1",
        "menu_name": "Dashboard",
        "menu_link": "/dashboard",
        "slug": "dashboard"
      },
      {
        "id": "2",
        "menu_name": "Users",
        "menu_link": "/my-account/manage-users",
        "slug": "group"
      },
      {
        "id": "4",
        "menu_name": "My Quotes",
        "menu_link": "/my-quotes",
        "slug": "receipt_long"
      },
      {
        "id": "5",
        "menu_name": "My Payments",
        "menu_link": "/my-payments",
        "slug": "receipt"
      }
    ];
    if (this.getUsageMenuItems()) {
      const usageMenu: any = this.getUsageMenuItems();
      menuItems.splice(2, 0, usageMenu);
    }
    return {
      items: menuItems
    }
  }

  getUsageMenuItems() {
    let submenuItem = {};
    if (this.productsAccess.includes(this.appSettings.InstantVal) || this.productsAccess.includes(this.appSettings.AssetInfo)) {
      let submenu = [];
      if (this.productsAccess.includes(this.appSettings.InstantVal)) {
        submenu = [{
          "name": 'InstantVal Usage',
          "url": '/usage'
        }];
      }
      if (this.productsAccess.includes(this.appSettings.AssetInfo)) {
        submenu.push({
          "name": 'Asset Info Usage',
          "url": '/asset-info-usage'
        });
      }
      if (this.productsAccess.includes(this.appSettings.SalvageVal)) {
        submenu.push({
          "name": 'SalvageVal Usage',
          "url": '/salvageval-usage'
        });
      }
      submenuItem = {
        "id": "2",
        "menu_name": "Usage",
        "slug": "assessment",
        "sub_menu": submenu
      };
      if (submenu.length > 0) {
        return submenuItem;
      } else {
        return null;
      }
    }

  }

  superAdminTiles() {
    return {
      items: [
        {
          "id": "1",
          "menu_name": "Dashboard",
          "menu_link": "/dashboard",
          "slug": "dashboard"
        },
        {
          "id": "3",
          "menu_name": "Companies",
          "menu_link": "/companies",
          "slug": "apartment"
        },
        {
          "id": "13",
          "menu_name": "Users",
          "menu_link": "/users",
          "slug": "group"
        },
        {
          "id": "2",
          "menu_name": "Usage",
          "slug": "assessment",
          "sub_menu": [
            {
              "name": 'InstantVal Usage',
              "url": '/usage'
            },
            {
              "name": 'Asset Info Usage',
              "url": '/asset-info-usage'
            },
            {
              "name": 'SalvageVal Usage',
              "url": '/salvageval-usage'
            }
          ]
        },
        {
          "id": "9",
          "menu_name": "Products",
          "slug": "view_list",
          "sub_menu": [
            {
              "name": 'Product Listing',
              "url": '/product-listing'
            },
            {
              "name": 'Coupons',
              "url": '/coupons-listing'
            }
          ]
        },
        {
          "id": "9",
          "menu_name": "Billing",
          "slug": "receipt_long",
          "sub_menu": [
            {
              "name": 'Quotes',
              "url": '/quotes-listing'
            },
            {
              "name": 'Invoices',
              "url": '/invoice-listing'
            }
          ]
        },
        {
          "id": "9",
          "menu_name": "Tools",
          "slug": "group",
          "sub_menu": [
            {
              "name": 'Backup Tables',
              "url": '/backup-tables'
            }
          ]
        }
      ]
    }
  }
}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MaterialComponentsModule } from "./material-components.module";

//Common modules
import { CoreModule } from "./core/core.module";
import { ApCommonModule } from "./ap-common/ap-common.module";
import { CompaniesModule } from "./companies/companies.module";

import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { RoutingModule } from "./app-routing.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { MyAccountModule } from "./my-account/my-account.module";
import { UiKitModule } from "./uikit/uikit.module";
import { ApiUrls } from "./configs/api-urls";
import { AppHeadings } from "./configs/app-headings";
import { AppSettings } from "./configs/app-settings";
import { DataStoreService } from "./services/data-store.service";
import { UserAuthModule } from "./user-auto/user-auth.module";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { DateFormat } from "./configs/date-format";
import {
  MatDatepickerModule
} from "@angular/material/datepicker";
import {
  MatNativeDateModule,
  DateAdapter,
} from "@angular/material/core";
import { UsersModule } from "./users/users.module";
import { VerificationModule } from "./ap-common/verification-module/verification.module";
import { ErrorHandlerInterceptor } from "./interceptors/error-handler.interceptor";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from "./interceptors/http-token.interceptor";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RoutingModule,
    CoreModule,
    SharedModule,
    ApCommonModule,
    DashboardModule,
    UiKitModule,
    MyAccountModule,
    UserAuthModule,
    CompaniesModule,
    UsersModule,
    VerificationModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    ApiUrls,
    AppHeadings,
    AppSettings,
    DataStoreService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, disableClose: true },
    },
    { provide: DateAdapter, useClass: DateFormat },
  ],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
  }
}
